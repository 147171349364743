

import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import DJLogoShort from '@/components/svg/DJLogoShort.vue'
import { user } from '@/utils/user'
import { MailIcon, PhoneIcon } from '@heroicons/vue/solid'

export default defineComponent({
  components: {
    DJLogoShort,
    MailIcon,
    PhoneIcon,
  },
  setup() {
    const { currentRoute } = useRouter()
    const loggedIn = computed(() => !!(user.value && user.value?.uid))
    const query = computed(() => currentRoute.value?.query || {})

    return {
      loggedIn,
      query,
    }
  },
})
