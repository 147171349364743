

import {
  ComputedRef,
  PropType,
  computed,
  defineComponent,
} from 'vue'

import { ProfileIcon } from '@/utils/types'
import { OfficeBuildingIcon, UserIcon } from '@heroicons/vue/solid'

const sizes: { [key: string]: number } = {
  xs: 64,
  sm: 64,
  md: 128,
  lg: 256,
}

// Translating component size to the real icon size
const trans: { [key: number]: string } = {
  64:  'xs',
  128: 'sm',
  256: 'md',
  512: 'lg',
}

export default defineComponent({
  components: {
    UserIcon,
    OfficeBuildingIcon,
  },
  props: {
    icon: {
      type:    Object as PropType<ProfileIcon | null>,
      default: null,
    },
    size: {
      type:     String,
      required: true,
    },
    placeholder: {
      type:     String,
      required: true,
    },
    alt: {
      type:     String,
      required: true,
    },
    lazy: {
      type:    Boolean,
      default: false,
    },
  },
  setup(props) {

    // DOCS: https://web.dev/serve-images-webp/
    // DOCS: https://developers.google.com/search/docs/advanced/guidelines/google-images

    function getIconSet(webP = false) {
      if (!props.icon) return ''
      if (webP && !props.icon?.hasWebp) return ''

      const used: number[] = []
      const set: string[] = []
      for (const size in sizes) {
        const targetPxSize = sizes[size]
        const iconVersion = trans[targetPxSize]
        if (used.indexOf(targetPxSize) !== -1) continue
        const k = webP ? `${iconVersion}w` : iconVersion
        if (k in props.icon && typeof props.icon[k] === 'string') {
          set.push(`${props.icon[k]} ${targetPxSize}w`)
          used.push(targetPxSize)
        }
      }

      return set.join(', ')
    }

    const srcsetWebp: ComputedRef<string> = computed(() => getIconSet(true))

    const srcsetPng: ComputedRef<string> = computed(() => getIconSet(false))

    const srcPng: ComputedRef<string> = computed(() => {
      if (!props.icon) return ''

      const targetPxSize = sizes[props.size]
      const iconVersion = trans[targetPxSize]
      if (iconVersion in props.icon && typeof props.icon[iconVersion] === 'string')
        return `${props.icon[iconVersion]}`

      return ''
    })

    const pixelSize = computed(() => props.size in sizes ? `${sizes[props.size]}px` : '10vw')

    return {
      srcsetWebp,
      srcsetPng,
      srcPng,
      pixelSize,
    }
  },
})
