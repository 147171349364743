import to from 'await-to-js'
import firebase from 'firebase/compat/app'

import { updateMyCompany } from '@/components/company/company'
import { Timestamp, db } from '@/firebase/db'
import { createJobSlug } from '@/utils/job'
import { clone } from '@/utils/obj'
import { decapitalize } from '@/utils/str'
import {
  CompanyProfile,
  JobForm,
  JobSource,
  JobStatus,
} from '@/utils/types'
import { user } from '@/utils/user'

export const emptyJobForm: JobForm = {
  title:      '',
  categories: [{ category: { name: '', slug: '' }, subcategory: null }],
  skills:     [],
  // locations: [JOB_LOCATIONS[0]],
  salary:     {
    currency: 'USD',
    type:     'yearly',
    public:   false,
  },
}

export async function createJob(job: JobForm, company: CompanyProfile): Promise<JobForm> {
  if (!user.value?.uid)
    throw new Error('User not logged in')
  if (!company?.id)
    throw new Error('User has no company')
  if (!job.title)
    throw new Error('Missing title')
  if (!job.categories || !job.categories.length || !job.categories[0].category)
    throw new Error('Missing categories')
  if (!job.salary || !job.salary.from)
    throw new Error('Missing salary')
  if (job.salary && job.salary.from && job.salary.to && job.salary.from > job.salary.to)
    throw new Error('Salary: To should be higher than From')
  // if (job.salary.to && job.salary.from) {
  //   const decreaseValue = job.salary.to - job.salary.from
  //   const diff = Math.floor((decreaseValue / job.salary.to) * 100)
  //   if (diff > 50)
  //     throw new Error('Salary range can\'t be greater than 50%')
  // }

  const enrichedJob: JobForm = clone(job)

  enrichedJob.title = decapitalize(job.title)

  enrichedJob.company = {
    id:       company.id,
    name:     company.name?.display || '',
    username: company.username || '',
    icon:     company.icon,
    ref:      db.collection('companyProfiles').doc(company.id),
  }

  enrichedJob.authorUid = user.value?.uid
  enrichedJob.editorUdis = [user.value?.uid]

  enrichedJob.createdAt = firebase.firestore.FieldValue.serverTimestamp() as Timestamp
  enrichedJob.updatedAt = firebase.firestore.FieldValue.serverTimestamp() as Timestamp

  enrichedJob.primaryCategory = job.categories[0].category
  enrichedJob.primarySubcategory = job.categories?.[0]?.subcategory ?? null

  enrichedJob.status = JobStatus.Unpublished
  enrichedJob.source = JobSource.User

  enrichedJob.update = true

  // Defaults
  // enrichedJob.locations = [JOB_LOCATIONS[0]]
  // enrichedJob.locationSlugs = [JOB_LOCATIONS[0].slug]
  // enrichedJob.type = JOB_TYPES[0]

  let slug = ''
  const jobsMap = company?.jobsMap || {}

  // TODO: this should be happening in transaction !!! create job & update company

  slug = await createJobSlug(job, jobsMap)
  enrichedJob.slug = slug

  const createdJob = await db.collection('jobs').add(enrichedJob)

  jobsMap[slug] = createdJob.id
  await to(updateMyCompany({
    id: company.id,
    jobsMap,
  }))

  return enrichedJob
}