import to from 'await-to-js'
import firebase from 'firebase/compat/app'
import {
  Ref,
  computed,
  ref,
} from 'vue'

import { User, getAuth, getCurrentUser } from '@/firebase/auth'
import {
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  Transaction,
  bindingFactory,
  db,
  serialize,
} from '@/firebase/db'
import { navFail } from '@/utils/nav'
import { clone } from '@/utils/obj'
import {
  decapitalize,
  getCapitalize,
  nthIndex,
  pad,
} from '@/utils/str'
import {
  TrackingValue,
  identifyUser,
  trackError,
  trackErrorMessage,
  trackEvent,
} from '@/utils/tracking'
import {
  BoolDict,
  ChangedEmail,
  Dict,
  Invite,
  Message,
  NameParts,
  Profile,
  ProfileProgress,
  Service,
} from '@/utils/types'
import { trueOnce } from '@/utils/use'
import { getUsername, user, userLoading } from '@/utils/user'

declare global {
  interface Window {
    warmup?: { [key: string]: never }
  }
}

export const myProfile: Ref<Profile | null> = ref<Profile | null>(null)
export const amIAdmin = ref(false)
export const adminLoggedAs: Ref<string | null> = ref(null)

export const initMyProfile = async (): Promise<void> => {
  // https://firebase.google.com/docs/rules/rules-language?authuser=0
  // https://firebase.google.com/docs/auth/admin/custom-claims#node.js_2

  let stop: null | (() => void) = null
  const updateMyUser = (newUser: User | null): void => {
    if (newUser) {
      user.value = newUser
      const qry = db.collection('profiles').doc(newUser.uid)
      stop = qry.onSnapshot(
        (doc: DocumentSnapshot) => {
          if (doc.exists)
            myProfile.value = serialize<Profile>(doc, 'profile')
        },
        error => trackError(error, 'Failed to bind my profile!'),
      )
      newUser.getIdTokenResult().then(idTokenResult => {
        amIAdmin.value = !!idTokenResult?.claims?.admin
        adminLoggedAs.value = idTokenResult?.claims?.admintakover ? String(idTokenResult.claims.admintakover) : null
      })
      identifyUser(newUser)
    } else {
      if (stop !== null) stop()
      stop = null
      user.value = null
      myProfile.value = null
    }
  }

  // Check if we have user now
  updateMyUser(await getCurrentUser())

  userLoading.value = false

  const auth = await getAuth()
  // Listen to further changes
  auth.onAuthStateChanged(updateMyUser)
}

export const profile: Ref<Profile | null> = ref<Profile | null>(null)

export const isMine = computed(() => !!user.value && !!profile.value && user.value.uid === profile.value.id)

export const getProfileProgress = (profile: Profile | null): ProfileProgress => ({
  hasSkills:   !!profile?.xps?.length,
  hasLocation: !!profile?.loc?.display,
  hasPhoto:    !!profile?.icon?.lg,
  hasLanguage: !!profile?.langs?.length && profile?.langs?.map(l => l.level).some(s => s === 5),
  hasPitch:    !!profile?.elevatorPitch,
  hasNotifs:   !!(profile?.notifs ? (profile?.notifs?.newsletter?.email ||
    Object.values(profile?.notifs).filter(n => n?.jobs).some(n => n.email)) : false),
})

export const myProfileProgress = computed<ProfileProgress>(() => getProfileProgress(myProfile.value))

export const amICandidatePro = computed(() => !!myProfile.value?.pro?.candidate)
export const amIBusinessPro = computed(() => !!myProfile.value?.pro?.business)
export const amIDCer = computed(() => !!myProfile.value?.pro?.dc)
export const amIHiringPro = computed(() =>
  !!myProfile.value?.pro?.business ||
  !!myProfile.value?.pro?.hiring ||
  !!myProfile.value?.pro?.dc)
export const amIServicePro = computed(() =>
  !!myProfile.value?.pro?.business ||
  !!myProfile.value?.pro?.seller ||
  !!myProfile.value?.pro?.dc)
export const amIAnyPro = computed(() =>
  !!myProfile.value?.pro?.candidate ||
  !!myProfile.value?.pro?.hiring ||
  !!myProfile.value?.pro?.business ||
  !!myProfile.value?.pro?.seller ||
  !!myProfile.value?.pro?.dc)

export const isIncomplete = computed(() =>
  !!profile.value &&
  isMine.value &&
  !profile.value.pro?.business &&
  Object.values(myProfileProgress.value).some(v => !v),
)

export const isVisible = computed(() =>
  isMine.value ||
  amIAdmin.value || (!profile.value?.disabled && (
    profile.value?.stats?.progress?.hasPublic ||
    (profile.value?.stats?.progress?.hasPlatformPublic && user.value)
  )),
)

export const hasMinimal = computed(() => profile.value?.stats?.progress?.hasPlatformPublic)

export const isPrivateNow = computed(() =>
  !isMine.value &&
  !amIAdmin.value && (
    profile.value?.stats?.progress?.hasPrivate ||
    (profile.value?.stats?.progress?.hasPlatform && !user.value)
  ),
)
export const isDisabled = computed(() =>
  !isMine.value &&
  !amIAdmin.value &&
  profile.value?.disabled,
)

export const amIHero = computed(() => !!myProfile.value?.pro && Object.values(myProfile.value.pro).some(p => p))

export const lastJob = (prof: Profile): string => {
  if (!prof.lastJob) return ''
  const era = prof.lastJob.isCurrent ? 'works' : 'worked'
  return `${decapitalize(prof.lastJob.positionName)} ${era} at ${decapitalize(prof.lastJob.companyName)}`
}

export const lastJobTitle = computed(() => profile.value ? lastJob(profile.value) : '')

export const elevatorPitch = computed(() => {
  if (!profile.value?.elevatorPitch && isMine.value) return 'Add your elevator pitch here!'
  if (!profile.value?.elevatorPitch) return ''
  const nt = nthIndex(profile.value.elevatorPitch, '\n', 2)
  if (nt > 0) {
    // Only allow 2 newlines
    return profile.value.elevatorPitch.substring(0, nt)
      + profile.value.elevatorPitch.substring(nt).split(/\r?\n/g).join(' ')
  }
  return profile.value.elevatorPitch
})

export const isHero = computed(() =>
  !!profile.value?.pro?.candidate ||
  !!profile.value?.pro?.business)

export const getProLevel = (prof: Profile | null): string => {
  if (prof?.pro?.business)
    return 'business'
  if (prof?.pro?.candidate)
    return 'candidate'
  if (prof?.pro?.dc)
    return 'dc'
  return 'none'
}

export const proLevel = computed(() => getProLevel(profile.value))

export const getProLevelColor = (level: string): string => {
  switch (level) {
  case 'candidate':
    return 'blue'
  case 'business':
  case 'seller':
  case 'hiring':
  case 'dc':
    return 'green'
  default:
    return 'gray'
  }
}

export const proLevelColor = computed(() => getProLevelColor(proLevel.value))

export const getProfileLevelColor = (prof: Profile | null): string =>
  getProLevelColor(getProLevel(prof))

export const canHasCompany = (prof: Ref<Profile | null>): boolean => {
  return !!prof.value?.pro?.business ||
    prof.value?.category === 'business' ||
    prof.value?.category === 'seller' ||
    prof.value?.category === 'hiring' ||
    prof.value?.category === 'dc'
}

export const canIHaveCompany = computed(() => canHasCompany(myProfile))

export const checkCategory = (prof: Profile | null): string | null => {
  const cat = prof?.category
  const path = !cat ? null : ((
    cat === 'business' ||
    cat === 'seller' ||
    cat === 'hiring'
  ) ? 'business' : (
      cat === 'candidate' ? 'candidate' : null
    ))
  return path
}

export const myCategory = computed(() => checkCategory(myProfile.value))

export const checkHasNotifs = (prof: Profile | null): boolean => {
  if (!prof?.notifs)
    return false

  const cat = checkCategory(prof)

  if (cat === 'candidate')
    return prof?.notifs?.newsletter?.email ||
      Object.values(prof?.notifs).filter(n => n?.jobs).some(n => n.email)

  if (cat === 'business')
    return Object.values(prof?.notifs).filter(n => n?.business).some(n => n.email)

  return Object.values(prof?.notifs).some(n => n.email)
}
export const hasNotifs = computed(() => checkHasNotifs(myProfile.value))

export const needCategory = computed(() =>
  !!user.value &&
  !!myProfile.value &&
  !myProfile.value.category &&
  !amIHero.value)

// eslint-disable-next-line require-await
export const profileBindings = bindingFactory<string, Profile>(profile, async (uid, slugRef) => {
  if (!uid)
    return null
  const qry = db.collection('profiles').doc(uid)
  return qry.onSnapshot(
    (doc: DocumentSnapshot) => {
      if (doc.exists) {
        const newProf = serialize<Profile>(doc, 'profile')
        if (profile.value?.warm && newProf) {
          Object.assign(profile.value, newProf)
          profile.value.warm = false
        } else {
          profile.value = newProf
        }
        if (slugRef &&
          !!slugRef.value &&
          !!profile.value &&
          !!profile.value?.username &&
          slugRef.value !== profile.value.username)
          slugRef.value = profile.value?.username || null
      } else
        trackErrorMessage(`Failed to bind a profile! [${uid}]`)
    },
    error => {
      navFail.value = true
      trackError(error, 'Failed to bind a profile!')
    })
}, async (slug, bind) => {
  if (!slug) return null

  if (window.warmup && window.warmup[`profile:${slug}`]) {
    const profID = window.warmup[`profile:${slug}:uid`] || ''
    if (profID && // Disable self-warmup for logged in user
      (!user.value || profID !== user.value?.uid)) {
      const warmupData: Profile = JSON.parse(window.warmup[`profile:${slug}`]) as Profile
      warmupData.warm = true
      warmupData.id = profID
      profile.value = warmupData
      return bind(profID)
    }
  }

  let qry = db.collection('profiles').where('usernameLow', '==', slug.toLowerCase())
  qry = qry.limit(1)
  const col = await qry.get()
  if (col.docs.length === 1) {
    const prof = serialize<Profile>(col.docs[0], 'profile')
    if (!prof?.id)
      return null
    profile.value = prof
    return bind(prof.id)
  }
  return null // Special value that let's the outside know it's not bound!
}, false, null)

/**
 * true - profile found
 * false - 404
 * string - new username
*/
export const setProfile = async (slug: string): Promise<boolean | string> => {
  if (!!slug && profile.value?.username === slug)
    return true

  const [error, success] = await to(profileBindings.slugBind(slug))
  if (error) {
    if (error)
      trackError(error, 'Failed to bind profile!', { slug })
    navFail.value = true
    return false
  }

  if (success !== null)
    return true

  const [usernameProblem, nameData] = await to(getUsername(slug))
  if (usernameProblem || !nameData) {
    if (usernameProblem)
      trackError(usernameProblem, 'Username not found!', { slug })
    return false
  }
  if (nameData?.username &&
      nameData.username !== slug
    && nameData.usernameLow === slug.toLowerCase()) {
    return `/${nameData.username}`
  }
  return false
}

// eslint-disable-next-line require-await
export const createMyProfile = async (
  nameParts: NameParts,
  user: User,
  enterQuery?: Dict | null,
  coupon?: string | null,
  invite?: Invite | null,
): Promise<Profile> => {
  if (!user)
    throw new Error('User not logged in!')

  const capitalize = await getCapitalize()
  const authorUid = user.uid
  const profileRef = db.collection('profiles').doc(authorUid)

  return db.runTransaction(async (transaction: Transaction): Promise<Profile> => {
    const createdAt = firebase.firestore.FieldValue.serverTimestamp()
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    const visibility = 'public'
    let counter = 1
    let username = nameParts.slug ? nameParts.slug.split('-').map(capitalize).join('') : ''
    let original = username
    if (username.length < 8) {
      username = pad('0000000', username)
      original = username
      username = username + '1'
    }
    let usernameLow: string = username.toLowerCase()
    let usernameRef: DocumentReference = db.collection('usernames').doc(usernameLow)
    let userNameReady = false
    while (!userNameReady) {
      usernameLow = username.toLowerCase()
      usernameRef = db.collection('usernames').doc(usernameLow)
      // eslint-disable-next-line no-await-in-loop
      const un = await transaction.get(usernameRef)
      if (un.exists) {
        username = original + counter
        counter += 1
      } else {
        userNameReady = true
      }
    }
    // Create username
    await transaction.set(usernameRef, {
      username,
      usernameLow,
      profileRef,
      authorUid,
      visibility,
      createdAt,
      updatedAt,
    })
    const profile: Profile = {
      username,
      usernameLow,
      usernameRef,
      visibility,
      enterQuery,
      coupon,
      invite,
      name:   nameParts,
      status: {
        fulltime:  true,
        parttime:  true,
        freelance: true,
        connect:   true,
      },
      update: true,
    }
    // Create profile
    await transaction.set(profileRef, { ...profile, createdAt, updatedAt }, { merge: true })
    return profile
  })
}

// eslint-disable-next-line require-await
export const createMyEmptyProfile = async (user: User): Promise<Profile> => {
  if (!user)
    throw new Error('User not logged in!')

  const authorUid = user.uid
  const profileRef = db.collection('profiles').doc(authorUid)

  return db.runTransaction(async (transaction: Transaction): Promise<Profile> => {
    const createdAt = firebase.firestore.FieldValue.serverTimestamp()
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    const visibility = 'public'
    const profile: Profile = {
      visibility,
      status: {
        fulltime:  true,
        parttime:  true,
        freelance: true,
        connect:   true,
      },
    }
    // Create profile
    await transaction.set(profileRef, { ...profile, createdAt, updatedAt }, { merge: true })
    return profile as Profile
  })
}

export const updateMyProfile = async (profile: Profile): Promise<boolean> => {
  try {
    if (!user.value)
      throw new Error('User not logged in!')

    const uid = user.value.uid
    profile = clone(profile, true)
    await db.collection('profiles').doc(uid).update({
      ...profile,
      update:    true,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
    })
  } catch (error) {
    trackError(error, 'Failed to update profile!')
    return false
  }
  return true
}

export const sendProfileMessage = async (user: User, fromProfile: Profile, toProfile: Profile, message: string, service?: Service): Promise<boolean> => {
  if (!user)
    throw new Error('User not logged in!')
  const fromUid = fromProfile.id
  const toUid = toProfile.id
  if (!toUid)
    throw new Error('Missing id of profile to send to!')
  if (!fromUid)
    throw new Error('Missing id of profile to send from!')
  if (fromUid && user.uid !== fromUid)
    throw new Error('Invalid profile!')
  if (!fromProfile.name?.display)
    throw new Error('Missing name!')
  if (!fromProfile.username)
    throw new Error('Missing username!')

  const createdAt = firebase.firestore.FieldValue.serverTimestamp() as Timestamp
  const fromRef = db.collection('profiles').doc(fromUid)
  const toRef = db.collection('profiles').doc(toUid)
  const fromProf = serialize<Profile>(await fromRef.get(), 'profile')
  const toProf = serialize<Profile>(await toRef.get(), 'profile')
  if (!fromProf)
    throw new Error('From profile not found!')
  if (!toProf)
    throw new Error('To profile not found!')
  const fromIcon = fromProf?.icon || null
  const toIcon = toProf?.icon || null
  const fromName = fromProfile.name?.display || 'User'
  const toName = toProfile.name?.display || 'User'
  const fromUsername = fromProfile.username
  const toUsername = toProfile.username || ''
  const participants = [fromUid, toUid]
  const participantsMap: BoolDict = {}
  participantsMap[fromUid] = true
  participantsMap[toUid] = true
  const version = 4
  const unread = true

  const messageData: Message = {
    createdAt,
    fromUid,
    toUid,
    participants,
    participantsMap,
    fromRef,
    toRef,
    fromIcon,
    toIcon,
    fromName,
    toName,
    fromUsername,
    toUsername,
    message,
    unread,
    version,
  }
  if (service) {
    messageData.kind = 'offer'
    messageData.offerName = service.pitch?.name || ''
    messageData.offerUid = service.id
    messageData.offerRef = db.collection('offers').doc(service.id)
  }

  await db.collection('messages').add(messageData)

  const tracking: TrackingValue = {
    from_id:        fromUid,
    to_id:          toUid,
    message_length: message.length,
  }
  if (service?.id) {
    tracking.service_id = service.id
    tracking.service_name = service.pitch?.name || ''
  }
  trackEvent('user_message', tracking, user)

  return true
}

export const addChangedEmail = async (changedEmail: string, id: string): Promise<boolean> => {
  const createdAt = firebase.firestore.FieldValue.serverTimestamp() as Timestamp
  const changedEmailData: ChangedEmail = {
    changedEmail: changedEmail,
    createdAt:    createdAt,
  }
  await db.collection('changedEmails').doc(id).set(changedEmailData)
  return true
}

export const upgradeCandidate = ref(false)
export const upgradeCandidateOnce = trueOnce(upgradeCandidate)

// export const upgradeBusiness = ref(false)
// export const upgradeBusinessOnce = trueOnce(upgradeBusiness)

export const changeEmail = ref(false)
export const changeEmailOnce = trueOnce(changeEmail)

export const connectAccount = ref(false)
export const connectAccountOnce = trueOnce(connectAccount)

export const changePassword = ref(false)
export const changePasswordOnce = trueOnce(changePassword)
