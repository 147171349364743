import {
  Ref,
  ref,
} from 'vue'
import {
  bindingFactory,
  db,
  serialize,

} from '@/firebase/db'
import { getAuth } from '@/firebase/auth'
import { trackError } from '@/utils/tracking'
import {
  JobForm,
  JobStatus,
} from '@/utils/types'
import { myProfile } from '@/components/profile/profile'

const job = ref<JobForm | null>(null)
const isBinded = ref(false)

// eslint-disable-next-line require-await
const jobBindings = bindingFactory<undefined, JobForm>(job, async () => {
  const queryRef = db.collection('jobs')
    .where('company.id', '==', myProfile.value?.companies?.[0]?.uid)
    .where('status', '==', JobStatus.Unpublished)
    .orderBy('createdAt', 'desc')
    .limit(1)

  return queryRef.onSnapshot(
    col => {
      const result = (col.docs.map(doc => serialize<JobForm>(doc)).filter(d => !!d) as JobForm[])
      job.value = result?.[0] ?? null
    },
    error => trackError(error, 'Failed to bind last job draft'),
  )
})

const { bind, unbind } = jobBindings

const setupLogoutReset = async () => {
  const auth = await getAuth()
  auth.onAuthStateChanged(newUser => {
    if (!newUser) {
      unbind()
      job.value = null
      isBinded.value = false
    }
  })
}

export const useLastJobDraft: () => { job: Ref<JobForm | null> } = () => {
  if (!isBinded.value && myProfile.value?.companies?.[0]?.uid) {
    isBinded.value = true
    bind()
    setupLogoutReset()
  }

  return { job }
}