import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "contents"
}
const _hoisted_2 = ["srcset", "sizes"]
const _hoisted_3 = ["srcset", "sizes"]
const _hoisted_4 = ["loading", "src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OfficeBuildingIcon = _resolveComponent("OfficeBuildingIcon")!
  const _component_UserIcon = _resolveComponent("UserIcon")!

  return (_ctx.icon)
    ? (_openBlock(), _createElementBlock("picture", _hoisted_1, [
        (_ctx.srcsetWebp)
          ? (_openBlock(), _createElementBlock("source", {
              key: 0,
              type: "image/webp",
              srcset: _ctx.srcsetWebp,
              sizes: _ctx.pixelSize
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("source", {
          type: "image/png",
          srcset: _ctx.srcsetPng,
          sizes: _ctx.pixelSize
        }, null, 8, _hoisted_3),
        _createElementVNode("img", _mergeProps(_ctx.$attrs, {
          class: ["object-cover object-center w-full h-full rounded-full", {
          'w-32 h-32': _ctx.size === 'lg',
          'w-24 h-24': _ctx.size === 'md',
          'w-14 h-14': _ctx.size === 'sm',
          'w-8 h-8': _ctx.size === 'xs',
        }],
          width: "100%",
          height: "100%",
          loading: _ctx.lazy ? 'lazy' : '',
          src: _ctx.srcPng,
          alt: _ctx.alt
        }), null, 16, _hoisted_4)
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.placeholder === 'company')
          ? (_openBlock(), _createBlock(_component_OfficeBuildingIcon, _mergeProps({ key: 0 }, _ctx.$attrs, { class: "w-full h-full text-gray-50" }), null, 16))
          : (_openBlock(), _createBlock(_component_UserIcon, _mergeProps({ key: 1 }, _ctx.$attrs, {
              class: "w-full h-full text-gray-50",
              "aria-hidden": "true"
            }), null, 16))
      ], 64))
}