import Autolinker, { AutolinkerConfig, ReplaceFn } from 'autolinker'
import { Directive } from 'vue'

interface ExtendedHTMLElement extends HTMLElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickOutside: any
}

export const ClickOutside: Directive = {

  mounted(el, binding): void {
    const elem = el as ExtendedHTMLElement
    // console.log('mounted', el)

    elem.clickOutside = (event: Event) => {
      // console.log('clicked', el, event)
      if (!(elem === event.target || elem.contains(event.target as Node))) {
        binding.value(event)
        // console.log('clicked outside', el, event)
      } else {
        // console.log('clicked inside', el, event)
      }
    }

    setTimeout(() => {
      document.body.addEventListener('click', elem.clickOutside)
      // console.log('listening', el)
    }, 10)
  },

  beforeUnmount(el): void {
    // console.log('unmounted', el)
    const elem = el as ExtendedHTMLElement
    document.body.removeEventListener('click', elem.clickOutside)
    elem.clickOutside = null
  },

}

export const Linker: Directive = {
  beforeMount(el, binding) {
    const replaceFn: ReplaceFn = function (match) {
      const tag = match.buildTag()
      tag.setAttr('rel', 'noopener nofollow')

      return tag
    }
    const defaultOptions: AutolinkerConfig = { className: 'underline', stripPrefix: false, replaceFn }
    const options: AutolinkerConfig = binding.arg as AutolinkerConfig || {}
    el.innerHTML = Autolinker.link(binding.value, { ...defaultOptions, ...options })
  },
}
