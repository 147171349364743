import { Ref, computed, ref } from 'vue'

import { trueOnce } from '@/utils/use'
import { user } from '@/utils/user'

import { myProfile } from '../profile/profile'

export const sidebar = ref(false)
export const sidebarEnabled = computed(() => !!myProfile.value && !!user.value)
export const sidebarOnce = trueOnce(sidebarEnabled)

export const myLink: Ref<boolean> = ref(false)
export const myLinkOnce = trueOnce(myLink)

export const SIDE_MENU = 'SidebarMenu'
export const SIDE_INBOX = 'SidebarInbox'
export const SIDE_UPDATES = 'SidebarUpdates'

export type StepType =
  typeof SIDE_MENU |
  typeof SIDE_INBOX |
  typeof SIDE_UPDATES

export const dir: Ref<boolean> = ref(true)
export const step: Ref<StepType> = ref(SIDE_MENU)

export const goTo = (target: StepType, direction = true): void => {
  if (step.value !== target) {
    dir.value = direction
    step.value = target
  }
}

export const goToMenu = (direction = true): void => goTo(SIDE_MENU, direction)
export const goToInbox = (direction = true): void => goTo(SIDE_INBOX, direction)
export const goToUpdates = (direction = true): void => goTo(SIDE_UPDATES, direction)