

import to from 'await-to-js'
import {
  PropType,
  defineComponent,
} from 'vue'

import { updateMyCompany } from '@/components/company/company'
import { isPrivateNow, updateMyProfile } from '@/components/profile/profile'
import ImageIcon from '@/components/widgets/ImageIcon.vue'
import LoadingSpinner from '@/components/widgets/LoadingSpinner.vue'
import { initPhotoUpload } from '@/utils/photo'
import { trackError } from '@/utils/tracking'
import { CompanyProfile, Profile, ProfileIcon } from '@/utils/types'
import { user } from '@/utils/user'
import { CameraIcon } from '@heroicons/vue/solid'

export default defineComponent({
  components: {
    ImageIcon,
    CameraIcon,
    LoadingSpinner,
  },
  props: {
    icon: {
      type:    Object as PropType<ProfileIcon | null>,
      default: null,
    },
    element: {
      type:     String,
      required: true,
    },
    color: {
      type:     String,
      required: true,
    },
    inverse: {
      type:    Boolean,
      default: false,
    },
    size: {
      type:     String,
      required: true,
    },
    mode: {
      type:     String,
      required: true,
    },
    editable: {
      type:    Boolean,
      default: false,
    },
    interactive: {
      type:    Boolean,
      default: false,
    },
    lazy: {
      type:    Boolean,
      default: false,
    },
    uid: {
      type:    String,
      default: '',
    },
    adminTakover: {
      type:    Boolean,
      default: false,
    },
  },
  emits: ['uploaded'],
  setup(props, { emit }) {

    function showError(error: Error): false {
      alert(error.message || String(error))
      return false
    }

    async function updateProfile(update: Profile): Promise<boolean> {
      if (!user.value) return false

      const [error] = await to(updateMyProfile(update))
      if (error) {
        trackError(error, 'Failed to update profile!')
        return showError(error)
      }

      if (update?.icon) iconUploaded(update?.icon)

      return true
    }

    async function updateCompany(update: CompanyProfile): Promise<boolean> {
      if (!user.value) return false

      const [error] = await to(updateMyCompany({ id: props.uid, ...update }))
      if (error) {
        trackError(error, 'Failed to update company!')
        return showError(error)
      }

      if (update?.icon) iconUploaded(update?.icon)

      return true
    }

    const iconUploaded = (newIcon: ProfileIcon) => {
      emit('uploaded', newIcon)
    }

    const saveData = props.mode === 'profile' ? updateProfile : updateCompany
    const getDir = (): (string | false) => {
      var dir: string
      if (!user.value) return false
      if (!props.uid) return false
      switch (props.mode) {
      case 'profile':
        dir = 'profiles'
        break
      case 'company':
        dir = `companies/${user.value.uid}`
        break
      default:
        return false
      }
      return `${dir}/${props.uid}`
    }

    const {
      uploadingPhoto,
      uploadingPercentage,
      selectPhoto,
    } = initPhotoUpload(
      user,
      saveData,
      getDir,
      showError,
    )

    return {
      isPrivateNow,
      selectPhoto,
      uploadingPercentage,
      uploadingPhoto,
    }
  },
})
