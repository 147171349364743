import to from 'await-to-js'
import firebase from 'firebase/compat/app'
import { v4 as uuidv4 } from 'uuid'

import {
  getPlaceDetails,
  getTimezone,
  guessPlace,
} from '@/utils/api'
import { trackError, trackErrorMessage } from '@/utils/tracking'
import { Location } from '@/utils/types'

export interface Place {
  id: string
  description: string
  matched_substrings: { length: number, offset: number }[]
  place_id: string
  reference: string
  structured_formatting: {
    main_text: string
    main_text_matched_substrings: { length: number, offset: number }[]
    secondary_text: string
  }
  terms: { offset: number, value: string }[]
  types: string[]
}

export interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

export interface PlaceDetails {
  address_components: AddressComponent[]
  geometry: {
    location: {
      lat: number
      lng: number
    },
    viewport: {
      northeast: {
        lat: number
        lng: number
      },
      southwest: {
        lat: number
        lng: number
      }
    }
  },
  id: string
  name: string
  place_id: string
  types: string[]
  utc_offset: number
}

export interface TimeZone {
  dstOffset: number
  rawOffset: number
  status: string
  timeZoneId: string
  timeZoneName: string
}

export const cTypes = {
  CITY:         'locality',
  ZIP_CODE:     'postal_code',
  COUNTRY:      'country',
  STATE:        'administrative_area_level_1',
  DISTRICT:     'administrative_area_level_2',
  TOWN:         'sublocality_level_1',
  AREA:         'sublocality_level_2',
  NEAREST_ROAD: 'route',
}

export const getComp = function (address_components: AddressComponent[], key: string): AddressComponent | null {
  const component = address_components.filter(comp =>
    comp.types.some(typesItem => typesItem === key))
  if (component !== null && component.length > 0)
    return component[0]
  return null
}

export interface LocationBit {
  location?: string
  last?: string
  display?: string
  timeZone?: string
}

export const extractLocationFromPlace = (pl: PlaceDetails, prev: LocationBit): Location => {
  const country: AddressComponent | null = getComp(pl.address_components, cTypes.COUNTRY)
  const zipCode: AddressComponent | null = getComp(pl.address_components, cTypes.ZIP_CODE)
  const region: AddressComponent | null = getComp(pl.address_components, cTypes.STATE)
  const district: AddressComponent | null = getComp(pl.address_components, cTypes.DISTRICT)
  const city: AddressComponent | null = getComp(pl.address_components, cTypes.CITY)
  const town: AddressComponent | null = getComp(pl.address_components, cTypes.TOWN)
  const area: AddressComponent | null = getComp(pl.address_components, cTypes.AREA)
  return {
    area:        area?.long_name || '',
    city:        city?.long_name || '',
    country:     country?.long_name || '',
    countryCode: country?.short_name || '',
    display:     prev.display || '',
    district:    district?.long_name || '',
    geo:         (pl.geometry?.location?.lat && pl.geometry?.location?.lng) ?
      new firebase.firestore.GeoPoint(pl.geometry.location.lat, pl.geometry.location.lng) : null,
    mapsPlaceID: pl.place_id,
    state:       region?.long_name || '',
    stateCode:   region?.short_name || '',
    town:        town?.long_name || '',
    utcOffset:   pl.utc_offset ?? null,
    zipCode:     zipCode?.long_name || '',
    timeZone:    prev.timeZone || '',
    continent:   prev.timeZone ? prev.timeZone.split('/')[0] : '',
  }
}

export const guessMyLocation = async (): Promise<Location | null> => {

  const loc: LocationBit = {
    display:  '',
    timeZone: '',
  }

  const session = uuidv4()
  const [error, place]: [Error | null, Place | null | undefined] =
    await to(guessPlace(session))
  if (error) {
    trackError(error, 'Error while loading place!')
    return null
  }
  if (!place) {
    trackErrorMessage('Failed to load place!')
    return null
  }
  loc.display = place.description

  const [error2, placeDetails]: [Error | null, PlaceDetails | null | undefined] =
    await to(getPlaceDetails(place.place_id, session))

  if (error2) {
    trackError(error2, 'Error while loading place details!')
    return null
  }
  if (!placeDetails) {
    trackErrorMessage('Missing place details!')
    return null
  }
  if (placeDetails?.geometry?.location) {
    const latLong = `${placeDetails.geometry.location.lat},${placeDetails.geometry.location.lng}`
    const [error3, timeZone]: [Error | null, TimeZone | undefined] =
      await to(getTimezone(latLong))
    if (!error3 && timeZone)
      loc.timeZone = timeZone.timeZoneId
  }

  return extractLocationFromPlace(placeDetails, loc)
}