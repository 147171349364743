

import {
  computed,
  defineComponent,
  reactive,
} from 'vue'
import { useRouter } from 'vue-router'

import {
  adminLoggedAs,
  amIHero,
  canIHaveCompany,
  myProfile,
} from '@/components/profile/profile'
import ProfileIcon from '@/components/shared/ProfileIcon.vue'
import { sidebar } from '@/components/sidebar/sidebar'
import DJLogoShort from '@/components/svg/DJLogoShort.vue'
import { newUpdates } from '@/utils/updates'
import { user } from '@/utils/user'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'

import { totalUnreadMessages } from '../sidebar/inbox'

export default defineComponent({
  components: {
    DJLogoShort,
    ProfileIcon,
    XIcon,
    MenuIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  setup() {
    const { currentRoute } = useRouter()
    const query = computed(() => currentRoute.value?.query || {})
    const loggedIn = computed(() => !!(user.value && user.value?.uid))
    const totalNews = computed(() => newUpdates.value + totalUnreadMessages.value)
    const state = reactive({
      menuSettings: false,
      menuJobs:     false,
      menuAbout:    false,
    })

    function toggleDropDown() {
      state.menuSettings = !state.menuSettings
    }

    function hideDropDown() {
      state.menuSettings = false
    }

    return {
      adminLoggedAs,
      amIHero,
      canIHaveCompany,
      hideDropDown,
      loggedIn,
      myProfile,
      query,
      sidebar,
      state,
      toggleDropDown,
      totalNews,
      user,
    }
  },
})
