import {
  Ref,
  WatchStopHandle,
  ref,
  watch,
} from 'vue'

/*
  This helper funtion will create a boolean ref which turns true after
  the watched variable is set to positive value.
  This is used to only load extra components if required at leaset once.
*/
export const trueOnce = <T>(source: Ref<T>): Ref<boolean> => {
  const isTrue: Ref<boolean> = ref<boolean>(false)
  let stop: WatchStopHandle | null = null
  stop = watch(source, newValue => {
    if (newValue) {
      isTrue.value = true
      if (stop)
        stop()
      else
        setTimeout(() => {
          if (stop) stop()
        }, 10)
    }
  }, { immediate: true })

  return isTrue
}