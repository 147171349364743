import { nextTick, ref } from 'vue'
import { LocationQuery, Router } from 'vue-router'

import { myProfile, updateMyProfile } from '@/components/profile/profile'
import { feeds } from '@/components/settings/notifications'
import { openWizardToJob } from '@/components/wizard/wizard'

// import { ON_PROD } from './env'
import { trackEvent } from './tracking'
import { Profile } from './types'
import { user } from './user'

export const navFail = ref<boolean>(false)

export const navigate = (url: string): void => {
  // TODO: Automatically add UTM parameters here
  window.location.href = url
}

function recoverUTM(query: LocationQuery): string {
  if (String(query?.utm_source))
    return String(query.utm_source)

  if (String(query?.source))
    return String(query.source)

  return ''
}

export const navigateToPostJob = async (router: Router, utm_source: string | undefined = undefined): Promise<void> => {

  trackEvent('post_job_clicked')

  const { push, currentRoute } = router

  const query = currentRoute?.value?.query || {} as LocationQuery

  if (!utm_source)
    utm_source = recoverUTM(query)

  if (!user.value) {
    openWizardToJob()
    return
  }

  // Candidate is trying to post a job, switch profile to business
  if (myProfile.value?.category === 'candidate') {
    const update: Profile = {
      category: 'business',
      syncDrip: true,
      notifs:   {},
    }
    for (const field in feeds)
      if (feeds[field].business && update.notifs)
        update.notifs[field] = { email: true, ...feeds[field] }

    await updateMyProfile(update)

    trackEvent('switched_to_business_for_job_posting')
  }

  if (myProfile.value?.companies?.length) {
    // This opens the job modal!
    openWizardToJob()
    return
  }

  // TODO: create company in wizzard
  await push({
    name:  'company',
    query: {
      ...query,
      target: 'post-job',
      utm_source,
    },
  })
}

export const navigateToListService = async (router: Router, utm_source = ''): Promise<void> => {

  trackEvent('list_service_clicked')

  const { push, currentRoute } = router

  const query = currentRoute?.value?.query || {} as LocationQuery

  if (!utm_source)
    utm_source = recoverUTM(query)

  if (!user.value) {
    await push({
      name:  'enter',
      query: {
        target: 'post-service',
      },
    })
    return
  }

  if (myProfile.value?.companies?.length) {
    await push({
      name:   'company-view',
      params: { company: myProfile.value.companies[0].username },
      query:  {
        ...query,
        service: 'new',
        utm_source,
      },
    })
    await nextTick()
    return
  }

  await push({
    name:  'company',
    query: {
      ...query,
      target: 'post-service',
    },
  })
}

export const navigateToCreateProfile = async (router: Router, utm_source = ''): Promise<void> => {

  trackEvent('create_your_profile_clicked')

  const { push, currentRoute } = router

  const query = currentRoute?.value?.query || {} as LocationQuery

  if (!utm_source)
    utm_source = recoverUTM(query)

  if (user.value) {
    await push({
      name:  'profile',
      query: {
        ...query,
        utm_source,
      },
    })
  } else {
    await push({
      name:  'enter',
      query: {
        ...query,
        target: 'create-profile', // fast track to profile
        utm_source,
      },
    })
  }
}