import to from 'await-to-js'
import firebase from 'firebase/compat/app'

import { app } from './app'

// https://firebase.google.com/docs/reference/js/firebase.analytics

export type Analytics = ReturnType<typeof app.analytics>

export let analytics: Analytics | null = null

export const getAnalytics = async (): Promise<Analytics | null> => {
  if (!analytics) {
    await import(/* webpackPreload: true, webpackChunkName: 'preload-extra-analytics' */ 'firebase/compat/analytics')
    const [error, supported] = await to(firebase.analytics.isSupported())
    if (!error && !!supported)
      analytics = app.analytics()
  }
  return analytics
}