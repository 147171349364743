import './assets/tailwind.css'

import { createApp } from 'vue'

import DJApp from './DJApp.vue'
import router from './router'
import { ClickOutside, Linker } from './utils/directives'

export const app = createApp(DJApp)
  .use(router)
  .directive('click-outside', ClickOutside)
  .directive('linker', Linker)
  .mount('#app')