import type { IDefaults, IOptions } from 'sanitize-html'
import type { OptionsSlugify, SlugifyFunction } from 'transliteration/dist/node/src/types'
import { Ref, ref } from 'vue'

import { isBot } from './tracking'
import { NameParts } from './types'

export { SlugifyFunction }

export const pad = (pading: string, str: string, padLeft = false): string => {
  if (typeof str === 'undefined')
    return pading

  if (padLeft)
    return (pading + str).slice(-pading.length)

  return (str + pading).substring(0, pading.length)
}

export const padLeft = (pading: string, str: string): string => pad(pading, str, true)

export const nthIndex = (str: string, pat: string, n: number): number => {
  const L = str.length
  let i = -1
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i)
    if (i < 0) break
  }
  return i
}

const config: OptionsSlugify = {
  lowercase:    true,
  separator:    '-',
  trim:         true,
  allowedChars: 'a-zA-Z0-9-',
}

export const strFnsLoaded: Ref<boolean> = ref<boolean>(false)
export const slugify: Ref<CustomSlugify | null> = ref<CustomSlugify | null>(null)
export const keyGen: Ref<KeywordGenerator | null> = ref<KeywordGenerator | null>(null)
export const capitalize: Ref<CapitalizeFunctions | null> = ref<CapitalizeFunctions | null>(null)
export const sanitize: Ref<SanitizeFunction | null> = ref<SanitizeFunction | null>(null)

export const loadStr = async (): Promise<void> => {
  if (isBot || strFnsLoaded.value) return
  const [slf, cap, san] = await Promise.all([
    getSlugify(),
    getCapitalize(),
    getSanitize(),
  ])
  slugify.value = slf
  capitalize.value = cap
  sanitize.value = san
  keyGen.value = slugifyKeygenFactory(slugify.value)
  strFnsLoaded.value = true
}

type CustomSlugify = (str: string) => string

const improveSlugifyWrapper = (fn: SlugifyFunction, options?: OptionsSlugify): CustomSlugify => {
  return (str: string) => {
    str = fn(str, options)
    if (str === '-')
      return ''
    return str
  }
}

export const generateKeywords = (text: string): string[] => {
  const keys: string[] = []
  let chars = ''
  text.split('').forEach(letter => {
    chars += letter
    keys.push(chars)
  })
  return keys
}

export type KeywordGenerator = (str: string) => string[]

const slugifyKeygenFactory: (fn: CustomSlugify) => KeywordGenerator = (fn: CustomSlugify) =>
  (str: string) => [...new Set((fn(str)).split('-').map(generateKeywords).reduce((acc, val) => acc.concat(val), []))]

const getSlugify = async (): Promise<CustomSlugify> => {
  if (slugify.value === null) {
    const slugImport: SlugifyFunction =
      await import(/* webpackChunkName: 'extra-trans' */ 'transliteration')
        .then(importedModule => importedModule.slugify)
    if (slugify.value === null) {
      slugImport.config(config)
      slugify.value = improveSlugifyWrapper(slugImport, config)
    }
  }
  return slugify.value
}

export const asyncSlugify = async (str: string): Promise<string> => {
  const slgFy = await getSlugify()
  return slgFy(str)
}

export const getKeywordGenerator = async (): Promise<KeywordGenerator> => {
  return slugifyKeygenFactory(await getSlugify())
}

export const keywordGenerator = async (name: string): Promise<string[]> => {
  return slugifyKeygenFactory(await getSlugify())(name)
}

export const splitName = (fullName: string): NameParts | null => {
  if (!slugify.value || !keyGen.value || !capitalize.value) return null
  fullName = fullName.replace(/\s+/g, ' ')
  fullName = fullName.split(' ').map(p => capitalize.value ? capitalize.value(p) : decapitalize(p)).join(' ')
  const parts = fullName.split(' ', 2)
  const first = parts[0]
  const last = parts.length > 1 ? parts[1] : ''
  const slug = slugify.value(fullName)
  return {
    display:  fullName,
    first:    first,
    last:     last,
    slug:     slug,
    slugs:    slug.split('-'),
    slugKeys: keyGen.value(fullName),
  }
}

export const splitSimpleName = (display: string): NameParts | null => {
  if (!slugify.value || !keyGen.value) return null
  const slug = slugify.value(display)
  display = display.replace(/\s+/g, ' ')
  display = decapitalize(display)
  return {
    display:  display,
    slug:     slug,
    slugs:    slug.split('-'),
    slugKeys: keyGen.value(display),
  }
}

export interface SanitizeFunction {
  (dirty: string, options?: IOptions): string
  defaults: IDefaults
}

let sanitizeFn: SanitizeFunction | null = null

const getSanitize = async (): Promise<SanitizeFunction> => {
  if (sanitizeFn === null) {
    const sanitizeF: SanitizeFunction =
      await import(/* webpackChunkName: 'extra-tools' */ 'sanitize-html')
        .then(importedModule => importedModule.default)
    if (sanitizeFn === null) {
      // eslint-disable-next-line require-atomic-updates
      sanitizeFn = sanitizeF
    }
  }
  return sanitizeFn
}

// export const asyncSanitize = async (str: string): Promise<string> => {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const sanit = await getSanitize()
//   return sanit(str)
// }

export type CapitalizeFunctions = (s: string) => string

export const getCapitalize = async (): Promise<CapitalizeFunctions> => {
  if (capitalize.value === null) {
    const capImport: CapitalizeFunctions =
      await import(/* webpackChunkName: 'extra-tools' */ '@foundernest/namecase')
        .then(importedModule => importedModule.nameCase)
    if (capitalize.value === null) {
      // eslint-disable-next-line require-atomic-updates
      capitalize.value = capImport
    }
  }
  return capitalize.value
}

export const asyncCapitalize = async (str: string): Promise<string> => {
  const capit = await getCapitalize()
  return capit(str)
}

export const owns = (str: string | null | undefined): string => {
  if (!str)
    return ''
  if (typeof str !== 'string')
    str = String(str)
  if (str.charAt(str.length - 1) === 's')
    return `${str}'`
  return `${str}'s`
}

export const stripParams = (url: string): string => {
  return url ? url.split('?')[0].split('#')[0].replace(/\/$/, '') : url
}

export const isUpperCase = (str: string): boolean => {
  return str === str.toUpperCase()
}

export const isLowerCase = (str: string): boolean => {
  return str === str.toLowerCase()
}

export const capitalFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
}

const MAX_CAPITALS = 5

export const decapitalize = (str?: string): string => {
  if (!str)
    return ''

  if (str.length > MAX_CAPITALS && (isUpperCase(str) || isLowerCase(str)))
    return str.split(' ').map(s => capitalFirst(s)).join(' ')

  return str
}