declare global {
  interface Window {
    attachEvent: (mathod: string, fn: () => void) => void
  }
}

let delay = 30
let pageLoaded = false

const makeLoaded = () => pageLoaded = true

const onLoad = (fn: () => unknown) => {
  if (window.addEventListener)
    window.addEventListener('load', fn, false)
  else if (window.attachEvent)
    window.attachEvent('onload', fn)
  else
    window.onload = fn
}

onLoad(makeLoaded)

export const loadLater = (fn: () => unknown): void => {
  let executed = false

  const loadLate = () => {
    if (executed) return
    setTimeout(async () => {
      await fn()
      executed = true
    }, delay)
  }

  if (pageLoaded) {
    delay = 10
    loadLate()
  }
  onLoad(loadLate)
}