export const loadScript = (src: string): Promise<boolean> => {
  return new Promise(function (resolve, reject) {
    let shouldAppend = false
    let el: HTMLScriptElement | null = document.querySelector('script[src="' + src + '"]')
    if (!el) {
      el = document.createElement('script')
      el.type = 'text/javascript'
      el.async = true
      el.defer = true
      el.src = src
      shouldAppend = true
    } else if (el.hasAttribute('data-loaded')) {
      resolve(true)
      return
    }
    el.addEventListener('error', reject)
    el.addEventListener('abort', reject)
    el.addEventListener('load', function loadScriptHandler() {
      if (el) {
        el.setAttribute('data-loaded', 'true')
        resolve(true)
      } else {
        resolve(false)
      }
    })
    if (shouldAppend) document.head.appendChild(el)
  })
}