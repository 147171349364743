import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import { getCurrentUser } from '@/firebase/auth'

import { getById } from './firebase/db'
import { navFail } from './utils/nav'
import { Profile } from './utils/types'

const HomeView = () => import(/* webpackChunkName: 'route-jobs' */ '@/components/views/HomeView.vue')
const EnterView = () => import(/* webpackChunkName: 'route-enter' */ '@/components/views/EnterView.vue')
const TermsView = () => import(/* webpackChunkName: 'route-legal' */ '@/components/views/TermsView.vue')
const PrivacyView = () => import(/* webpackChunkName: 'route-legal' */ '@/components/views/PrivacyView.vue')
const LostPasswordView = () => import(/* webpackChunkName: 'route-lost' */ '@/components/views/LostPasswordView.vue')
const ProfilesView = () => import(/* webpackChunkName: 'route-profiles' */ '@/components/views/ProfilesView.vue')
const ProfileView = () => import(/* webpackChunkName: 'route-profile' */ '@/components/views/ProfileView.vue')
const ProfileNamedView = () => import(/* webpackChunkName: 'route-profile' */ '@/components/views/ProfileNamedView.vue')
const ServiceView = () => import(/* webpackChunkName: 'route-service' */ '@/components/views/ServiceView.vue')
const ServicesView = () => import(/* webpackChunkName: 'route-services' */ '@/components/views/ServicesView.vue')
const CompanyCreateView = () => import(/* webpackChunkName: 'route-company' */ '@/components/views/CompanyCreateView.vue')
const CompanyView = () => import(/* webpackChunkName: 'route-company' */ '@/components/views/CompanyView.vue')
const JobView = () => import(/* webpackChunkName: 'route-job' */ '@/components/views/JobView.vue')
const PostJobView = () => import(/* webpackChunkName: 'route-post-a-job' */ '@/components/views/PostJobView.vue')
const RemoteRecruitingView = () => import(/* webpackChunkName: 'route-remote-recruiting' */ '@/components/views/RemoteRecruitingView.vue')
const BusinessProAccountView = () => import(/* webpackChunkName: 'route-business-pro' */ '@/components/views/BusinessProAccountView.vue')
const HowToFindRemoteJobsView = () => import(/* webpackChunkName: 'route-how-to-find-remote-jobs' */ '@/components/views/HowToFindRemoteJobsView.vue')
const ListYourServicesView = () => import(/* webpackChunkName: 'route-list-your-services' */ '@/components/views/ListYourServicesView.vue')
const AboutView = () => import(/* webpackChunkName: 'route-about' */ '@/components/views/AboutView.vue')
const SuccessStoriesView = () => import(/* webpackChunkName: 'route-success-stories' */ '@/components/views/SuccessStoriesView.vue')
const CandidateProView = () => import(/* webpackChunkName: 'route-candidate-pro' */ '@/components/views/CandidateProView.vue')
const DCersView = () => import(/* webpackChunkName: 'route-dcers' */ '@/components/views/DCersView.vue')
const JobsView = () => import(/* webpackChunkName: 'route-jobs' */ '@/components/views/JobsView.vue')
const ContactUsView = () => import(/* webpackChunkName: 'route-contact-us' */ '@/components/views/ContactUsView.vue')
const DCMexView = () => import(/* webpackChunkName: 'route-dcers' */ '@/components/views/DCMexView.vue')
const RemoteJobsCategoriesView = () => import(/* webpackChunkName: 'route-remote-jobs-categories' */ '@/components/views/RemoteJobsCategoriesView.vue')

const routes: RouteRecordRaw[] = [{
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: not needed (main page)
  */
  path: '/',
  name: 'home',
  meta: {
    title:     'The Web’s Best Remote Jobs',
    canonical: 'https://dynamitejobs.com/',
  },
  component: HomeView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/terms-and-conditions',
  name: 'terms',
  meta: {
    title:     'Terms and Conditions',
    canonical: '/terms-and-conditions',
  },
  component: TermsView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/privacy-policy',
  name: 'privacy',
  meta: {
    title:     'Privacy Policy',
    canonical: '/privacy-policy',
  },
  component: PrivacyView,
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/terms',
  redirect: to => {
    const { query } = to
    return { name: 'terms', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/privacy',
  redirect: to => {
    const { query } = to
    return { name: 'privacy', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/enter',
  name: 'enter',
  meta: {
    title:     'Enter Dynamite Jobs',
    canonical: '/enter',
  },
  component:   EnterView,
  beforeEnter: async (to, _, next): Promise<void> => {
    const user = await getCurrentUser()
    const { query, params } = to
    if (user?.uid) {
      const toProfile = await getById<Profile>('profiles', user.uid)
      if (!toProfile?.username) {
        next({ name: 'home', query, params })
        return
      }
      if (toProfile.category === 'business') {
        // TODO: redirect to
        if (toProfile.companies?.length && toProfile.companies[0]) {
          params.company = toProfile.companies[0].username
          next({ name: 'company-view', query, params })
        } else {
          next({ name: 'company', query, params })
        }
        return
      }
      if (toProfile.category === 'candidate') {
        params.profile = toProfile.username
        next({ name: 'profile-view', params, query })
        return
      }
      next({ name: 'home', query, params })
      return
    }
    next()
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/join',
  redirect: to => {
    const { query } = to
    if (!query.source) query.source = 'join'
    return { name: 'enter', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/login',
  redirect: to => {
    const { query } = to
    if (!query.source) query.source = 'login'
    return { name: 'enter', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/signup',
  redirect: to => {
    const { query } = to
    if (!query.source) query.source = 'signup'
    return { name: 'enter', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/signin',
  redirect: to => {
    const { query } = to
    if (!query.source) query.source = 'signin'
    return { name: 'enter', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/i',
  redirect: to => {
    const { query } = to
    if (!query.source) query.source = 'empty-invite'
    return { name: 'enter', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/i/:uid',
  redirect: to => {
    const { query } = to
    const uid = to.params.uid
    query.invite = uid
    if (!query.source) query.source = 'invite'
    return { name: 'how-to-find-remote-jobs', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:        '/uid/:uid',
  component:   ProfileNamedView,
  beforeEnter: async (to, from, next): Promise<void> => {
    const { query, params } = to
    const uid = String(params.uid)
    if (!uid) {
      next({ name: 'home', query, params })
      return
    }
    const toProfile = await getById<Profile>('profiles', uid)
    if (!toProfile?.username) {
      next({ name: 'home', query, params })
      return
    }
    params.profile = toProfile.username
    next({ name: 'profile-view', params, query })
  },
  name: 'uid-fwd',
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/lost-password',
  name: 'lost-password',
  meta: {
    title:     'Password Recovery',
    canonical: '/lost-password',
  },
  component: LostPasswordView,
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: yes
   */
  path:     '/skills',
  name:     'skills',
  redirect: to => {
    const { query } = to
    return { name: 'profiles', query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/skills/:skills',
  name:     'skills-view',
  redirect: to => {
    const { query, params } = to
    return { name: 'profiles', params, query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/profiles',
  name: 'profiles',
  meta: {
    title:     'Profiles',
    canonical: '/profiles',
  },
  component: ProfilesView,
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (profiles)
   * canonical: not needed (profiles)
   * sitemap: not needed (profiles)
   */
  path:      '/profiles/:skills',
  name:      'profiles-view',
  meta:      { suffix: 'Profiles' },
  component: ProfilesView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/services',
  name: 'services',
  meta: {
    title:     'Services Marketplace',
    canonical: '/services',
  },
  component: ServicesView,
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (services)
   * canonical: not needed (services)
   * sitemap: not needed (services)
   */
  path:      '/services/:category',
  name:      'services-category',
  meta:      { suffix: 'Services Marketplace' },
  component: ServicesView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/post-a-remote-job',
  name: 'post-a-remote-job',
  meta: {
    title:     'Post a Remote Job',
    canonical: '/post-a-remote-job',
  },
  component: PostJobView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/remote-recruiting',
  name: 'remote-recruiting',
  meta: {
    title:     'Remote Recruiting',
    canonical: '/remote-recruiting',
  },
  component: RemoteRecruitingView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/about-us',
  name: 'about',
  meta: {
    title:     'About',
    canonical: '/about-us',
  },
  component: AboutView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/business-pro-account',
  name: 'business-pro-account',
  meta: {
    title:     'Business Pro Account',
    canonical: '/business-pro-account',
  },
  component: BusinessProAccountView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/candidate-pro',
  name: 'candidate-pro',
  meta: {
    title:     'Candidate Pro',
    canonical: '/candidate-pro',
  },
  component: CandidateProView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/how-to-find-remote-jobs',
  name: 'how-to-find-remote-jobs',
  meta: {
    title:     'How To Find Remote Jobs',
    canonical: '/how-to-find-remote-jobs',
  },
  component: HowToFindRemoteJobsView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/list-your-services',
  name: 'list-your-services',
  meta: {
    title:     'List Your Services',
    canonical: '/list-your-services',
  },
  component: ListYourServicesView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/success-stories',
  name: 'success-stories',
  meta: {
    title:     'Success Stories',
    canonical: '/success-stories',
  },
  component: SuccessStoriesView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/dcers',
  name: 'dcers',
  meta: {
    title:     'DCers',
    canonical: '/dcers',
  },
  component: DCersView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/contact-us',
  name: 'contact-us',
  meta: {
    title:     'Contact Us',
    canonical: '/contact-us',
  },
  component: ContactUsView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/dcmex',
  name: 'dcmex',
  meta: {
    title:     'DCMex',
    canonical: '/dcmex',
  },
  component: DCMexView,
}, {
  /*
   * go handler: yes - but we will retire this url at some point
   * vue.config.js: yes
   * canonical: not needed
   * sitemap: not needed
   */
  path:      '/company',
  name:      'company',
  component: CompanyCreateView,
  meta:      { suffix: 'Create Company', canonical: '/company' },
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes (in another commit)
   */
  path:      '/company/:company',
  name:      'company-view',
  component: CompanyView,
  meta:      { suffix: 'Company' },
}, {
  /*
   * go handler: not needed (redirect)
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/company/:company/offers/:service',
  redirect: to => {
    const { query } = to
    const company = String(to.params.company)
    const service = String(to.params.service)
    return { path: `/company/${company}/service/${service}`, query }
  },
}, {
  /*
   * go handler: not needed (redirect)
   * vue.config.js: not needed (redirect)
   * canonical: not needed (redirect)
   * sitemap: not needed (redirect)
   */
  path:     '/company/:company/services/:service',
  redirect: to => {
    const { query } = to
    const company = String(to.params.company)
    const service = String(to.params.service)
    return { path: `/company/${company}/service/${service}`, query }
  },
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: no (will be done in another commit)
   */
  path:      '/company/:company/service/:service',
  name:      'service-view',
  meta:      { suffix: 'Service' },
  component: ServiceView,
}, {
  /*
   * go handler: yes (:profile)
   * vue.config.js: yes (:profile)
   * canonical: yes (:profile)
   * sitemap: yes (:profile)
   */
  path:      '/profile',
  name:      'profile',
  meta:      { requiresAuth: true, suffix: 'My Profile' },
  component: ProfileView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: no (will be done in another commit)
   */
  path:      '/company/:companySlug/remote-job/:jobSlug',
  name:      'job-view',
  meta:      { suffix: 'Remote Job' },
  component: JobView,
  props:     true,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path:      '/remote-jobs-categories',
  name:      'remote-jobs-categories-view',
  meta:      { title: 'Remote Jobs Categories', canonical: '/remote-jobs-categories' },
  component: RemoteJobsCategoriesView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yes
   * sitemap: yes
   */
  path: '/remote-jobs',
  name: 'jobs',
  meta: {
    title:     'Browse Remote Jobs',
    canonical: '/remote-jobs',
  },
  component: JobsView,
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (remote jobs)
   * canonical: not needed (remote jobs)
   * sitemap: not needed (remote jobs)
   */
  path: '/remote-jobs/:category',
  name: 'jobs-category',
  meta: {
    title: 'Browse Remote Jobs',
  },
  component: JobsView,
}, {
  /*
   * go handler: yes
   * vue.config.js: not needed (remote jobs)
   * canonical: not needed (remote jobs)
   * sitemap: not needed (remote jobs)
   */
  path: '/remote-jobs/:category/:subcategory',
  name: 'jobs-subcategory',
  meta: {
    title: 'Browse Remote Jobs',
  },
  component: JobsView,
}, {
  /*
   * go handler: yes
   * vue.config.js: yes
   * canonical: yesF?
   * sitemap: yes
   */
  path:      '/:profile(.*)',
  name:      'profile-view',
  meta:      { requiresAuth: false, suffix: 'Profile' },
  component: ProfileNamedView,
}]

const router = createRouter({
  history:        createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from) => {

    // if you find profile or job in the query then do not scroll to top, this is for ProfileModal/JobModal
    if ('profile' in to.query || 'profile' in from.query ||
      'job' in to.query || 'job' in from.query) {
      return
    }

    const el = window.document.getElementById('scroll-area')
    if (el) el.scrollIntoView({ behavior: 'smooth' })

  },
})

router.beforeEach(async (to, from, next): Promise<void> => {
  if (navFail.value)
    navFail.value = false

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !(await getCurrentUser())) {
    const query = to.query

    query.redirect = to.fullPath
    next({ name: 'enter', query })

  } else
    next()
})

export default router
