import to from 'await-to-js'
import axios, { AxiosResponse } from 'axios'

import { getIdToken } from '@/firebase/auth'
import { Config, Dict } from '@/utils/types'
import { JobAddonId, JobPackageId } from '@/utils/types'

import { amIAdmin } from '../components/profile/profile'
import {
  Place,
  PlaceDetails,
  TimeZone,
} from '../components/settings/location'
import { user } from './user'

const ADMIN_REQUIRED = 'admin'
const AUTH_REQUIRED = 'auth'

type AuthMode = null | typeof ADMIN_REQUIRED | typeof AUTH_REQUIRED

const POST = 'POST'
const GET = 'GET'

type HTTPMethod = null | typeof POST | typeof GET

const getApiPath = (path: string, auth: AuthMode = null) => {
  let url = '/api/'
  if (auth !== null) {
    url += `${auth}/${user.value?.uid}/`
  }
  url += path
  return url
}

interface ResponseData { [key: string]: never }

const callApi = async ({
  url,
  method,
  data = {},
  config = {},
}: {
  url: string
  method: HTTPMethod
  data?: Config
  config?: Config
}): Promise<[Error | null, undefined | null | AxiosResponse<ResponseData>]> => {
  const requireAuth = url.startsWith('/api/auth/') || url.startsWith('/api/admin/')
  if (requireAuth) {
    const idToken = await getIdToken()
    if (idToken) {
      if (!config.headers)
        config.headers = {} as Dict
      if (typeof config.headers === 'object')
        config.headers.Authorization = `Bearer ${idToken}`
    }
  }
  if (method === POST)
    return to<AxiosResponse<ResponseData>>(axios.post(url, data, config))
  return to<AxiosResponse<ResponseData>>(axios.get(url, config))
}

export const recap = async (token: string): Promise<boolean> => {
  const url = getApiPath('recaptcha')
  const [err, response] = await callApi({ url, method: POST, data: { token }})
  if (err) throw err
  if (!response || !response.data?.pass) throw new Error('Failed validate recaptcha!')
  return response.data.pass
}

export const getUID = async (email: string): Promise<string> => {
  const url = getApiPath('uid')
  const [err, response] = await callApi({ url, method: POST, data: { email }})
  if (err) throw err
  if (!response || !response.data?.uid) throw new Error('Failed to get user info!')
  return response.data.uid
}

export const getPlaces = async (input: string, session: string): Promise<Place[]> => {
  // https://stackoverflow.com/questions/42180788/how-to-use-cors-to-implement-javascript-google-places-api-request
  // https://developers.google.com/maps/documentation/javascript/places
  // https://developers.google.com/places/web-service/web-services-best-practices

  const url = getApiPath('place/autocomplete')
  const params = {
    types:        '(cities)',
    sessiontoken: session,
    input:        input || '',
  }

  const [err, response] = await callApi({ url, method: GET, config: { params }})
  if (err) throw err
  if (!response) throw new Error('Ivalid Places Detail API Response!')
  if (!response.data) throw new Error('Missing data from API Response!')
  const data = response.data
  if (!data?.predictions) throw new Error('Missing API Response!')

  return data.predictions as Place[]
}

export const guessPlace = async (session: string): Promise<Place | null> => {

  const url = getApiPath('place/autocomplete')
  const params = {
    types:        '(cities)',
    sessiontoken: session,
    input:        '',
  }

  const [err, response] = await callApi({ url, method: GET, config: { params }})
  if (err) throw err
  if (!response) throw new Error('Ivalid Places Detail API Response!')
  if (!response.data) throw new Error('Missing data from API Response!')
  const data = response.data
  if (!data?.predictions) throw new Error('Missing API Response!')

  const preds = data.predictions as Place[]
  if (preds?.length)
    return preds[0]

  return null
}

export const getPlaceDetails = async (place_id: string, session: string): Promise<PlaceDetails> => {

  const url = getApiPath('place/details')
  const fields = [
    'address_component',
    'adr_address',
    'formatted_address',
    'geometry',
    'name',
    'place_id',
    'utc_offset',
  ]
  const params = {
    place_id:     place_id,
    sessiontoken: session,
    fields:       fields.join(','),
  }

  const [err, response] = await callApi({ url, method: GET, config: { params }})
  if (err) throw err
  if (!response) throw new Error('Ivalid PlacesDetail API Response!')

  return response.data.result as PlaceDetails
}

export const getTimezone = async (latLong: string): Promise<TimeZone> => {

  const url = getApiPath('timezone')
  const params = {
    location:  latLong,
    timestamp: Math.round(new Date().getTime() / 1000),
  }

  const [err, response] = await callApi({ url, method: GET, config: { params }})
  if (err) throw err
  if (!response) throw new Error('Ivalid Timezone API Response!')
  if (!response.data) throw new Error('Missing data from API Response!')
  return response.data as unknown as TimeZone
}

// *** Paths that require auth or admin *** //

export const updateDripTags = async (): Promise<boolean> => {
  const url = getApiPath('drip-tags', AUTH_REQUIRED)
  const [err] = await callApi({ url, method: GET })
  return !err
}

export const getCheckoutSession = async (product: string, target?: string): Promise<string> => {
  let url = getApiPath(`stripe/checkout-session/${product}`, AUTH_REQUIRED)
  if (target)
    url = `${url}?target=${target}`
  const [err, response] = await callApi({ url, method: POST })
  if (err) throw err
  if (!response || !response.data?.id) throw new Error('Failed to create checkout session!')
  return response.data.id
}

export const getJobCheckoutSession = async (companyId: string, jobId: string, product: JobPackageId, addons: JobAddonId[] = [], target = ''): Promise<string> => {
  let url = getApiPath(`stripe/job-checkout-session/${product}/${jobId}/${companyId}`, AUTH_REQUIRED)
  if (target || addons?.length) url = `${url}?`
  if (target) url = `${url}target=${target}`
  if (target && addons?.length) url = `${url}&`
  if (addons?.length) url = `${url}addons=${addons.join()}`

  const [err, response] = await callApi({ url, method: POST })
  if (err) throw err
  if (!response || !response.data?.id) throw new Error('Failed to create checkout session!')
  return response.data.id
}

export const postBusinessProJob = async (companyId: string, jobId: string, target = ''): Promise<void> => {
  let url = getApiPath(`post-bpro-job/${jobId}/${companyId}`, AUTH_REQUIRED)
  if (target) url = `${url}?target=${target}`

  const [err, response] = await callApi({ url, method: POST })
  if (err) throw err
  if (!response) throw new Error('Failed to post business pro job!')
}

export const postStaffJob = async (companyId: string, jobId: string, product: JobPackageId, couponId: string, addons: JobAddonId[] = [], target = ''): Promise<void> => {
  let url = getApiPath(`post-staff-job/${product}/${jobId}/${companyId}/${couponId}`, AUTH_REQUIRED)
  if (target || addons?.length) url = `${url}?`
  if (target) url = `${url}target=${target}`
  if (target && addons?.length) url = `${url}&`
  if (addons?.length) url = `${url}addons=${addons.join()}`

  const [err, response] = await callApi({ url, method: POST })
  if (err) throw err
  if (!response) throw new Error('Failed to post staff job!')
}

export const getConnectedCheckoutSession = async (cid: string, sid: string): Promise<string> => {
  const url = getApiPath(`stripe/connect-checkout-session/${cid}/${sid}`, AUTH_REQUIRED)
  const [err, response] = await callApi({ url, method: POST })
  if (err) throw err
  if (!response || !response.data?.id) throw new Error('Failed to create connect checkout session!')
  return response.data.id
}

export const getConnectAccountLink = async (cid: string, countryId: string): Promise<string> => {
  const url = getApiPath(`stripe/connect-account/${countryId}/${cid}`, AUTH_REQUIRED)
  const [err, response] = await callApi({ url, method: POST })
  if (err) throw err
  if (!response || !response.data?.url) throw new Error('Failed to create connect account link!')
  return response.data.url
}

export const loginAs = async (profileId: string): Promise<string> => {
  if (!amIAdmin.value) throw new Error('You are not admin!')
  const url = getApiPath(`login-as/${profileId}`, ADMIN_REQUIRED)
  if (!profileId) throw new Error('Missing parameter profileId!')
  const [err, response] = await callApi({ url, method: POST })
  if (err) throw err
  if (!response || !response.data?.token) throw new Error('Failed to get token to login as user!')
  return response.data.token
}