import { DocumentReference, GeoPoint, Timestamp } from '@/firebase/db'

export type Dict = { [key: string]: string }
export type BoolDict = { [key: string]: boolean }
export type Config = { [key: string]: string | number | boolean | Config }

export interface Doc {
  updatedAt?: Timestamp
  createdAt?: Timestamp
}

export interface FlatDoc extends Doc {
  id?: string
  name: string
  nameSlugs?: string[]
  slugKeys?: string[]
  slug: string
  authorUid?: string
  editorUid?: string
}

export interface Location {
  area: string
  city: string
  country: string
  countryCode: string
  display: string
  district: string
  geo: GeoPoint | null
  mapsPlaceID: string
  state: string
  stateCode: string
  town: string
  utcOffset: number | null
  timeZone?: string
  zipCode: string
  continent?: string
}

export interface Salary {
  currency: 'USD'
  type: 'monthly' | 'yearly' | 'hourly'
  from?: number
  to?: number
  label?: string
  public?: boolean
  hidden?: boolean // TODO: REMOVE THIS AND REFACTOR CANDIDATES TO USE PUBLIC!!
}

export interface JobLocation {
  id: string
  name: { display: string } // ... or should this be a string instead of dict?
  slug: string  // TODO: this would be better inside the name dict
  regions?: JobLocation[]
}

// TODO: this needs to be moved somewhere else, this file only contains types not constants!
export const EVERYWHERE_ID = '1' // TODO: Use slugs instead of these IDS!

// TODO: this needs to be moved somewhere else, this file only contains types not constants!
export const JOB_LOCATIONS: readonly JobLocation[] = [
  { name: { display: 'Anywhere in the world' }, id: EVERYWHERE_ID, slug: 'anywhere' },
  { name: { display: 'Africa' }, id: '2', slug: 'africa' },
  { name: { display: 'Asia' }, id: '3', slug: 'asia' },
  { name: { display: 'Australia' }, id: '4', slug: 'australia' },
  { name: { display: 'Europe' }, id: '5', slug: 'europe' },
  {
    name: { display: 'North America' }, id: '6', slug: 'northamerica',
    // , regions: [
    //   { name: { display: 'USA' }, id: '9', slug: 'usa' },
    //   { name: { display: 'Canada' }, id: '10', slug: 'canada' },
    // ],
  },
  { name: { display: 'South America' }, id: '7', slug: 'southamerica' },
]

export interface JobType {
  id: string
  name: { display: string }
  slug: string
}

// TODO: this needs to be moved somewhere else, this file only contains types not constants!
export const JOB_TYPES: readonly JobType[] = [  // TODO: Use slugs instead of these IDS!
  { name: { display: 'Full Time' }, id: '1', slug: 'full-time' },
  { name: { display: 'Part Time' }, id: '2', slug: 'part-time' },
  { name: { display: 'Short-Term Project' }, id: '3', slug: 'freelance' },
]

export enum JobStatus {
  Unprocessed = 'unprocessed',
  Unpublished = 'unpublished',
  Pending = 'pending',
  Published = 'published',
  Expired = 'expired',
  Finished = 'finished',
  Fulfilled = 'fulfilled',
}

export enum JobSource {
  User = 'user',
  Staff = 'staff',
  External = 'external',
  PredictLeads = 'predictLeads',
}

export enum JobPackageId {
  StaffExternal = 'staff-external',
  NoPackage = 'no-package',
  BPro = 'business-pro-free-post',
  None = 'job-package-none',
  Basic = 'job-package-basic',
  Advanced = 'job-package-advanced',
  Best = 'job-package-best',
  RecruitmentService = 'recruitment-service',
}

export enum JobAddonId {
  JobListing = 'job-listing',
  SocialMedia = 'social-media',
  NewsletterMention = 'newsletter-mention',
  FrontPage7 = 'front-page-7',
  FrontPageFeatured3 = 'front-page-featured-3',
  FrontPageFeatured7 = 'front-page-featured-7',
  FrontPageFeatured30 = 'front-page-featured-30',
  FrontPageFeatured7More = 'front-page-featured-7-more',
  PrivateChannels = 'private-channels',
  NewsletterFeature = 'newsletter-feature',
  TopJob7 = 'top-job-7',
  StaffPick = 'staff-pick',
  FrontPage30 = 'front-page-30',
  Invite300 = 'invite-300',
  Invite100 = 'invite-100',
  TopJob14 = 'top-job-14',
  Repromote = 'repromote-job',
}

export interface Coupon {
  id: string
  product: CouponType
  used?: boolean
}

export enum CouponType {
  StaffJobPost = 'staff-job-post'
}

export interface JobLabel {
  id: string
  name: { display: string }
  slug: string
}

export interface StatDetail {
  months: number
}

export interface SkillScore {
  score: number
}

export interface ProfileScore {
  score: number
}

export interface ProfileScore {
  score: number
}

export interface SkillDetail extends StatDetail {
  level: {
    'expert': number
    'advanced': number
    'competent': number
  }
  score: number
  maxLevel?: string
}

export interface Notification {
  tag: string
  name?: string
  force?: boolean
  jobs?: boolean
  business?: boolean
}

export interface Notifications {
  [key: string]: Notification
}

export interface NotificationValue extends Notification {
  email: boolean
}

export interface NotificationValues {
  [key: string]: NotificationValue
}

export interface ProfileStats {
  general: {
    edu: StatDetail
    work: StatDetail
    remote: StatDetail
    skills: SkillScore
    profile: ProfileScore
  }
  skills: { [key: string]: SkillDetail }
  industry: { [key: string]: StatDetail }
  position: { [key: string]: StatDetail }
  company: { [key: string]: StatDetail }
  studyfield: { [key: string]: StatDetail }
  school: { [key: string]: StatDetail }
  progress: {
    hasAll: boolean
    hasMinimum: boolean
    hasBasics: boolean
    hasRecommended: boolean
    hasName: boolean
    hasLocation: boolean
    hasUsername: boolean
    hasPrimaryLanguage: boolean
    hasSecondLanguage: boolean
    hasEnglish: boolean
    hasLangs: boolean
    hasIcon: boolean
    hasPitch: boolean
    hasSkills: boolean
    hasXps: boolean
    hasRemote: boolean
    hasEdus: boolean
    hasCurrent: boolean
    hasQuests: boolean
    hasLinks: boolean
    hasPlatformPublicBasic: boolean
    hasPublicBasic: boolean
    hasPlatformPublic: boolean
    hasPlatform: boolean
    hasPublic: boolean
    hasPrivate: boolean
    hasNotifs: boolean
  }
}

export interface ProfileIcon {
  [key: string]: unknown
  xs: string
  sm: string
  md: string
  lg: string
  xsw?: string
  smw?: string
  mdw?: string
  lgw?: string
  hasIcon?: boolean
  hasWebp?: boolean
}

export interface NameParts {
  first?: string
  last?: string
  display: string
  slug?: string
  slugs?: string[]
  slugKeys?: string[]
  verified?: boolean
}

export type ServiceMap = Dict

export interface ProfileStatus {
  connect: boolean
  freelance: boolean
  fulltime: boolean
  parttime: boolean
}

export interface ProLevel {
  candidate?: boolean
  seller?: boolean
  hiring?: boolean
  business?: boolean
  dc?: boolean
}

export interface CompanyEvidence {
  uid: string
  name: string
  username: string
  ref: DocumentReference
  elevatorPitch?: string
  icon?: ProfileIcon
}

export interface Category {
  id?: string // virtual
  parentCatId?: string // virtual
  name: {
    display: string
    slug: string
    slugs?: string[]
    slugKeys?: string[]
  }
  topLevel?: boolean // DEPRECATED
  section?: {
    jobs: boolean
    profiles: boolean
    services: boolean
  }
  subcategories?: Category[] // NOTE: should not be saved ot DB but calculated
}

export interface Invite {
  name: string
  username: string
  ref: DocumentReference | null
}

export interface Profile extends Doc {
  kind?: 'profile'
  id?: string
  name?: NameParts
  username?: string
  usernameLow?: string
  usernameRef?: DocumentReference | null
  coupon?: string | null
  oldEmail?: string | null
  visibility?: string // 'public' | 'platform' | 'private'
  category?: string | null // 'candidate' | 'business'
  companies?: CompanyEvidence[]
  pro?: ProLevel
  xps?: Experience[]
  lastJob?: Experience
  edus?: Education[]
  quests?: Answer[]
  elevatorPitch?: string
  loc?: Location
  icon?: ProfileIcon
  status?: ProfileStatus
  langs?: Language[]
  links?: MyLink[]
  stats?: ProfileStats
  skillSlugs?: string[]
  skills?: SkillTag[]
  topSkill?: SkillTag
  notifs?: NotificationValues,
  version?: number
  update?: boolean
  syncDrip?: boolean
  upgradedAt?: Timestamp | null
  disabledAt?: Timestamp | null
  disabled?: boolean
  invite?: Invite | null
  enterQuery?: Dict | null
  salary?: Salary | null
  categories?: CategorySubcategoryPair[] | null
  primaryCategory?: CategoryDetails
  primarySubcategory?: CategoryDetails | null
  warm?: boolean // Just a helper to know if it was warmed up
}

export interface CompanyProfileForm {
  name?: NameParts
  description?: string
  industryName?: string
}

export interface StripeConnectMeta {
  status: string
  number: number
  initiatedAt?: Timestamp | null
}

export interface CompanyProfile extends Doc, CompanyProfileForm {
  kind?: 'company'
  ownerUid?: string
  elevatorPitch?: string // Deprecated
  editorUids?: string[]
  authorUid?: string
  editorUid?: string
  id?: string
  username?: string
  usernameLow?: string
  usernameRef?: DocumentReference | null
  industrySlug?: string
  industryRef?: DocumentReference | null
  loc?: Location // Not used ...
  links?: MyLink[]
  visibility?: string // 'public'... basically
  icon?: ProfileIcon
  connect?: StripeConnectMeta
  // offers?: ServiceStub[]
  offersMap?: Dict
  jobsMap?: Dict
  skillSlugs?: string[] // Deprecated
  skills?: SkillTag[] // Deprecated
  version?: number
  update?: boolean  // Not used yet
}

// TODO: Refactor string literals: https://stackoverflow.com/questions/37978528/typescript-type-string-is-not-assignable-to-type

export interface Username extends Doc {
  authorUid?: string
  username?: string
  usernameLow?: string
  visibility?: string // 'public' | 'platform' | 'private'
  kind?: string // 'profile' | 'company'
  profileRef?: DocumentReference | null
}

export interface AchievementForm {
  skill?: SkillTagForm | null
  question?: string | null
  answer?: string | null
}

export interface Achievement extends AchievementForm {
  skill?: SkillTag | null
  // NOT IMPLEMENTED
  // reference?: {
  //   confirmed: boolean
  //   text: string
  //   name: string
  //   role: string | null
  //   email: string | null
  //   profileRef: DocumentReference | null
  //   profileUsername: string
  // }
  // DEPRECATED
  skillSlug?: string
  skillName?: string
  skillRef?: DocumentReference | null
  level?: string // 'competent' | 'advanced' | 'expert'
}

export interface Interval {
  isCurrent: boolean
  yearFrom: number
  yearTo?: number | null
  monthFrom?: number | null
  monthTo?: number | null
}

export interface IndustryMeta {
  industryName: string
  industrySlug: string
  industryRef: DocumentReference | null
}

export interface ExperienceForm extends Interval {
  description?: string | null
  positionName: string
  companyName: string
  industryName: string
  isRemote: boolean
  skills: AchievementForm[] | null
}

export interface Experience extends ExperienceForm {
  [key: string]: unknown // TODO: remove when we can!
  kind?: 'xp'
  id?: string
  positionSlug: string
  positionRef: DocumentReference | null
  companySlug: string
  companyRef: DocumentReference | null
  industrySlug: string
  industryRef: DocumentReference | null
  skills: Achievement[] | null
}

export interface Education extends Interval {
  [key: string]: unknown // TODO: remove when we can!
  kind?: 'edu'
  id?: string
  description?: string | null
  schoolName: string
  schoolSlug: string
  schoolRef: DocumentReference | null
  fieldName: string
  fieldSlug: string
  fieldRef: DocumentReference | null
}

export type EducationForm = Partial<Education>

export interface Answer {
  [key: string]: unknown // TODO: remove when we can!
  id?: string
  question: string
  questionRef: DocumentReference | null
  answer: string
}

export interface Language {
  [key: string]: unknown // TODO: remove when we can!
  name: string
  code: string
  level: 1 | 2 | 3 | 4 | 5
  levelName: string
}

export interface MyLink {
  [key: string]: unknown // TODO: remove when we can!
  kind: string
  name: string
  link: string
  url: string
}

export interface SkillTagForm {
  name: string
  slug: string
  level: string
}

export interface SkillTag extends SkillTagForm {
  ref?: DocumentReference | null
  score?: number | null
  months?: number | null
}

export interface Service extends Doc {
  [key: string]: unknown
  id?: string
  pitch?: {
    name: string
    slug?: string
    slugs?: string[]
    slugKeys?: string[]
  }
  category?: {
    name: string
    slug: string
    ref?: DocumentReference | null
  }
  kind?: string
  companyUid?: string
  authorUid?: string
  editorUid?: string
  description?: string
  descriptionHTML?: string
  profile?: {
    ref?: DocumentReference | null
    name?: string
    username?: string
    pitch?: string
    icon?: ProfileIcon
  }
  short?: string
  scope?: string // unit | project
  unit?: string // usually an hour
  currency?: string // USD is default currency
  price?: number
  priceUSD?: number
  featured?: boolean
  score?: number
  skillSlugs?: string[]
  skills?: SkillTag[]
  faqs?: Answer[]
  isPro?: boolean
  isActive?: boolean
  isBlocked?: boolean
}

export type ServiceForm = Partial<Service>

export interface CategoryDetails {
  name: string
  slug: string
}

export interface CategorySubcategoryPair {
  category: CategoryDetails
  subcategory: CategoryDetails | null
}

export type JobForm = Partial<Job>

export type NewJobForm = Pick<JobForm, 'title' | 'categories' | 'salary'>

export interface Job {
  id: string
  company: {
    id: string
    name: string
    username: string
    icon?: ProfileIcon
    ref: DocumentReference
  }
  authorUid: string
  editorUdis: string[]
  updatedAt: Timestamp
  createdAt: Timestamp
  publishedAt?: Timestamp
  expiresAt?: Timestamp
  featuredAt?: Timestamp // set in backend task
  featureExpiresAt?: Timestamp // set in backend task
  sortDateAt?: Timestamp // set in backend task
  sortOrderPriority?: number
  slug: string
  title: string
  shortDescription: string
  description: string
  descriptionHTML: string
  faqs?: Answer[]
  locations: JobLocation[]
  locationSlugs: string[]
  type: JobType
  categories: CategorySubcategoryPair[]
  primaryCategory: CategoryDetails
  primarySubcategory: CategoryDetails | null
  salary: Salary
  status: JobStatus
  source: JobSource
  skills: SkillTag[]
  skillSlugs: string[]
  labels?: JobLabel[]
  package?: JobPackageId
  addons?: (JobAddonId | {
    id: JobAddonId,
    expiresAt?: Timestamp
    // name?: string // TODO: please implement the addon name here as well!
  })[]
  link?: string
  applyType: 'link' | 'platform'
  applyLink?: string
  applyCustomQuestions?: string
  jobAmount?: number
  version?: number
  charges?: {
    [id: string]: {
      amount: number
      id: string
      products: string[]
      chargedAt: Timestamp
    }
  }
  lastCharge?: {
    amount: number
    id: string
    products: string[]
    chargedAt: Timestamp
  }
  flags?: {
    hasSkills?: boolean
    hasSalary?: boolean
    hasPublicSalary?: boolean
    hasTitle?: boolean
    hasDescription?: boolean
    hasCategory?: boolean
    hasSubcategory?: boolean
    hasFAQs?: boolean
    hasLocation?: boolean
    hasPackage?: boolean
    hasAddons?: boolean

    hasMinimum?: boolean
    hasRecommended?: boolean
    hasFull?: boolean

    hasCustomForm?: boolean

    isPaid?: boolean
    isInternal?: boolean
    isExternal?: boolean
    IsPredicted?: boolean

    isUnprocessed?: boolean
    isUnpublished?: boolean
    isPublished?: boolean
    isPending?: boolean
    isFinished?: boolean
    isFulfilled?: boolean
    isExpired?: boolean

    isAdminFeatured?: boolean
    isFeatured?: boolean
    isBlocked?: boolean
    isVisible?: boolean
    isPageVisible?: boolean

    isSalary1To5h?: boolean
    isSalary5hTo15h?: boolean
    isSalary15hTo3k?: boolean
    isSalary3kTo6k?: boolean
    isSalary6kTo10k?: boolean
    isSalaryAbove10k?: boolean
  },
  jobApplications?: {
    [uid: string]: JobApplication
  },
  update: boolean
}

export interface JobMeta {
  analytics: {
    applyClick?: number
    applyAnonymousClick?: number
    totalApplyClick?: number
  }
  update: boolean
  version: number
}

export interface JobApplication {
  type: 'click-application' | 'full-application' | 'bookmark'
  userType: 'anonymous' | 'profile'
  profileId?: string
  appliedAt: Timestamp
  ip: string
  userAgent: string
  loc: Partial<Location>
  job: {
    id: string
    title: string
    slug: string
    publishedAt?: Timestamp
  }
  company: {
    id: string
    name: string
    username: string
    icon?: ProfileIcon
    ref: DocumentReference
  }
  profile: Pick<Profile, 'category' | 'createdAt' | 'icon' | 'lastJob' | 'name' | 'elevatorPitch' | 'topSkill'>
}

export interface Message extends Doc {
  id?: string
  fromUid: string
  toUid: string
  participants?: string[]
  participantsMap?: BoolDict
  fromRef: DocumentReference | null
  toRef: DocumentReference | null
  fromName: string
  toName: string
  fromIcon?: ProfileIcon | null
  toIcon?: ProfileIcon | null
  fromUsername: string
  toUsername: string
  message: string
  kind?: string
  profileKind?: string // deprecated
  offerName?: string
  offerUid?: string
  offerRef?: DocumentReference | null
  version?: number
  unread?: boolean
}

export interface Question extends FlatDoc {
  kind: string
}
export type Skill = FlatDoc
export type Industry = FlatDoc
export type Position = FlatDoc
export type Company = FlatDoc
export type School = FlatDoc
export type Field = FlatDoc

export interface ProfileProgress {
  hasSkills: boolean
  hasLocation: boolean
  hasPhoto: boolean
  hasLanguage: boolean
  hasPitch: boolean
  hasNotifs: boolean
}

export interface Breadcrumb {
  name: string
  id: string
  linkable?: boolean
}

export interface Inquiry {
  createdAt?: Timestamp
  email: string
  fromName?: string | null
  type?: string | null
  fromUid?: string
  fromRef?: DocumentReference | null
  position?: string
  location?: string
  experience?: string
  salary?: string
  text?: string
  jobPaymentOption?: string
}

export interface InquiryForm {
  text: string
  jobPaymentOption?: string
  fromName?: string
  email: string
}

export interface PostJobInquiryForm {
  position: string
  location: string
  experience: string
  salary: string
  fromName: string
  email: string
}

export interface InquiryFormDetails {
  title: string
  subtitle: string
  appr: string
  info: string
}

export interface ChangedEmail {
  changedEmail: string
  createdAt?: Timestamp
}

export interface ContactUsForm {
  text: string
  email: string
  name: string
  phone?: string
}

export interface FAQItem {
  question: string
  answer: string
}

export interface CTA {
  action: () => Promise<void>
  text: string
  icon: string
}