import { Notifications } from '@/utils/types'

export const feeds: Notifications = {

  'business-newsletter': {
    name:     'Business Newsletter',
    tag:      'Business Newsletter',
    jobs:     false,
    business: true,
    force:    false,
  },

  'hiring': {
    name:     'Hiring',
    tag:      'Hiring',
    jobs:     false,
    business: true,
    force:    false,
  },

  'services-selling': {
    name:     'Selling Services',
    tag:      'Selling Services',
    jobs:     false,
    business: true,
    force:    false,
  },

  'serivices-buying': {
    name:     'Buying Services',
    tag:      'Buying Services',
    jobs:     false,
    business: true,
    force:    false,
  },

  'newsletter': {
    name:     'Dynamite Jobs Newsletter',
    tag:      'Remotely Interesting Newsletter',
    jobs:     false,
    business: false,
    force:    false,
  },

  'multimedia-production': {
    name:     'Multimedia Production',
    tag:      'Multimedia Production',
    jobs:     true,
    business: false,
    force:    false,
  },

  'data-analyst': {
    name:     'Data Analysis',
    tag:      'Data Analysis',
    jobs:     true,
    business: false,
    force:    false,
  },

  'administrative': {
    name:     'Administrative',
    tag:      'Administrative',
    jobs:     true,
    business: false,
    force:    false,
  },

  'business-development-and-sales': {
    name:     'Business Development and Sales',
    tag:      'Business and Sales',
    jobs:     true,
    business: false,
    force:    false,
  },

  'technical-support': {
    name:     'Technical Support',
    tag:      'Tech Support',
    jobs:     true,
    business: false,
    force:    false,
  },

  'writing': {
    name:     'Writing',
    tag:      'Writing',
    jobs:     true,
    business: false,
    force:    false,
  },

  'design': {
    name:     'Design',
    tag:      'Design',
    jobs:     true,
    business: false,
    force:    false,
  },

  'finance': {
    name:     'Finance',
    tag:      'Finance',
    jobs:     true,
    business: false,
    force:    false,
  },

  'developer-engineer': {
    name:     'Developer/Engineer',
    tag:      'Developer',
    jobs:     true,
    business: false,
    force:    false,
  },

  'customer-service': {
    name:     'Customer Service',
    tag:      'Customer Service',
    jobs:     true,
    business: false,
    force:    false,
  },

  'operations': {
    name:     'Operations',
    tag:      'Operations',
    jobs:     true,
    business: false,
    force:    false,
  },

  'marketing': {
    name:     'Marketing',
    tag:      'Marketing',
    jobs:     true,
    business: false,
    force:    false,
  },

  'users': {
    name:     'Dynamite Jobs Users',
    tag:      'Platform',
    jobs:     false,
    business: false,
    force:    true,
  },

}