import { Dict, JobForm } from '@/utils/types'
import { loadStr, slugify } from '@/utils/str'

export async function createJobSlug(job: JobForm, jobsMap: Dict): Promise<string> {
  await loadStr()

  if (!slugify.value || !job.title) return ''

  let slug = slugify.value(job.title)

  if (jobsMap[slug]) {
    slug = findFreeSlug(slug, jobsMap)
  }

  return slug
}

function findFreeSlug(slug: string, jobsMap: Dict, prefix = 0): string {
  const testSlug = !prefix ? slug : `${slug}-${prefix}`

  if (!jobsMap[testSlug]) {
    return testSlug
  }

  return findFreeSlug(slug, jobsMap, prefix + 1)
}