import firebase from 'firebase/compat/app'
import {
  ref,
} from 'vue'

import { User } from '@/firebase/auth'
import { db } from '@/firebase/db'

import { Username } from './types'

export const user = ref<User | null>(null)
export const userLoading = ref<boolean>(true)
export const usernameRegexp = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/

export const getUsername = async (username: string): Promise<Username | null> => {
  if (username.includes('/'))
    throw new Error('Invalid username')
  username = username.toLowerCase()
  const usernameRef = db.collection('usernames').doc(username)
  const snapshot = await usernameRef.get()
  if (!snapshot.exists) return null
  return snapshot.data() as Username
}

export const usernameIsAvailable = async (uid: string, username: string): Promise<boolean> => {
  const authorUid = uid
  if (!authorUid)
    throw new Error('User not logged in!')

  if (!username)
    return false

  username = username.toLowerCase()
  if (!usernameRegexp.test(username))
    return false

  const usernameRef = db.collection('usernames').doc(username)
  const snapshot = await usernameRef.get()
  return snapshot.exists ? false : true
}

// eslint-disable-next-line require-await
export const createUsername = async (uid: string, username: string): Promise<boolean> => {
  const authorUid = uid
  if (!authorUid)
    throw new Error('User not logged in!')

  const usernameLow = username.toLowerCase()
  const usernameRef = db.collection('usernames').doc(usernameLow)
  const profileRef = db.collection('profiles').doc(authorUid)

  return db.runTransaction(async transaction => {
    const createdAt = firebase.firestore.FieldValue.serverTimestamp()
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    // This code may get re-run multiple times if there are conflicts.
    const doc = await transaction.get(usernameRef)
    if (doc.exists)
      throw new Error('Username is taken!')

    const prof = await transaction.get(profileRef)
    const profile = prof.data()
    if (!profile)
      throw new Error('Profile not found!')

    // Delete old username
    if (profile.usernameRef)
      await transaction.delete(profile.usernameRef)

    const visibility = profile.visibility || 'public'
    await transaction.set(usernameRef, {
      username,
      usernameLow,
      profileRef,
      authorUid,
      visibility,
      createdAt,
      updatedAt,
    })
    await transaction.update(profileRef, {
      username,
      usernameLow,
      usernameRef,
      updatedAt,
    })
    return true
  })
}