export const INVALID_PASSWORDS = [
  '!@#$%^&*',
  '00000000',
  '01234567',
  '012345678',
  '0123456789',
  '01234567890',
  '11111111',
  '123123123',
  '12341234',
  '12345678',
  '123456789',
  '1234567890',
  '147147147',
  '18atcskd2w',
  '1q2w3e4r',
  '1q2w3e4r5t',
  '1qaz2wsx',
  '22222222',
  '258258258',
  '321321321',
  '33333333',
  '369369369',
  '3rjs1la7qe',
  '43214321',
  '44444444',
  '456456456',
  '55555555',
  '65432101',
  '654654654',
  '66666666',
  '67896789',
  '69696969',
  '741741741',
  '76543210',
  '77777777',
  '789789789',
  '852852852',
  '87654321',
  '876543210',
  '88888888',
  '963963963',
  '98765432',
  '987654321',
  '9876543210',
  '98769876',
  '987987987',
  '99999999',
  'aa123456',
  'abcdefgh',
  'access11',
  'admin111',
  'adobe123',
  'adobe123[a]',
  'adobe123a',
  'asdfghjk',
  'Asdfghjk',
  'asdfghjkl',
  'Asdfghjkl',
  'ashley11',
  'azerty11',
  'bailey11',
  'baseball',
  'batman11',
  'charlie1',
  'donald11',
  'dragon01',
  'dragon11',
  'flower11',
  'football',
  'Football',
  'freedom1',
  'Freedom1',
  'hello123',
  'hidden00',
  'hottie11',
  'iloveyou',
  'Iloveyou',
  'iloveyou1',
  'Iloveyou1',
  'jesus111',
  'letmein1',
  'Letmein1',
  'login111',
  'lovely11',
  'loveme11',
  'master11',
  'michael1',
  'monkey01',
  'mustang1',
  'nothing1',
  'passw0rd',
  'Passw0rd',
  'PASSW0RD',
  'password',
  'Password',
  'PASSWORD',
  'password0',
  'Password0',
  'PASSWORD0',
  'password1',
  'Password1',
  'PASSWORD1',
  'password9',
  'Password9',
  'PASSWORD9',
  'photoshop',
  'photoshop[a]',
  'photoshopa',
  'princess',
  'princess1',
  'qwerty123',
  'qwertyui',
  'Qwertyui',
  'qwertyuio',
  'Qwertyuio',
  'qwertyuiop',
  'Qwertyuiop',
  'sdfghjkl',
  'secret00',
  'secret01',
  'secret11',
  'secret12',
  'secret123',
  'starwars',
  'sunshine',
  'superman',
  'trustno1',
  'undefined',
  'unknown1',
  'welcome1',
  'wertyuiop',
  'whatever',
  'Whatever',
  'zaq1zaq1',
  'zxcvbnm<',
]