
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue'
import { XIcon } from '@heroicons/vue/outline'
import { RouteRecordName, useRouter } from 'vue-router'
import { useLastJobDraft } from './useLastJobDraft'

export const show = ref(false)

export default defineComponent({
  components: {
    XIcon,
  },
  setup() {
    const { currentRoute } = useRouter()
    const query = computed(() => currentRoute.value?.query || {})
    const isRouteAllowed = (name: RouteRecordName | null | undefined) => {
      if (!name) return true

      return !['job-view'].includes(name.toString())
    }

    const dismissed = ref(false)
    const { job: lastDraft } = useLastJobDraft()

    watch(lastDraft, (next, prev) => {
      if (next && !prev && isRouteAllowed(currentRoute.value?.name))
        show.value = true

      if (next?.id !== prev?.id)
        dismissed.value = false
        
    }, { immediate: true })

    watch(currentRoute, next => {
      if (!isRouteAllowed(next?.name)) {
        show.value = false
      } else if (lastDraft.value) {
        show.value = true
      }
    })

    onMounted(() => show.value = false)

    return {
      show,
      lastDraft,
      query,
      dismissed,
    }
  },
})
