import {
  ComponentPublicInstance,
  ComputedRef,
  Ref,
  computed,
  nextTick,
  reactive,
  toRaw,
} from 'vue'
import {
  ref,
} from 'vue'
import * as yup from 'yup'
import { ValidationError } from 'yup'

import { clone } from './obj'

export interface Errors {
  [key: string]: string | undefined
}

export interface ObjectWithPaths {
  [key: string]: unknown
}

export const saving = ref<boolean | string>(false)
export const locked = ref<boolean>(false)
export const shaker = ref<boolean>(false)
export const isReady = computed(() => saving.value === false && locked.value === false)

export const startSaving = (param: true | string = true): void => {
  saving.value = param
}

export const stopSaving = async (): Promise<void> => {
  await nextTick()
  saving.value = false
}

export const shake = (): void => {
  shaker.value = true
  setTimeout(() => shaker.value = false, 1000)
}

export const useValidation = ({
  schema,
  form,
  prepared,
  formRef,
}: {
  schema: yup.AnyObjectSchema,
  form: ObjectWithPaths,
  prepared?: ComputedRef<boolean> | Ref<boolean>,
  formRef?: Ref<ComponentPublicInstance<HTMLFormElement> | null>,
}): {
  showErrorMessage: (msg: string) => false,
  showError: (error: unknown | Error) => false,
  errors: Errors,
  errorList: ComputedRef<(string)[]>,
  lastError: ComputedRef<string | null>,
  validateAt: (path: string) => Promise<void>,
  isValidAt: (path: string) => Promise<boolean>,
  validatorFactory: <T>(path: string, ifAtLeastOnce?: boolean) =>
    ((newValue: T, oldValue: T) => Promise<void>),
  clearErrors: (clearAll?: boolean) => void,
  validateAll: () => Promise<boolean>,
} => {
  const noErrors = !schema.fields ? {} : Object.keys(schema.fields).reduce((res, key) => {
    res[key] = undefined; return res
  }, {} as Errors)
  const errors = reactive<Errors>(clone(noErrors))
  const errorList = computed(() => Object.values(errors).filter(v => v !== undefined) as string[])
  const lastError = computed(() => (errorList.value || [null])[0])
  const thisIsReady = computed(() => prepared ? prepared.value && isReady.value : isReady.value)
  const atLeastOnce = ref(false)

  const validateAt = async (path: string): Promise<void> => {
    // if (ensurePaths && schema.fields && !Object.keys(schema.fields).includes(path)) return
    try {
      await schema.validateAt(path, form)
      errors[path] = undefined
    } catch (issue) {
      if (issue && issue instanceof Error)
        errors[path] = issue.message
      endSaving(true)
    }
  }

  const isValidAt = async (path: string): Promise<boolean> => {
    try {
      await schema.validateAt(path, form)
      return true
    } catch (issue) {
      return false
    }
  }

  const validatorFactory = <T>(path: string, ifAtLeastOnce = false) =>
    async (newValue: T, oldValue: T) => {
      if (!thisIsReady.value) return
      if (ifAtLeastOnce && !atLeastOnce.value) return
      const oldie = toRaw(oldValue)
      const newie = toRaw(newValue)
      const isOldieObj = typeof oldie === 'object'
      const isNewieObj = typeof oldie === 'object'
      // If objects check them as strings ...
      if (oldie !== newie &&
        (!isOldieObj && !isNewieObj &&
          JSON.stringify(oldie) !== JSON.stringify(newie)))
        await validateAt(path)
    }

  const clearErrors = (clearAll = false) => {
    Object.keys(errors).forEach(key => delete errors[key])
    Object.assign(errors, clone(noErrors))
    if (clearAll)
      atLeastOnce.value = false
  }

  const validateAll = async (): Promise<boolean> => {
    let isValid = false
    if (!atLeastOnce.value) atLeastOnce.value = true
    clearErrors()
    if (formRef && formRef.value)
      if (!formRef.value.reportValidity()) {
        endSaving(true)
        return false
      }

    try {
      await schema.validate(form, { abortEarly: false })
      isValid = true
    } catch (validationResult) {
      isValid = false
      if (validationResult instanceof ValidationError) {
        for (const issue of validationResult.inner)
          if (issue?.path)
            errors[issue.path] = issue.message
      }
      endSaving()
    }
    return isValid
  }

  const showErrorMessage = (message: string): false => {
    showError(new Error(message))
    return false
  }

  const showError = (error: unknown | Error): false => {
    if (error && error instanceof Error)
      errors[error.name || 'error'] = error.message || String(error)
    endSaving()
    return false
  }

  const endSaving = (noShake = false) => {
    if (!noShake)
      shake()
    setTimeout(() => saving.value = false, 500)
  }

  return {
    showErrorMessage,
    showError,
    errors,
    errorList,
    lastError,
    validateAt,
    isValidAt,
    validatorFactory,
    clearErrors,
    validateAll,
  }
}