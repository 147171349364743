import 'firebase/compat/firestore'

import firebase from 'firebase/compat/app'
import { Ref, ref } from 'vue'

import { ON_PROD } from '@/utils/env'
import {
  asyncCapitalize,
  asyncSlugify,
  decapitalize,
  keywordGenerator,
} from '@/utils/str'
import { isBot } from '@/utils/tracking'
import { FlatDoc } from '@/utils/types'
import type {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldValue,
  GeoPoint,
  Query,
  QuerySnapshot,
  Timestamp,
  Transaction,
} from '@firebase/firestore-types'

import { app } from './app'

export {
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  DocumentData,
  FieldValue,
  GeoPoint,
  Timestamp,
  Transaction,
}

// https://firebase.google.com/docs/reference/js/firebase.firestore
const cfg: firebase.firestore.Settings = {
  ignoreUndefinedProperties: true,
}

if (ON_PROD) {
  if (isBot)
    cfg.experimentalForceLongPolling = true
  // else
  //   cfg.experimentalAutoDetectLongPolling = true
}

export const db = app.firestore()
db.settings(cfg)

export const serialize = <T>(snapshot: DocumentSnapshot, kind?: string): T | null => {
  // snapshot.data() DOES NOT contain the `id` of the document.
  // This allows to easily create copies when updating documents, as using
  // the spread operator won't copy it
  if (!snapshot) return null
  if (!snapshot.exists) return null
  if (!snapshot.data()) return null
  let obj = Object.defineProperty(snapshot.data(), 'id', { value: snapshot.id }) as T
  if (kind)
    obj = Object.defineProperty(obj, 'kind', { value: kind }) as T

  return obj
}

export const getById = async<T>(collection: string, id: string): Promise<T | null> => {
  if (!collection)
    throw new Error('Missing collection!')

  if (!id)
    throw new Error(`Missing id for '${collection}' collection!`)

  const ref = db.collection(collection).doc(id)

  const doc = await ref.get()
  if (doc.exists) {
    const t = serialize<T>(doc)
    if (t) return t
  }
  return null
}

export const getBySlug = async<T>(collection: string, slugProp: string, slug: string): Promise<T | null> => {
  if (!collection)
    throw new Error('Missing collection!')

  if (!slug)
    throw new Error(`Missing slug for '${collection}' collection!`)

  const qry = db.collection(collection)
    .where(slugProp, '==', slug)
    .limit(1)

  const col = await qry.get()
  if (col.docs.length === 1) {
    const t = serialize<T>(col.docs[0])
    if (t) return t
  }
  return null
}

export const getOrCreateFactory = <T extends FlatDoc>(collection: string, nameDecapitalize = false): ((uid: string | undefined, name: string) => Promise<T>) => {
  return async (uid: string | undefined, name: string): Promise<T> => {
    const authorUid = uid

    if (!authorUid)
      throw new Error('User not logged in!')

    if (!name)
      throw new Error(`Missing name for '${collection}' collection!`)

    if (nameDecapitalize)
      name = await asyncCapitalize(name) // People name aware decapitalization
    else
      name = decapitalize(name) // More simple decapitalization for company names etc. Will allow capitals for short names.

    const slug = await asyncSlugify(name)
    if (!slug)
      throw new Error(`Failed to create new item for ${collection} collection, invalid name: [${name}]!`)

    const ref = db.collection(collection).doc(slug)
    const nameSlugs = slug.split('-')
    const slugKeys = await keywordGenerator(name)

    return db.runTransaction(async (transaction): Promise<T> => {
      const createdAt = firebase.firestore.FieldValue.serverTimestamp()
      const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
      const doc = await transaction.get(ref)
      if (doc.exists) {
        const t = serialize<T>(doc)
        if (t) return t
      }
      const item: FlatDoc = { authorUid, name, slug, nameSlugs, slugKeys }
      await transaction.set(ref, { ...item, createdAt, updatedAt }, { merge: true })
      return item as T
    })
  }
}

export const getBySlugFactory = <T extends FlatDoc>(collection: string, limit = 6): ((name: string) => Promise<T[]>) => {
  return async (name: string): Promise<T[]> => {
    if (!name)
      return new Promise(resolve => resolve([]))
    const slug = await asyncSlugify(name)
    if (!slug)
      return new Promise(resolve => resolve([]))

    const refDoc = db.collection(collection).doc(slug)
    const singleDoc = await refDoc.get()
    let tDoc: T | null = null

    const slugs = slug.split('-')
    const refCol = db.collection(collection)
      .where('slugKeys', 'array-contains-any', slugs.slice(0, 10))
      .limit(limit)
    const snapshot = await refCol.get()

    if (snapshot.empty)
      return new Promise(resolve => resolve([]))

    if (singleDoc.exists)
      tDoc = serialize<T>(singleDoc)

    const items: T[] = snapshot.docs.map(doc => serialize<T>(doc)).
      filter(d => !!d && (!tDoc || d.id !== tDoc.id)) as T[]

    if (tDoc)
      items.unshift(tDoc)

    if (!items)
      return new Promise(resolve => resolve([]))

    return items
  }
}

export type StopBinding = () => void

export const bindingFactory = <I, T>(
  rec: Ref<T | null>,
  binder: (
    i?: I,
    slugRef?: Ref<string | null>,
  ) => Promise<StopBinding | null>,
  slugBinder?: (
    slug: string,
    bind: (i: I) => Promise<StopBinding | null>,
  ) => Promise<StopBinding | null>,
  cleanOnUnbind = true,
  emptyState: T | null = null,
): {
  bind: (i?: I) => Promise<StopBinding | null>,
  slugBind: (slug: string) => Promise<StopBinding | null>,
  unbind: StopBinding,
  stop: Ref<StopBinding | null>,
} => {

  const stop: Ref<StopBinding | null> = ref(null)

  let boundToId: null | I | undefined = null
  const slugRef: Ref<string | null> = ref(null)

  function stopBinding() {
    if (stop.value !== null)
      stop.value()
  }

  const bind = async (i?: I): Promise<StopBinding | null> => {
    if (stop.value !== null &&
      !!rec.value &&
      !!i &&
      !!boundToId &&
      boundToId === i) {
      return stop.value
    }
    stopBinding()
    stop.value = await binder(i, slugRef)
    boundToId = i
    return stop.value
  }

  const slugBind = async (slug: string): Promise<StopBinding | null> => {
    if (!slugBinder)
      throw new Error('Missing slug binding function!')
    if (!!slugRef.value &&
      stop.value !== null &&
      !!rec.value &&
      !!slug &&
      slugRef.value === slug) {
      return stop.value
    }
    stopBinding()
    stop.value = await slugBinder(slug, bind)
    slugRef.value = slug
    return stop.value
  }

  const unbind = () => {
    stopBinding()
    if (cleanOnUnbind) {
      rec.value = emptyState
      boundToId = null
      slugRef.value = null
    }
  }

  return {
    bind,
    slugBind,
    unbind,
    stop,
  }
}