declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance
    captchaOnLoad: () => void
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => void) => void
  execute: (sitekey: string, options: ReCaptchaExecuteOptions) => Promise<string>
  render: (id: string, options: ReCaptchaRenderOptions) => never
  getResponse: () => string
}

interface ReCaptchaExecuteOptions {
  action: string
}

interface ReCaptchaRenderOptions {
  sitekey: string
  size: 'invisible'
}

import {
  Ref,
  ref,
} from 'vue'

import { loadScript } from '@/utils/script'

import { isBot } from './tracking'

export const recaptchaReady: Ref<boolean> = ref(false)

const sitekey: string | undefined = process.env.VUE_APP_RECAPTCHA_SITE
const isEnabled: boolean = process.env.VUE_APP_RECAPTCHA_ENABLED === 'true'

export const loadRecaptcha = async (): Promise<void> => {
  if (isBot || recaptchaReady.value || !isEnabled || !sitekey) return
  window.captchaOnLoad = () => {
    if (success && !!window.grecaptcha)
      recaptchaReady.value = true
  }
  const url = `https://www.google.com/recaptcha/api.js?render=${sitekey}&onload=captchaOnLoad`
  const success = await loadScript(url)
}

// eslint-disable-next-line require-await
export async function recaptchaExecute(action: string): Promise<string | null> {
  return new Promise((resolve, reject) => {
    if (!recaptchaReady.value || !window.grecaptcha || !sitekey) reject(null)
    window.grecaptcha.ready(() =>
      window.grecaptcha.execute(String(sitekey), { action })
        .then(token => resolve(token))
        .catch(reason => reject(reason)))
  })
}