import to from 'await-to-js'
import firebase from 'firebase/compat/app'
import {
  ComputedRef,
  Ref,
  computed,
  ref,
} from 'vue'

import { User } from '@/firebase/auth'
import {
  DocumentReference,
  DocumentSnapshot,
  Transaction,
  bindingFactory,
  db,
  serialize,
} from '@/firebase/db'
import { navFail } from '@/utils/nav'
import { clone } from '@/utils/obj'
import {
  getCapitalize,
  nthIndex,
  pad,
} from '@/utils/str'
import {
  TrackingValue,
  trackError,
  trackErrorMessage,
  trackEvent,
} from '@/utils/tracking'
import {
  CompanyEvidence,
  CompanyProfile,
  Profile,
  Service,
  Username,
} from '@/utils/types'
import { trueOnce } from '@/utils/use'
import { user } from '@/utils/user'

import { isMine, myProfile, profile } from '../profile/profile'

export const company: Ref<CompanyProfile | null> = ref<CompanyProfile | null>(null)
export const isMyCompany = computed(() => !!company.value && !!user.value &&
  ((!!company.value.editorUids && user.value.uid in company.value.editorUids)
    || (!!user.value.uid && user.value.uid === company.value.ownerUid)))

export const getCompanyUsername = async (username: string): Promise<Username | null> => {
  username = username.toLowerCase()
  if (!username) return null
  const usernameRef = db.collection('companyUsernames').doc(username)
  const snapshot = await usernameRef.get()
  if (!snapshot.exists) return null
  return snapshot.data() as Username
}

// eslint-disable-next-line require-await
export const companyBindings = bindingFactory<string, CompanyProfile>(company, async (uid, slugRef) => {
  if (!uid)
    return null
  const qry = db.collection('companyProfiles').doc(uid)
  return qry.onSnapshot(
    (doc: DocumentSnapshot) => {
      if (doc.exists) {
        company.value = serialize<CompanyProfile>(doc, 'company')
        if (slugRef)
          slugRef.value = company.value?.username || null
      } else
        trackErrorMessage(`Failed to bind a profile! [${uid}]`)
    },
    error => {
      navFail.value = true
      trackError(error, 'Failed to bind a profile!')
    })
}, async (slug, bind) => {
  if (!slug)
    return null
  const qry = db.collection('companyProfiles')
    .where('username', '==', slug)
    .limit(1)
  const col = await qry.get()
  if (col.docs.length === 1) {
    company.value = serialize<CompanyProfile>(col.docs[0], 'company')
    return bind(col.docs[0].id)
  }
  return null // Special value that let's the outside know it's not bound!
}, true, null)

export const setCompany = async (slug: string): Promise<boolean | string> => {
  if (!!slug && company.value?.username === slug)
    return true

  const [error, success] = await to(companyBindings.slugBind(slug))
  if (error) {
    if (error)
      trackError(error, 'Failed to bind company!', { slug })
    navFail.value = true
    return false
  }

  if (success !== null) {
    return true
  }

  const [usernameProblem, nameData] = await to(getCompanyUsername(slug))
  if (usernameProblem || !nameData) {
    if (usernameProblem)
      trackError(usernameProblem, 'Company username not found!', { slug })
    return false
  }
  if (nameData?.username && nameData.username !== slug
    && nameData.usernameLow === slug.toLowerCase()) {
    return `/company/${nameData.username}`
  }
  return false
}

// eslint-disable-next-line require-await
export const createMyCompany = async (companyData: CompanyProfile): Promise<CompanyProfile> => {
  if (!user.value)
    throw new Error('User not logged in!')
  if (!companyData?.name?.display)
    throw new Error('Missing company name!')

  const capitalize = await getCapitalize()
  const authorUid = user.value.uid
  const ownerUid = user.value.uid
  const editorUids = [ownerUid]
  const companyRef = db.collection('companyProfiles').doc()
  const profileRef = db.collection('profiles').doc(user.value.uid)

  return db.runTransaction(async (transaction: Transaction): Promise<CompanyProfile> => {
    if (!companyData?.name?.display || !companyData?.name?.slug)
      throw new Error('Missing company name!')
    const createdAt = firebase.firestore.FieldValue.serverTimestamp()
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    const visibility = 'public'
    const kind = 'company'
    let counter = 1
    let username = companyData.name.slug.split('-').map(capitalize).join('')
    let original = username
    if (username.length < 4) {
      username = pad('0000', username)
      original = username
      username = username + '1'
    }
    let usernameLow: string = username.toLowerCase()
    let usernameRef: DocumentReference = db.collection('companyUsernames').doc(usernameLow)
    let userNameReady = false
    while (!userNameReady) {
      usernameLow = username.toLowerCase()
      usernameRef = db.collection('companyUsernames').doc(usernameLow)
      // eslint-disable-next-line no-await-in-loop
      const un = await transaction.get(usernameRef)
      if (un.exists) {
        username = original + counter
        counter += 1
      } else {
        userNameReady = true
      }
    }
    // Get & update my profile
    const prof = await transaction.get(profileRef)
    if (!prof.exists)
      throw new Error('Your profile is missing!')
    const profile = serialize<Profile>(prof, 'profile')
    if (!profile)
      throw new Error('Your profile not found!')
    let companies: CompanyEvidence[] = []
    if (profile.companies)
      companies = profile.companies
    companies.push({
      username,
      name: companyData.name.display,
      ref:  companyRef,
      uid:  companyRef.id, // Getting the id before it was even created.. huh :)
    })
    await transaction.set(profileRef, { companies, updatedAt }, { merge: true })
    // Create username
    await transaction.set(usernameRef, {
      username,
      usernameLow,
      companyRef,
      visibility,
      authorUid,
      createdAt,
      updatedAt,
      kind,
    })
    // Create company profile
    const companyProfile: CompanyProfile = {
      username,
      usernameLow,
      usernameRef,
      authorUid,
      ownerUid,
      editorUids,
      visibility,
      ...companyData,
    }
    // Create company
    await transaction.set(companyRef, { ...companyProfile, createdAt, updatedAt }, { merge: true })
    return companyProfile
  })
}

export const updateMyCompany = async (company: CompanyProfile): Promise<boolean> => {
  try {
    if (!user.value)
      throw new Error('User not logged in!')
    const profileRef = db.collection('profiles').doc(user.value.uid)

    const cid = company.id
    if (!cid)
      throw new Error('Company is missing id!')

    const companyRef = db.collection('companyProfiles').doc(cid)
    const companyData = clone(company, true)
    return await db.runTransaction(async (transaction: Transaction): Promise<boolean> => {
      const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
      // Get my cmpany
      const companyReal = await transaction.get(companyRef)
      if (!companyReal.exists)
        throw new Error('Company does not exist!')
      const companyRealData = companyReal.data()
      if (!companyRealData?.username)
        throw new Error('Company is missing username!')
      // Get & update my profile
      const prof = await transaction.get(profileRef)
      if (!prof.exists)
        throw new Error('Your profile is missing!')
      const profile = serialize<Profile>(prof, 'profile')
      if (!profile)
        throw new Error('Your profile not found!')
      let companies: CompanyEvidence[] = []
      if (profile.companies)
        companies = profile.companies
      let exists = false
      for (const key in companies) {
        if (companies[key].uid === cid) {
          companies[key].username = companyRealData.username || companyRealData.username
          companies[key].name = companyData.name?.display || companyRealData.name.display
          companies[key].elevatorPitch = companyData.elevatorPitch || companyRealData.elevatorPitch
          companies[key].icon = companyData.icon || companyRealData.icon
          exists = true
        }
      }
      if (!exists)
        throw new Error('Company does not exist on your profile!')
      await transaction.set(profileRef, { companies, updatedAt }, { merge: true })
      // Update company
      await transaction.set(companyRef, {
        ...companyData,
        update: true,
        updatedAt,
      }, { merge: true })
      return true
    })
  } catch (error) {
    trackError(error, 'Failed to update company!')
    return false
  }
}

export const elevatorPitch = computed(() => {
  if (!company.value?.elevatorPitch && isMyCompany.value) return 'Add your elevator pitch here!'
  if (!company.value?.elevatorPitch) return ''
  const nt = nthIndex(company.value.elevatorPitch, '\n', 2)
  if (nt > 0) {
    // Only allow 2 newlines
    return company.value.elevatorPitch.substring(0, nt)
      + company.value.elevatorPitch.substring(nt).split(/\r?\n/g).join(' ')
  }
  return company.value.elevatorPitch
})

export const sendCompanyMessage = async (user: User, fromCompany: CompanyProfile, toCompany: CompanyProfile, message: string, service?: Service): Promise<boolean> => {
  if (!user)
    throw new Error('User not logged in!')
  const fromUid = fromCompany.id
  const toUid = toCompany.id
  if (!toUid)
    throw new Error('Missing id of company to send to!')
  if (!fromUid)
    throw new Error('Missing id of company to send from!')
  if (fromUid && user.uid !== fromUid)
    throw new Error('Invalid company!')
  if (!fromCompany.name?.display)
    throw new Error('Missing name!')
  if (!fromCompany.username)
    throw new Error('Missing username!')

  const createdAt = firebase.firestore.FieldValue.serverTimestamp()
  const fromRef = db.collection('companyProfiles').doc(fromUid)
  const toRef = db.collection('companyProfiles').doc(toUid)
  const fromName = fromCompany.name?.display || 'Unnamed User'
  const toName = toCompany.name?.display || 'Unnamed User'
  const fromUsername = fromCompany.username
  const toUsername = toCompany.username

  await db.collection('messages').add({
    createdAt,
    fromUid,
    toUid,
    fromRef,
    toRef,
    fromName,
    toName,
    fromUsername,
    toUsername,
    message,
  })

  const tracking: TrackingValue = {
    from_id:        fromUid,
    to_id:          toUid,
    message_length: message.length,
  }
  if (service?.id) {
    tracking.service_id = service.id
    tracking.service_name = service.pitch?.name || ''
  }
  trackEvent('user_message', tracking, user)

  return true
}

export const companySettings = ref<boolean>(false)
export const companySettingsOnce = trueOnce(companySettings)

export const companySettingsReady = computed(() =>
  companySettingsOnce.value &&
  !!user.value &&
  !!myProfile.value,
)

export const genericProfile = (mode: string): ComputedRef<Profile | CompanyProfile | null> => {
  return computed(() => mode === 'profile' ? profile.value : company.value)
}
export const genericEditable = (mode: string): ComputedRef<boolean> => {
  return computed(() => mode === 'profile' ? isMine.value : isMyCompany.value)
}