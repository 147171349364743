import { amIAnyPro, myProfile, proLevel } from '../components/profile/profile'
import { loadLater } from './net'
import { trackingEnabled } from './tracking'
import { user } from './user'

export const helpsScountID: string | undefined = process.env.VUE_APP_HELPSCOUT_ID

const supportEnabled: boolean = (process.env.VUE_APP_TRACKING_ENABLED === 'true') && trackingEnabled
let loaded = false

export interface BeaconObject {
  [key: string]: string | number | boolean | BeaconParam
}
export type BeaconParam = BeaconObject | string

interface BeaconFunc {
  (method: BeaconParam, options?: BeaconParam, data?: BeaconParam): void
  readyQueue: BeaconParam[]
}

declare global {
  interface Window {
    Beacon: BeaconFunc
  }
}

function loadSupport(): void {
  if (loaded || !helpsScountID) return

  loaded = true
  const beacon: BeaconFunc = Object.assign(
    (method: BeaconParam, options: BeaconParam, data?: BeaconParam): void => {
      const params: { [key: string]: BeaconParam } = { method, options }
      if (data)
        params.data = data
      beacon.readyQueue.push(params)
    }, { readyQueue: [] },
  )
  window.Beacon = beacon
  window.Beacon('init', helpsScountID)

  // Load HelpScout Beacon
  const s = document.createElement('script')
  s.setAttribute('async', 'true')
  s.setAttribute('type', 'text/javascript')
  s.setAttribute('src', 'https://beacon-v2.helpscout.net')
  document.body.appendChild(s)

  identifyHelp()
}

export const useSupport = (): void => {
  if (!supportEnabled) return

  loadLater(loadSupport)
}

export const hideHelp = (): void => {
  if (!supportEnabled || !window.Beacon) return

  window.Beacon('destroy')
}

export const showHelp = (): void => {
  if (!supportEnabled || !window.Beacon) return

  window.Beacon('init', helpsScountID)

  identifyHelp()
}

export const identifyHelp = (): void => {
  if (!supportEnabled || !window.Beacon) return
  if (!myProfile.value && !user.value) return

  window.Beacon('config', {
    hideAvatars:      true,
    messagingEnabled: amIAnyPro.value,
    display:          {
      zIndex: 5,
    },
  })

  window.Beacon('identify', {
    name:     myProfile.value?.name?.display || 'DJ User',
    email:    user.value?.email || 'no email',
    proLevel: proLevel.value,
    path:     myProfile.value?.category || '',
    UID:      user.value?.uid || '',
  })
}

// TODO: Add secure mode for identify
