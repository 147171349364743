import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'

// https://firebase.google.com/docs/reference/js/firebase.storage
// https://firebase.google.com/docs/storage/security/start#sample-rules
// https://firebase.google.com/docs/storage/web/upload-files
// https://stackoverflow.com/questions/41304405/firebase-storage-web-how-to-upload-a-file
// https://console.firebase.google.com/project/djplatform/extensions/instances/storage-resize-images?tab=usage
// https://www.naishare.com/blog/how-to-upload-files-to-firebase-cloud-storage-using-javascript

export const Storage = getStorage()

export interface UploadCallbacks {
  error?: (error: Error) => void
  success?: () => void,
  loading: (number: number) => void
}

export const uploadFile = (file: File, path: string, callbacks: UploadCallbacks): void => {

  // create a storage reference
  const storage = ref(Storage, path)

  const metadata = {
    cacheControl: 'max-age=31536000',
    name:         file.name,
    contentType:  file.type,
    size:         file.size,
  }

  const upload = uploadBytesResumable(storage, file, metadata)

  // (if there is an error then the user doesn't need to wait 10 minutes (default behaviour), instead maximum time for uploading an image is set in 2 minutes)
  storage.storage.maxUploadRetryTime = 240000
  // Update progress bar
  upload.on('state_changed', function (snapshot) {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    // send a percentage progress of image upload
    callbacks.loading(progress)
  }, callbacks.error, callbacks.success,
  )
}

// eslint-disable-next-line require-await
export const getPubUrl = async (path: string): Promise<string> => {
  const storage = ref(Storage, path)
  return getDownloadURL(storage)
}

export const pathExists = async (path: string): Promise<boolean> => {
  const storage = ref(Storage, path)
  try {
    const meta = await getMetadata(storage)
    return !!meta
  } catch (error) {
    return false
  }
}
