
export const debounce = (fn: (...rest: never[]) => Promise<void>, delay: number): () => void => {
  let timeoutID: number | undefined = undefined
  // let interrupt:boolean = false
  return (...rest: never[]) => {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(async () => {
      // if (!interrupt)
      await fn(...rest)
    }, delay) as unknown as number
  }
}