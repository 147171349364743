import to from 'await-to-js'
import {
  ComponentPublicInstance,
  ComputedRef,
  Ref,
  computed,
  onMounted,
  provide,
  reactive,
  ref,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'

import { createMyCompany } from '@/components/company/company'
import {
  checkHasNotifs,
  createMyProfile,
  myCategory,
  myProfile,
  updateMyProfile,
} from '@/components/profile/profile'
import {
  UserCredential,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  signIn,
  signUp,
  updateName,
} from '@/firebase/auth'
import { DocumentReference, db, getById } from '@/firebase/db'
import { getUID, recap } from '@/utils/api'
import { debounce } from '@/utils/func'
import { loadLater } from '@/utils/net'
import { clone } from '@/utils/obj'
import { INVALID_PASSWORDS } from '@/utils/passwords'
import { recaptchaExecute, recaptchaReady } from '@/utils/recaptcha'
import {
  capitalize,
  loadStr,
  splitName,
  splitSimpleName,
} from '@/utils/str'
import { trackError, trackEvent } from '@/utils/tracking'
import {
  CategorySubcategoryPair,
  CompanyProfile,
  Dict,
  Invite,
  JobForm,
  NewJobForm,
  NotificationValues,
  Notifications,
  Position,
  Profile,
  Salary,
} from '@/utils/types'
import { trueOnce } from '@/utils/use'
import { user } from '@/utils/user'
import {
  saving,
  shake,
  startSaving,
  stopSaving,
  useValidation,
} from '@/utils/validation'

import { createJob, emptyJobForm } from '../editors/useNewJobEditor'
import { guessMyLocation } from '../settings/location'
import { feeds } from '../settings/notifications'

export const ENTER_FRM = 'EnterEmail'
export const LOGIN_FRM = 'EnterPassword'
export const JOIN_FRM = 'CreateAccount'
export const PATH_FRM = 'ChangePath'
export const NOTIFS_FRM = 'EmailNotifications'
export const CATS_FRM = 'ProfileCategory'
export const SALARY_FRM = 'ProfileSalary'
export const JOB_FRM = 'PostJob'

export type StepType =
  typeof ENTER_FRM |
  typeof LOGIN_FRM |
  typeof JOIN_FRM |
  typeof PATH_FRM |
  typeof NOTIFS_FRM |
  typeof CATS_FRM |
  typeof SALARY_FRM |
  typeof JOB_FRM

// These indicate the target ... but they are not fully implemented yet, and might be removed with the frame instead
export const TARGET_JOB = 'post-job'
export const TARGET_PROFILE = 'create-profile'
export const TARGET_SERVICE = 'post-service'

export type TargetType =
  typeof TARGET_JOB |
  typeof TARGET_PROFILE |
  typeof TARGET_SERVICE

export const targets: TargetType[] = [
  TARGET_JOB,
  TARGET_PROFILE,
  TARGET_SERVICE,
]

// This can also contain the target frame of the wizard or goal like post-job!
export const wizardOpen = ref<TargetType | StepType | null>(null)
export const wizardOpenedOnce = trueOnce(wizardOpen)

export const wasAtPath = ref<boolean>(false)

export const modalIsOpen = computed(() => wizardOpen.value !== null)
export const targetIsJob = computed(() => wizardOpen.value === TARGET_JOB || wizardOpen.value === JOB_FRM)
export const targetIsProfile = computed(() => wizardOpen.value === TARGET_PROFILE)
export const targetIsService = computed(() => wizardOpen.value === TARGET_SERVICE)

export const targetIsSet = computed(() =>
  targetIsJob.value ||
  targetIsProfile.value ||
  targetIsService.value)

export const fullScreen = computed(() => targetIsJob.value)

export const inAuthStep = computed(() =>
  step.value === ENTER_FRM ||
  step.value === LOGIN_FRM ||
  step.value === JOIN_FRM)

export const loginProfile: Ref<Profile | null> = ref<Profile | null>(null)
export const accountChecked: Ref<boolean> = ref<boolean>(false)
export const accountExists: Ref<boolean> = ref<boolean>(false)
export const dir: Ref<boolean> = ref(true)
export const step: Ref<StepType> = ref(ENTER_FRM)

export const goTo = (target: StepType, direction = true): void => {
  // This navigates to different wizard cards, swaps components and makes animation
  if (step.value !== target) {
    dir.value = direction
    step.value = target
  }
}
export const authGoTo = (target: StepType, direction = true): void => {
  if (!user.value)
    backToEnterAndReset()
  else
    goTo(target, direction)
}

// Before auth
export const goToEnter = (direction = true): void => goTo(ENTER_FRM, direction)
export const goToLogin = (direction = true): void => goTo(LOGIN_FRM, direction)
export const goToJoin = (direction = true): void => goTo(JOIN_FRM, direction)
// After auth
export const goToPath = (direction = true): void => authGoTo(PATH_FRM, direction)
export const goToNotifs = (direction = true): void => authGoTo(NOTIFS_FRM, direction)
export const goToCats = (direction = true): void => authGoTo(CATS_FRM, direction)
export const goToSalary = (direction = true): void => authGoTo(SALARY_FRM, direction)
export const goToJob = (direction = true): void => authGoTo(JOB_FRM, direction)

// This only clears info about the logged user which is added during login ..
export const resetEnter = (): void => {
  dir.value = true
  loginProfile.value = null
  accountExists.value = false
  accountChecked.value = false
  wasAtPath.value = false
}

// Go to enter and also close modal if open
export const backToEnterResetAndClose = (): void => {
  resetEnter()

  wizardOpen.value = null // closing the modal!

  goToEnter(false) // go backward
}

// Jump to start and clear all form state
export const backToEnterAndReset = (): void => {
  resetEnter()

  goToEnter(false) // go backward
}

// Just go to enter screen
export const backToEnter = (): void => goToEnter(false)

export const openWizardToEnter = (): void => { wizardOpen.value = ENTER_FRM }
export const openWizardToLogin = (): void => { wizardOpen.value = LOGIN_FRM }
export const openWizardToJoin = (): void => { wizardOpen.value = JOIN_FRM }
export const openWizardToPath = (): void => { wizardOpen.value = PATH_FRM }
export const openWizardToNotifs = (): void => { wizardOpen.value = NOTIFS_FRM }
export const openWizardToCats = (): void => { wizardOpen.value = CATS_FRM }
export const openWizardToSalary = (): void => { wizardOpen.value = SALARY_FRM }
export const openWizardToJob = (): void => { wizardOpen.value = JOB_FRM }

export const formRef: Ref<ComponentPublicInstance<HTMLFormElement> | null> =
  ref<ComponentPublicInstance<HTMLFormElement> | null>(null)

export interface FormEmail {
  email: string
}

export const useWizzard = (): void => {

  watch(wizardOpen, (newOpen, wasOpen) => {
    if (!wasOpen && newOpen) {
      switch (newOpen) {
      case PATH_FRM:
        goToPath()
        break
      case CATS_FRM:
        goToCats()
        break
      case NOTIFS_FRM:
        goToNotifs()
        break
      case SALARY_FRM:
        goToSalary()
        break
      case TARGET_JOB:
      case JOB_FRM:
        goToJob()
        break
      default:
        backToEnterAndReset()
        break
      }
    }
  })

}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useEnter = () => {

  const form = reactive<FormEmail>({
    email: '',
  })
  const schema: yup.SchemaOf<FormEmail> = yup.object().shape({
    email: yup.string().email().required().max(256).label('Email'),
  })

  const {
    lastError,
    errors,
    errorList,
    clearErrors,
    validatorFactory,
    showErrorMessage,
    isValidAt,
    validateAll,
  } = useValidation({ schema, form, formRef })

  watch(() => form.email, validatorFactory('email', true))
  watch(() => form.email, debounce(async (newValue: string, oldValue: string) => {
    if (newValue && oldValue !== newValue) {
      if (!(await isValidAt('email'))) {
        accountExists.value = false
        return
      }
      const [problem, uid]: [Error | null, string | undefined] = await to(getUID(newValue))
      if (problem || !uid) {
        accountExists.value = false
        return
      }
      const prof = await getById<Profile>('profiles', uid)
      if (!prof || prof.disabled) {
        accountExists.value = false
        return
      }
      accountChecked.value = true
      accountExists.value = true
    }
  }, 500))

  provide('emailErrors', errors)
  provide('emailForm', form)
  provide('accountExists', accountExists)
  provide('accountChecked', accountChecked)

  async function next() {
    if (saving.value) {
      shake()
      return
    }
    clearErrors()
    loginProfile.value = null
    if (user.value) {
      goToPath()
      return
    }
    startSaving()

    if (!(await validateAll()))
      return

    const [error, methods] = await to(fetchSignInMethodsForEmail(form.email))
    if (error) {
      const msg = 'Failed to get sign in method!'
      trackError(error, msg)
      showErrorMessage(msg)
      return
    }
    if (!methods?.includes('password')) {
      accountExists.value = false
      clearErrors()

      goToJoin()
      await stopSaving()
      return
    }
    const [problem, uid]: [Error | null, string | undefined] = await to(getUID(form.email))
    if (problem || !uid) {
      const msg = 'Error while loading user info!'
      if (problem)
        trackError(problem, msg)
      showErrorMessage(msg)

      accountExists.value = false
      return
    }
    const prof = await getById<Profile>('profiles', uid)
    if (!prof) {
      showErrorMessage('Missing profile!')

      accountExists.value = false
      return
    }
    if (prof.disabled) {
      showErrorMessage('This profile is disabled! Please contact support.')
      return
    }

    loginProfile.value = prof
    accountChecked.value = true
    accountExists.value = true

    clearErrors()
    goToLogin()
    await stopSaving()
  }

  return {
    form,
    errors,
    errorList,
    lastError,
    next,
  }
}

export interface FormLogin {
  password: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLogin = ({ emailInput, target }: { emailInput: Ref<string>, target: ComputedRef<string | null> }) => {

  const form = reactive<FormLogin>({
    password: '',
  })

  const schema: yup.SchemaOf<FormLogin> = yup.object().shape({
    password: yup.string().required().max(128).label('Password'),
  })

  const {
    errors,
    errorList,
    lastError,
    clearErrors,
    showError,
    validatorFactory,
    showErrorMessage,
    validateAll,
  } = useValidation({ schema, form, formRef })
  watch(() => form.password, validatorFactory('password', true))

  provide('loginErrors', errors)
  provide('loginForm', form)

  const { push, currentRoute } = useRouter()

  async function login() {
    if (saving.value) {
      shake()
      return
    }
    clearErrors()
    if (user.value) {
      goToPath()
      return
    }
    startSaving()

    const email = emailInput.value
    if (!email) {
      showErrorMessage('Missing email!')
      backToEnter()
      return
    }

    if (!(await validateAll()))
      return

    if (recaptchaReady.value) {
      const [error, token]: [Error | null, string | null | undefined] =
        await to(recaptchaExecute('login'))
      if (error || !token) {
        const msg = 'Error generating recaptcha!'
        if (error)
          trackError(error, msg)
        showErrorMessage(msg)
        return
      }
      const [problem, pass]: [Error | null, boolean | undefined] =
        await to(recap(token))
      if (problem || !pass) {
        const msg = 'Error validating recaptcha!'
        if (problem)
          trackError(problem, msg)
        showErrorMessage(msg)
        return
      }
    }

    const [error, cred]: [Error | null, UserCredential | undefined] =
      await to(signIn(email, form.password))
    if (error || !cred?.user) {
      if (error) {
        showError(error)
        trackError(error, 'Login failed!')
      } else {
        const msg = 'Error while logging in!'
        showErrorMessage(msg)
      }
      return
    }

    trackEvent('login', null, cred.user)

    user.value = cred.user

    // Cleanup
    emailInput.value = ''
    form.password = ''
    clearErrors()

    // Guess the location:
    if (!loginProfile.value?.loc) {
      const loc = await guessMyLocation()
      if (loc)
        await updateMyProfile({ loc })
    }

    await stopSaving()

    // This `target` is from url but it could be also from modal which is: `targetIsJob`
    if (target.value === TARGET_JOB || target.value === JOB_FRM || targetIsJob.value) {
      goToJob()
      return
    } else if (currentRoute.value?.query.redirect &&
      typeof currentRoute.value?.query.redirect === 'string' &&
      currentRoute.value?.query.redirect.startsWith('/')) {
      const path = currentRoute.value?.query.redirect
      delete currentRoute.value?.query.redirect
      await push({ path })
      backToEnterResetAndClose()
      return
    } else if (loginProfile.value?.category === 'candidate') {
      if (!loginProfile.value?.category) {
        goToPath()
        resetEnter()
        return
      } else if (!checkHasNotifs(loginProfile.value)) {
        goToNotifs()
        resetEnter()
        return
      }
      const query = { ...currentRoute.value?.query }
      await push({ name: 'profile', query })
      backToEnterResetAndClose()
      return
    } else if (loginProfile.value?.category === 'business') {
      const query = { ...currentRoute.value?.query }
      if (loginProfile.value?.companies?.length && loginProfile.value.companies[0]) {
        const company = loginProfile.value.companies[0].username
        await push({ name: 'company-view', query, params: { company }})
        backToEnterResetAndClose()
        return
      }
      await push({ name: 'company', query })
      backToEnterResetAndClose()
      return
    }

    // It's hard to get here ...
    const query = { ...currentRoute.value?.query }
    await push({ name: 'home', query })
    backToEnterResetAndClose()
  }

  return {
    form,
    errors,
    errorList,
    lastError,
    login,
    back: backToEnter,
  }
}

export interface FormAccount {
  email: string
  fullName: string
  companyName: string
  password: string
  consent: boolean
}

export const emptyAccount = {
  email:       '',
  fullName:    '',
  companyName: '',
  password:    '',
  consent:     false,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAccount = ({ emailInput, target }: { emailInput: Ref<string>, target: ComputedRef<string | null> }) => {

  const needCompany = computed(() =>
    target.value === TARGET_JOB ||
    target.value === JOB_FRM ||
    target.value === TARGET_SERVICE ||
    targetIsJob.value)

  const form = reactive<FormAccount>(clone(emptyAccount))

  const schema: yup.SchemaOf<FormAccount> = yup.object().shape({
    email:       yup.string().email().required().max(256).label('Email'),
    fullName:    yup.string().required().min(3).max(256).label('Full name'),
    companyName: yup.string().max(128).defined().test(
      'need-company-to-post-job',
      'You need to enter your business name',
      company => !needCompany.value || !!company,
    ).label('Company Name'),
    password: yup.string().required().min(8).max(128)
      .notOneOf([yup.ref('fullName'), yup.ref('email'), ...INVALID_PASSWORDS],
        'Password can\'t be that simple to guess')
      .label('Password'),
    consent: yup.boolean().required().test('is-true',
      'You must agree with mentioned terms and policy',
      value => !!value === true).label('Consent'),
  })

  const {
    errors,
    errorList,
    lastError,
    clearErrors,
    showError,
    validatorFactory,
    showErrorMessage,
    validateAll,
  } = useValidation({ schema, form, formRef })
  watch(() => form.email, validatorFactory('email', true))
  watch(() => form.fullName, validatorFactory('fullName'))
  watch(() => form.companyName, validatorFactory('companyName'))
  watch(() => form.password, validatorFactory('password', true))
  watch(() => form.consent, validatorFactory('consent'))

  provide('accountErrors', errors)
  provide('accountForm', form)
  provide('needCompany', needCompany)

  const { currentRoute, push } = useRouter()

  function cleanUp() {
    // Cleanup
    Object.assign(form, clone(emptyAccount))
    emailInput.value = ''
    form.email = ''
    clearErrors()
  }

  async function createAccount() {

    if (saving.value) {
      shake()
      return
    }
    clearErrors()
    if (user.value) {
      goToPath()
      return
    }
    startSaving()

    if (!emailInput.value) {
      showErrorMessage('Missing email!')
      backToEnter()
      return
    }
    form.email = emailInput.value.toLowerCase()

    if (!(await validateAll()))
      return

    if (recaptchaReady.value) {
      const [error, token]: [Error | null, string | null | undefined] =
        await to(recaptchaExecute('signup'))
      if (error || !token) {
        const msg = 'Error generating recaptcha!'
        if (error)
          trackError(error, msg)
        showErrorMessage(msg)
        return
      }
      const [problem, pass]: [Error | null, boolean | undefined] =
        await to(recap(token))
      if (problem || !pass) {
        const msg = 'Error validating recaptcha!'
        if (problem)
          trackError(problem, msg)
        showErrorMessage(msg)
        return
      }
    }

    const query = currentRoute.value?.query
    const enterQuery: Dict = {}
    Object.keys(query).forEach((k: string) => enterQuery[k] = String(query[k]))

    let coupon: string | null = null
    if (enterQuery?.coupon)
      coupon = enterQuery.coupon

    let inviteData: Invite | null = null
    let invite: string | null = null
    if (enterQuery?.invite)
      invite = enterQuery.invite
    if (invite) {
      const invitor = await getById<Profile>('profiles', invite)
      if (invitor) {
        const name = invitor.name?.display
        const username = invitor.username
        const ref: DocumentReference = db.collection('profiles').doc(invite)

        if (name && username)
          inviteData = { name, username, ref }
      }
    }

    if (capitalize.value === null)
      throw new Error('Capitalize not loaded!')

    const nameUpdate = splitName(form.fullName)
    if (!nameUpdate) {
      showErrorMessage('Failed to parse name!')
      return
    }

    const [issue, cred]: [Error | null, UserCredential | undefined] =
      await to(signUp(form.email, form.password))
    if (issue || !cred?.user) {
      if (issue) {
        showError(issue)
        trackError(issue, 'Signup failed!')
      } else {
        const msg = 'Failed to create account!'
        showErrorMessage(msg)
      }
      return
    }

    await updateName(cred.user, form.fullName)
    const profile = await createMyProfile(nameUpdate, cred.user, enterQuery, coupon, inviteData)
    if (!profile) {
      showErrorMessage('Failed to create profile on signup. Please contact support!')
      return
    }

    // TODO: Send this much later via task!
    await sendEmailVerification(cred.user)

    trackEvent('sign_up', null, cred.user)

    user.value = cred.user

    // Try to set default location
    const loc = await guessMyLocation()
    if (loc)
      await updateMyProfile({ loc })

    if (needCompany.value) {

      const companyName = form.companyName

      const update: Profile = {
        category: 'business',
        syncDrip: true,
      }
      update.notifs = clone(myProfile.value?.notifs) || {}
      const email = true
      for (const field in feeds)
        if (feeds[field].business)
          update.notifs[field] = { email, ...feeds[field] }
      await updateMyProfile(update)

      const name = splitSimpleName(companyName)
      if (!name) {
        showErrorMessage('Invalid company name!')
        return
      }

      const [error, newCompany] = await to(createMyCompany({ name }))
      if (error || !newCompany) {
        const msg = 'Failed to create a company!'
        if (error)
          trackError(error, msg)
        showErrorMessage(msg)
        return
      }

      cleanUp()

      await stopSaving()

      // This `target` is from url but it could be also from modal which is: `targetIsJob`
      if (target.value === TARGET_JOB || target.value === JOB_FRM || targetIsJob.value) {
        goToJob()
        return
      }

      // TODO: Create a card for service in the future ...
      const query = { ...currentRoute.value?.query }

      if (myProfile.value?.companies?.length && myProfile.value.companies[0]) {
        const company = myProfile.value.companies[0].username
        await push({ name: 'company-view', query, params: { company }})
      } else {
        await push({ name: 'company', query })
      }
      return
    } else if (target.value === TARGET_PROFILE) {

      const update: Profile = {
        category: 'candidate',
        syncDrip: true,
      }
      update.notifs = clone(myProfile.value?.notifs) || {}
      update.categories = clone(myProfile.value?.categories) || []
      const email = true
      update.notifs.newsletter = { email, ...feeds.newsletter }
      await updateMyProfile(update)

      cleanUp()
      goToNotifs()
      await stopSaving()
      return
    }

    cleanUp()
    goToPath()
    await stopSaving()
  }

  onMounted(() => loadLater(loadStr))

  return {
    needCompany,
    form,
    errors,
    errorList,
    lastError,
    createAccount,
    back: backToEnter,
  }
}

export interface FormPath {
  path: string | null
}

export const emptyPath = {
  path: null,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePath = () => {

  const form = reactive<FormPath>(clone(emptyPath))

  const schema: yup.SchemaOf<FormPath> = yup.object().shape({
    path: yup.string().nullable().required().label('Account type'),
  })

  const {
    errors,
    errorList,
    lastError,
    clearErrors,
    validateAll,
  } = useValidation({ schema, form, formRef })

  provide('pathErrors', errors)
  provide('pathForm', form)

  watch(myCategory, cat => form.path = cat, { immediate: true })

  async function savePath() {
    if (saving.value) {
      shake()
      return
    }
    clearErrors()
    if (!user.value) {
      backToEnterAndReset()
      return
    }
    startSaving()

    if (!(await validateAll()))
      return

    const category = form.path

    const update: Profile = {
      category,
      syncDrip: true,
    }
    const email = true
    update.notifs = clone(myProfile.value?.notifs) || {}
    if (category === 'business') {
      for (const field in feeds)
        if (feeds[field].business)
          update.notifs[field] = { email, ...feeds[field] }
    } else if (category === 'candidate') {
      update.notifs.newsletter = { email, ...feeds.newsletter }
      update.categories = clone(myProfile.value?.categories) || []
    }
    await updateMyProfile(update)

    trackEvent('path_selected')

    clearErrors()

    await stopSaving()

    category === 'business' ? goToNotifs() : goToCats()
  }

  return {
    form,
    errors,
    errorList,
    lastError,
    savePath,
  }
}

export interface FormNotifications {
  [key: string]: boolean
}

export const myFeeds = computed<Notifications>(() => {
  const fids: Notifications = {} as Notifications
  for (const field in feeds)
    if (!!feeds[field].force || (
      (myCategory.value === 'candidate' && !feeds[field].business) ||
      (myCategory.value === 'business' && !!feeds[field].business)
    ))
      fids[field] = feeds[field]
  return fids
})

export interface CategoriesForm {
  categories?: CategorySubcategoryPair[]
}

export const emptyCategories = {
  categories: [{
    category: {
      name: '',
      slug: '',
    },
    subcategory: {
      name: '',
      slug: '',
    },
  }],
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCategories = () => {

  const form = reactive<CategoriesForm>(clone(emptyCategories))

  const schema: yup.SchemaOf<CategoriesForm> = yup.object().shape({
    categories: yup.array().ensure().min(1, 'Choose a category').label('Category').defined(),
  })

  watch(myProfile, newValue => {
    if (newValue) {
      if (newValue.categories && newValue.categories.length) form.categories = newValue.categories as CategorySubcategoryPair[]
    }
  }, { immediate: true })

  const {
    errors,
    clearErrors,
    isValidAt,
    showError,
  } = useValidation({ schema, form })

  provide('categoriesErrors', errors)

  provide('categoriesForm', form)

  async function save() {
    if (saving.value) {
      shake()
      return false
    }
    clearErrors()
    if (!user.value) {
      backToEnterAndReset()
      return false
    }

    startSaving()

    if (!(await isValidAt('categories'))) {
      const error: Error = { name: 'categories', message: 'Choose a category' }
      showError(error)
      shake()
      await stopSaving()
      return false
    }

    const categories = clone(form.categories)

    await updateMyProfile({
      categories,
      syncDrip: true,
    })

    await stopSaving()
    clearErrors()

    trackEvent('profile_categories_updated')

    return true
  }

  async function saveCategories() {
    if (!(await save())) return
    goToSalary()
  }

  return {
    form,
    saveCategories,
  }
}

export interface SalaryForm {
  salary?: Salary
}

export const emptySalary = {
  salary: {
    currency: 'USD',
    type:     'yearly',
    hidden:   false,
  } as Salary,

}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSalary = () => {

  const form = reactive<SalaryForm>(clone(emptySalary))

  watch(myProfile, newValue => {
    if (newValue) {
      if (newValue.salary) form.salary = newValue.salary as Salary
    }
  }, { immediate: true })

  const schema: yup.SchemaOf<SalaryForm> = yup.object().shape({
    salary: yup.object().shape({
      from: yup.number().nullable().positive(),
      to:   yup.number().min(yup.ref('from')).nullable().positive(),
    }).required().label('Salary').defined(),
  })

  const {
    errors,
    clearErrors,
    showError,
    validateAll,
  } = useValidation({ schema, form })

  provide('salaryErrors', errors)
  provide('salaryForm', form)

  async function save() {
    if (saving.value) {
      shake()
      return false
    }
    clearErrors()
    if (!user.value) {
      backToEnterAndReset()
      return false
    }

    if (!(await validateAll())) {
      const error: Error = { name: 'salary', message: 'Add salary range from' }
      showError(error)
      shake()
      await stopSaving()
      return false
    }

    const salary = clone(form.salary)
    await updateMyProfile({
      salary,
      syncDrip: true,
    })

    await stopSaving()
    clearErrors()

    trackEvent('salary_saved')
    return true
  }

  async function saveSalary() {
    if (!(await save())) return

    goToNotifs()

  }

  return {
    form,
    saveSalary,
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotifications = () => {

  const form = reactive<FormNotifications>(clone({}))

  provide('notificationsForm', form)

  const notifs = computed(() => myProfile.value?.notifs)
  watch(notifs, ntfs => {
    for (const field in myFeeds.value)
      if (ntfs && ntfs[field])
        form[field] = ntfs[field].force || ntfs[field].email
      else
        form[field] = myFeeds.value[field].business || false
  }, { immediate: true })

  const { push, currentRoute } = useRouter()

  async function saveNotifications() {
    if (saving.value) {
      shake()
      return
    }
    if (!user.value) {
      backToEnterAndReset()
      return
    }
    startSaving()

    const notifs: NotificationValues = clone(myProfile.value?.notifs) || {}
    for (const field in myFeeds.value)
      notifs[field] = { ...feeds[field], email: form[field] }

    await updateMyProfile({
      notifs,
      syncDrip: true,
    })

    await stopSaving()

    trackEvent('notifications_saved')

    const query = { ...currentRoute.value?.query }
    if (myProfile.value?.category === 'candidate') {
      await push({ name: 'profile', query })
      backToEnterResetAndClose()
      return
    } else if (myProfile.value?.category === 'business') {
      if (myProfile.value?.companies?.length && myProfile.value.companies[0]) {
        const company = myProfile.value?.companies[0].username
        await push({ name: 'company-view', query, params: { company }})
        backToEnterResetAndClose()
        return
      }
      await push({ name: 'company', query })
      backToEnterResetAndClose()
      return
    }
    await push({ name: 'home', query })
    backToEnterResetAndClose()
  }

  function goBack() {
    if (myProfile.value?.category === 'candidate') goToSalary()
    if (myProfile.value?.category === 'business') goToPath()
  }

  return {
    form,
    saveNotifications,
    goBack,
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePostAJob = () => {

  const form = reactive<JobForm>(clone(emptyJobForm))
  const state = reactive<{
    positions: Position[]
    suggestPosition: boolean
    suggestingPosition: boolean
  }>({
    positions:          [],
    suggestPosition:    false,
    suggestingPosition: false,
  })
  provide('jobForm', form)

  const { push, currentRoute } = useRouter()

  onMounted(() => {
    trackEvent('job_modal_open')
    loadLater(loadStr)
  })

  const jobFormSchema: yup.SchemaOf<NewJobForm> = yup.object().shape({
    title:      yup.string().default('').max(128).required().label('Job Title').defined(),
    categories: yup.array().ensure().min(1, 'Choose a category').label('Category').defined(),
    salary:     yup.object().shape({
      from: yup.number().required('Add salary range from'),
      to:   yup.number(),
    }).required().label('Salary').defined(),
  })

  const {
    errors,
    errorList,
    lastError,
    validatorFactory,
    clearErrors,
    showError,
    showErrorMessage,
    validateAll,
  } = useValidation({ schema: jobFormSchema, form: form })
  provide('jobErrors', errors)

  watch(() => form.title, validatorFactory('title'))
  watch(() => form.categories, validatorFactory('category'))
  watch(() => form.skills, validatorFactory('skills'))

  async function saveAndContinue() {
    if (saving.value) {
      shake()
      return false
    }
    clearErrors()
    if (!user.value) {
      backToEnterAndReset()
      return false
    }
    if (!myProfile.value) {
      backToEnterAndReset()
      return false
    }
    if (!myProfile.value?.companies?.length) {
      backToEnterAndReset()
      // TODO: show create company card
      await push({ name: 'company' })
      return false
    }

    // Get the company of user!
    const company = await getById<CompanyProfile>('companyProfiles', myProfile.value?.companies[0].uid)

    if (!company?.id) {
      showErrorMessage('Missing company!')
      return false
    }

    saving.value = true

    if (!(await validateAll()))
      return false

    const newJob = clone(form)

    const [problem, createdJob]: [Error | null, JobForm | undefined] = await to(createJob(newJob, company))
    if (problem || !createdJob) {
      if (problem)
        showError(problem)
      else
        showErrorMessage('Creating job failed! Please contact support!')
      return false
    }

    trackEvent('job_modal_save', { job: form.title || '' })

    if (!company.username || !createdJob.slug) {
      showErrorMessage('Creating job failed! Please contact support!')
      return false
    }

    clearErrors()

    const query = currentRoute.value?.query || {}

    await push({
      name:   'job-view',
      params: {
        companySlug: company.username,
        jobSlug:     createdJob.slug,
      },
      query: { ...query, step: 'job-2' },
    })

    await stopSaving()

    backToEnterResetAndClose()

    return true
  }

  function goBack() {
    if (wasAtPath.value)
      goToPath()
    else
      backToEnterResetAndClose()
  }

  return {
    form,
    saveAndContinue,
    errors,
    errorList,
    lastError,
    state,
    goBack,
  }
}
