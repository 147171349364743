import { nextTick, ref } from 'vue'

import { myProfile } from '../components/profile/profile'
import { loadLater } from './net'
import { doNotTrack, trackEvent } from './tracking'
import { user } from './user'

export const productID: string | undefined = process.env.VUE_APP_BEAMER_ID

export const newUpdates = ref<number>(0)

let updatesEnabled = false
let loaded = false
let showNeeded = false

interface BeamerConfig {
  [key: string]:
  string |
  boolean |
  number |
  ((...x: never[]) => void)
}

interface BeamerFunc {
  init: () => void
  show: () => void
  hide: () => void
  update: (config: BeamerConfig) => void
}

declare global {
  interface Window {
    beamer_config: BeamerConfig
    Beamer: BeamerFunc
  }
}

function counter(count: number): void {
  newUpdates.value = Number(count) >= 1 ? 1 : 0 // limit on maximum number of news ...
}

function loadBeamer(): void {
  if (loaded ||!productID) return
  loaded = true

  const path = myProfile.value?.category || ''
  const visibility = myProfile.value?.visibility || ''
  const company_name = myProfile.value?.companies?.length ? myProfile.value?.companies[0].name : ''
  const filters: string[] = ['app_user']
  if (path)
    filters.push(path)
  if (visibility)
    filters.push(visibility)
  if (myProfile.value?.pro?.business)
    filters.push('business_pro')
  if (myProfile.value?.pro?.candidate)
    filters.push('candidate_pro')
  if (myProfile.value?.pro?.business || myProfile.value?.pro?.candidate)
    filters.push('is_pro')
  if (company_name)
    filters.push('has_company')

  // Docs: https://www.getbeamer.com/docs/#parameters
  window.beamer_config = {
    product_id:       productID, // This is your product code on Beamer
    selector:         '#updates',                                      /* Optional: Id, class (or list of both) of the HTML element to use as a button */
    show:             false,
    // display : 'right',                                     /* Optional: Choose how to display the Beamer panel in your site */
    // top: 0,                                                /* Optional: Top position offset for the notification bubble */
    // right: 0,                                              /* Optional: Right position offset for the notification bubble */
    // bottom: 0,                                             /* Optional: Bottom position offset for the notification bubble */
    // left: 0,                                               /* Optional: Left position offset for the notification bubble */
    // button_position: 'bottom-right',                       /* Optional: Position for the notification button that shows up when the selector parameter is not set */
    // icon: 'bell_lines',                                    /* Optional: Alternative icon to display in the notification button */
    // language: 'EN',                                        /* Optional: Bring news in the language of choice */
    // filter: 'admin',                                       /* Optional: Bring the news for a certain role as well as all the public news */
    lazy:             true,                                               /* Optional: true if you want to manually start the script by calling Beamer.init() */
    // alert : true,                                          /* Optional: false if you don't want to initialize the selector */
    counter:          false,
    delay:            0,                                                 /* Optional: Delay (in milliseconds) before initializing Beamer */
    embed:            true,                                              /* Optional: true if you want to embed and display the feed inside the element selected by the 'selector' parameter */
    // mobile : true,                                         /* Optional: false if you don't want to initialize Beamer on mobile devices */
    // notification_prompt : 'sidebar',                       /* Optional: override the method selected to prompt users for permission to receive web push notifications */
    callback:         counter,                                        /* Optional: Beamer will call this function, with the number of new features as a parameter, after the initialization */
    // onclick : your_onclick_function(url, openInNewWindow), /* Optional: Beamer will call this function when a user clicks on a link in one of your posts */
    // onopen : your_onopen_function,                         /* Optional: Beamer will call this function when opening the panel */
    // onclose : your_onclose_function,                       /* Optional: Beamer will call this function when closing the panel */
    // ---------------Visitor Information---------------
    user_firstname:   myProfile.value?.name?.first || 'DJ',     /* Optional: Input your user firstname for better statistics */
    user_lastname:    myProfile.value?.name?.last || 'User',     /* Optional: Input your user lastname for better statistics */
    user_email:       user.value?.email || 'no email',              /* Optional: Input your user email for better statistics */
    user_id:          user.value?.uid || 'no email',                   /* Optional: Input your user ID for better statistics */
    is_business_pro:  !!myProfile.value?.pro?.business,
    is_candidate_pro: !!myProfile.value?.pro?.candidate,
    username:         myProfile.value?.username || '',
    user_path:        path,
    visibility:       visibility,
    company_name:     company_name,
    filter:           filters.join(';'),                                /* DOCS: https://www.getbeamer.com/help/how-to-use-segmentation*/
  }

  // Load Beamer Beamer
  const s = document.createElement('script')
  s.setAttribute('async', 'true')
  s.setAttribute('type', 'text/javascript')
  s.setAttribute('src', 'https://app.getbeamer.com/js/beamer-embed.js')
  s.onload = async () => {
    await nextTick()
    initUpdates()
    if (showNeeded) {
      showNeeded = false
      showUpdates()
    }
  }
  document.body.appendChild(s)
}

export const useUpdates = (): void => {
  updatesEnabled = !doNotTrack()

  if (!updatesEnabled) return

  loadLater(loadBeamer)
}

export const initUpdates = (): void => {
  if (!updatesEnabled || !window.Beamer) return

  // updateUpdatesConfig()
  window.Beamer.init()

}

export const updateUpdatesConfig = (): void => {
  if (!updatesEnabled || !window.Beamer) return

  if (!myProfile.value && !user.value) return

  const config = {
    user_firstname: myProfile.value?.name?.first || '',
    user_lastname:  myProfile.value?.name?.last || '',
    user_email:     user.value?.email || '',
    user_id:        user.value?.uid || '',
  }
  window.Beamer.update(config)
}

export const hideUpdates = (): void => {
  if (!updatesEnabled || !window.Beamer) return

  window.Beamer.hide()
}

export const showUpdates = (): void => {
  if (!updatesEnabled || !window.Beamer) {
    showNeeded = true
    return
  }

  window.Beamer.show()

  trackEvent('updates_show')
}
