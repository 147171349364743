import {
  Ref,
  computed,
  nextTick,
  ref,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'

import { ON_DEV, ON_PROD } from './env'
import { TrackingValue } from './tracking'

export const getMeta = (metaName: string): string => {
  const metas = document.getElementsByTagName('meta')

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content') || ''
    }
  }

  return ''
}

export const setMeta = (metaName: string, value: string, attributeName = 'name'): boolean => {
  const metas = document.getElementsByTagName('meta')

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute(attributeName) === metaName) {
      metas[i].setAttribute('content', value)
      return true
    }
  }

  return false
}

export const getLink = (linkName: string): string => {
  const metas = document.getElementsByTagName('link')

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('rel') === linkName) {
      return metas[i].getAttribute('href') || ''
    }
  }

  return ''
}

export const setLink = (linkName: string, href: string): boolean => {
  const links = document.getElementsByTagName('link')

  for (let i = 0; i < links.length; i++) {
    if (links[i].getAttribute('rel') === linkName) {
      links[i].setAttribute('href', href)
      return true
    }
  }

  return false
}

export const setCanonical = (href: string): boolean => {
  if (!href) return false
  if (href.indexOf('http') !== 0) {
    if (ON_PROD)
      href = `https://dynamitejobs.com${href}`
  }
  return setLink('canonical', href)
}

export const getURLParameter = (param: string): string | null => {
  const Url = window.URL || window.webkitURL
  if (!Url) return null
  const url_string = window.location.href
  const url = new Url(url_string)
  const dntp = url.searchParams.get(param)
  if (dntp)
    return dntp
  return null
}

export const getAppMeta = (): TrackingValue => {
  try {
    let metaStrig = getMeta('dj:app_meta')
    let source = getURLParameter('source')
    if (!source)
      source = getURLParameter('utm_source')
    if (!source)
      source = 'dj-platform'
    if (ON_DEV)
      metaStrig = 's-default:v-dev-1-2-3:p-false'
    const { s: service, v: version, p: production } = metaStrig
      .split(':')
      .map(p => p.split(/-(.+)/, 2))
      .reduce<TrackingValue>((d, p) => (d[p[0]] = p[1], d), {})
    const meta: TrackingValue = {
      service,
      version,
      production,
      source,
    }
    const dst = getURLParameter('dst') || ''
    if (dst)
      meta.dst = dst
    return meta
  } catch (error) {
    if (ON_DEV) // eslint-disable-next-line no-console
      console.error(error)
    return {}
  }
}

export const title = ref<string>('')

export const initTitle = (): Ref<string> => {

  const { currentRoute } = useRouter()

  const meta = computed(() => currentRoute.value?.meta || {})
  const canonical = computed(() => meta.value.canonical || null)

  const pageTitle = computed(() => {
    const routeTitle = String(meta.value?.title || '')
    let newTitle = ''
    if (routeTitle) {
      newTitle = `${routeTitle} | Dynamite Jobs`
    } else if (title.value) {
      const routeSuffix = String(meta.value?.suffix || '')
      if (routeSuffix) {
        newTitle = `${title.value} - ${routeSuffix} | Dynamite Jobs`
      } else {
        newTitle = `${title.value} | Dynamite Jobs`
      }
    }
    return newTitle
  })

  const updateWindowTitle = (): void => {
    if (window.document.title !== pageTitle.value) {
      window.document.title = pageTitle.value
      setMeta('twitter:title', pageTitle.value)
      setMeta('og:title', pageTitle.value, 'property')
    }
  }

  const updateCanonical = async (newCanonical: string): Promise<void> => {
    if (!newCanonical) return
    const current = getLink('canonical')
    if (current !== newCanonical) {
      await nextTick()
      setCanonical(newCanonical)
    }
  }

  watch(pageTitle, updateWindowTitle, { immediate: true, deep: false })
  watch(canonical, updateCanonical, { immediate: true, deep: false })

  return title
}

export const setTitle = (value?: string | null): void => {
  if (value)
    value = value.replace(/\r\n/g, ' ').replace(/\n/g, ' ')
  if (value) title.value = value
}

const updatePageDescription = (description: string): void => {
  if (description)
    description = description.replace(/\r\n/g, ' ').replace(/\n/g, ' ')
  const desc = getMeta('description')
  if (desc === description) return
  setMeta('description', description)
  setMeta('twitter:description', description)
  setMeta('og:description', description, 'property')
}

const DES_MIN = 110
const DES_MAX = 160

export const setDescription = (value?: string | (string | null | undefined)[] | null): void => {
  if (!value) return
  if (typeof value === 'string') {
    updatePageDescription(value)
    return
  }
  let desc = ''
  for (let i = 0; i < value.length; i++) {
    const v = value[i]
    if (!v) continue
    let add = ''
    if (i !== 0)
      add += ', '
    add += v
    if (desc.length > DES_MIN && desc.length + add.length > DES_MAX)
      break
    desc += add
  }
  updatePageDescription(desc)
}
