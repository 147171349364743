

import './assets/inputs.pcss'

import {
  defineAsyncComponent,
  defineComponent,
  onBeforeMount,
  watch,
} from 'vue'

import { show as showJobDraftBanner } from '@/components/job/BackToJobDraftBanner.vue'
import NavBar from '@/components/layout/NavBar.vue'
import PageFooter from '@/components/layout/PageFooter.vue'
import { messengerOnce } from '@/components/messenger/messenger'
import {
  changeEmail,
  changeEmailOnce,
  changePassword,
  changePasswordOnce,
  initMyProfile,
  myProfile,
} from '@/components/profile/profile'
import { settingsOpenedOnce } from '@/components/settings/settings'
import { sidebarEnabled, sidebarOnce } from '@/components/sidebar/sidebar'
import {
  useWizzard,
  wizardOpenedOnce,
} from '@/components/wizard/wizard'
import { initTitle } from '@/utils/meta'
import {
  navFail,
} from '@/utils/nav'
import { useSupport } from '@/utils/support'
import { useLogrocket, useTracking } from '@/utils/tracking'
import { useUpdates } from '@/utils/updates'
import { user } from '@/utils/user'

const PaymentModals = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-modals' */ '@/components/modals/PaymentModals.vue'))
const UserSettings = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-modals' */ '@/components/settings/UserSettings.vue'))
const AccountWizard = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-modals' */ '@/components/wizard/AccountWizard.vue'))
const MessageModal = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-modals' */ '@/components/messenger/MessageModal.vue'))
const SidebarPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-modals' */ '@/components/sidebar/SidebarPanel.vue'))
const UpdatePasswordModal = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-modals' */ '@/components/modals/UpdatePasswordModal.vue'))
const UpdateEmailModal = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-modals' */ '@/components/modals/UpdateEmailModal.vue'))
const BackToJobDraftBanner = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'extra-editable' */ '@/components/job/BackToJobDraftBanner.vue'))
const PageNotFoundView = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'route-not-found' */ '@/components/views/PageNotFoundView.vue'))

export default defineComponent({
  components: {
    AccountWizard,
    BackToJobDraftBanner,
    PageFooter,
    MessageModal,
    NavBar,
    PaymentModals,
    UserSettings,
    SidebarPanel,
    UpdateEmailModal,
    UpdatePasswordModal,
    PageNotFoundView,
  },
  setup() {
    initTitle()
    onBeforeMount(initMyProfile)
    useWizzard()

    watch(myProfile, newProfile => {
      if (newProfile) {
        useLogrocket()
        useSupport()
        useUpdates()
      }
    }, { immediate: true })

    useTracking()

    return {
      changeEmail,
      changeEmailOnce,
      changePassword,
      changePasswordOnce,
      messengerOnce,
      myProfile,
      navFail,
      settingsOpenedOnce,
      showJobDraftBanner,
      sidebarEnabled,
      sidebarOnce,
      user,
      wizardOpenedOnce,
    }
  },
})
