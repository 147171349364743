import to from 'await-to-js'
import type {
  AuthCredential,
  User,
  UserCredential,
} from 'firebase/auth'
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail as authFetchSignInMethodsForEmail,
  getIdToken as authGetIdToken,
  reauthenticateWithCredential as authReauthenticateWithCredential,
  sendEmailVerification as authSendEmailVerification,
  sendPasswordResetEmail as authSendPasswordResetEmail,
  signOut as authSignOut,
  updateEmail as authUpdateEmail,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  updatePassword,
  updateProfile,
} from 'firebase/auth'

import { loginAs } from '@/utils/api'
import { trackError, trackErrorMessage } from '@/utils/tracking'

export {
  AuthCredential,
  UserCredential,
  User,
  getAuth,
}

// https://firebase.google.com/docs/reference/js/firebase.auth

export async function getCurrentUser(): Promise<User | null> {
  const auth = await getAuth()
  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      resolve(auth.currentUser)
      return
    }
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export async function getIdToken(): Promise<string | null> {
  const user = await getCurrentUser()
  return new Promise((resolve, reject) => {
    if (!user) {
      reject(null)
      return
    }
    authGetIdToken(user)
      .then(token => resolve(token))
      .catch(reason => reject(reason))
  })
}

export async function signUp(email: string, password: string): Promise<UserCredential> {
  const auth = await getAuth()
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email.toLowerCase(), password)
      .then(userCreds => resolve(userCreds))
      .catch(reason => reject(reason))
  })
}

export async function signIn(email: string, password: string): Promise<UserCredential> {
  const auth = await getAuth()
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email.toLowerCase(), password)
      .then(userCreds => resolve(userCreds))
      .catch(reason => reject(reason))
  })
}

export async function signInWithToken(token: string): Promise<UserCredential> {
  const auth = await getAuth()
  return new Promise((resolve, reject) => {
    signInWithCustomToken(auth, token)
      .then(userCreds => resolve(userCreds))
      .catch(reason => reject(reason))
  })
}

export function updateUserPassword(user: User, email: string, oldPassword: string, newPassword: string): Promise<void> {
  const credentials = EmailAuthProvider.credential(email, oldPassword)
  return new Promise((resolve, reject) => {
    authReauthenticateWithCredential(user, credentials)
      .then(() => resolve(updatePassword(user, newPassword)))
      .catch(reason => reject(reason))
  })
}

export async function signOut(): Promise<void> {
  const auth = await getAuth()
  return new Promise((resolve, reject) => {
    authSignOut(auth)
      .then(() => resolve())
      .catch(reason => reject(reason))
  })
}

// eslint-disable-next-line require-await
export async function updateEmail(user: User, email: string): Promise<void> {
  return new Promise((resolve, reject) => {
    authUpdateEmail(user, email)
      .then(() => resolve())
      .catch(reason => reject(reason))
  })
}

export function updateUserEmail(user: User, oldEmail: string, newEmail: string, password: string): Promise<void> {
  const credentials = EmailAuthProvider.credential(oldEmail, password)

  return new Promise((resolve, reject) => {
    authReauthenticateWithCredential(user, credentials)
      .then(() => resolve(updateEmail(user, newEmail)))
      .catch(reason => reject(reason))
  })
}

// eslint-disable-next-line require-await
export async function updateName(user: User, displayName: string): Promise<void> {
  return new Promise((resolve, reject) => {
    updateProfile(user, { displayName })
      .then(() => resolve())
      .catch(reason => reject(reason))
  })
}

// eslint-disable-next-line require-await
export async function updatePhoto(user: User, photoURL: string): Promise<void> {
  return new Promise((resolve, reject) => {
    updateProfile(user, { photoURL })
      .then(() => resolve())
      .catch(reason => reject(reason))
  })
}

// eslint-disable-next-line require-await
export async function sendEmailVerification(user: User): Promise<void> {
  // https://firebase.google.com/docs/reference/js/firebase.User#sendemailverification
  return new Promise((resolve, reject) => {
    authSendEmailVerification(user, { url: 'https://dynamitejobs.com/enter?source=verified' })
      .then(() => resolve())
      .catch(reason => reject(reason))
  })
}

export async function sendPasswordResetEmail(email: string): Promise<void> {
  const auth = await getAuth()
  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sendpasswordresetemail
  return new Promise((resolve, reject) => {
    authSendPasswordResetEmail(auth, email.toLowerCase(), { url: 'https://dynamitejobs.com/enter?source=reset' })
      .then(() => resolve())
      .catch(reason => reject(reason))
  })
}

// eslint-disable-next-line require-await
export async function reauthenticateWithCredential(user: User, cred: AuthCredential): Promise<void> {
  // https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
  return new Promise((resolve, reject) => {
    authReauthenticateWithCredential(user, cred)
      .then(() => resolve())
      .catch(reason => reject(reason))
  })
}

export async function fetchSignInMethodsForEmail(email: string): Promise<string[]> {
  const auth = await getAuth()
  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#fetchsigninmethodsforemail
  return new Promise((resolve, reject) => {
    authFetchSignInMethodsForEmail(auth, email.toLowerCase())
      .then(methods => resolve(methods))
      .catch(reason => reject(reason))
  })
}

export async function loginAsProfile(uid?: string) {
  if (!uid) {
    trackErrorMessage('Error while logging as user!')
    return
  }

  const [error, token]: [Error | null, string | null | undefined] =
    await to(loginAs(uid))

  if (error) {
    trackError(error, 'Error while logging as user!')
    return
  }

  if (token) {
    await signOut()
    await signInWithToken(token)

    window.location.reload()
  }

}
