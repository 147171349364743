import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  class: "relative flex-1 overflow-y-auto focus:outline-none",
  tabindex: "0"
}
const _hoisted_2 = { class: "flex-grow pb-10 lg:pb-20 xl:pb-32 bg-cool-gray-100 min-h-5xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_BackToJobDraftBanner = _resolveComponent("BackToJobDraftBanner")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_PageNotFoundView = _resolveComponent("PageNotFoundView")!
  const _component_PageFooter = _resolveComponent("PageFooter")!
  const _component_AccountWizard = _resolveComponent("AccountWizard")!
  const _component_UserSettings = _resolveComponent("UserSettings")!
  const _component_UpdatePasswordModal = _resolveComponent("UpdatePasswordModal")!
  const _component_UpdateEmailModal = _resolveComponent("UpdateEmailModal")!
  const _component_SidebarPanel = _resolveComponent("SidebarPanel")!
  const _component_PaymentModals = _resolveComponent("PaymentModals")!
  const _component_MessageModal = _resolveComponent("MessageModal")!

  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, { class: "flex h-screen overflow-hidden bg-gray-100" }), [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NavBar, { id: "scroll-area" }),
      _createElementVNode("main", _hoisted_2, [
        (!_ctx.navFail)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.user && _ctx.myProfile)
                ? (_openBlock(), _createBlock(_component_BackToJobDraftBanner, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_component_RouterView)
            ], 64))
          : (_openBlock(), _createBlock(_component_PageNotFoundView, { key: 1 }))
      ]),
      _createVNode(_component_PageFooter),
      (_ctx.wizardOpenedOnce)
        ? (_openBlock(), _createBlock(_component_AccountWizard, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.settingsOpenedOnce)
              ? (_openBlock(), _createBlock(_component_UserSettings, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.changePasswordOnce)
              ? (_openBlock(), _createBlock(_component_UpdatePasswordModal, {
                  key: 1,
                  show: _ctx.changePassword,
                  onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePassword = false))
                }, null, 8, ["show"]))
              : _createCommentVNode("", true),
            (_ctx.changeEmailOnce)
              ? (_openBlock(), _createBlock(_component_UpdateEmailModal, {
                  key: 2,
                  show: _ctx.changeEmail,
                  onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeEmail = false))
                }, null, 8, ["show"]))
              : _createCommentVNode("", true),
            (_ctx.sidebarOnce || _ctx.sidebarEnabled)
              ? (_openBlock(), _createBlock(_component_SidebarPanel, { key: 3 }))
              : _createCommentVNode("", true),
            _createVNode(_component_PaymentModals)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.messengerOnce)
        ? (_openBlock(), _createBlock(_component_MessageModal, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ], 16))
}