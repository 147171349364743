import type LogRocket from 'logrocket'
import { watch } from 'vue'

import { Analytics, getAnalytics } from '@/firebase/analytics'
import { User } from '@/firebase/auth'

import { ON_DEV } from './env'
import { getAppMeta, getURLParameter, title } from './meta'
import { loadLater } from './net'
import { user } from './user'
import { saving } from './validation'

export const dripID: string | undefined = process.env.VUE_APP_DRIP_ID

export interface TrackingValue {
  [key: string]: string | number
}

export type TrackingPacket = (string | TrackingValue)

interface MyExternal extends External {
  msTrackingProtectionEnabled: () => boolean
}

interface NavigatorDoNotTrack extends Navigator {
  readonly msDoNotTrack: string | null
  readonly doNotTrack: string | null
}

type PlausibleMeta = { props: TrackingValue, callback?: () => void }
type PlausibleParam = [string, PlausibleMeta]

declare global {
  interface Window {
    _dcq: (string | TrackingPacket[])[]
    _dcs: { [key: string]: string | number | boolean }
    plausible: { (eventName: string, meta?: PlausibleMeta): void, q: PlausibleParam[] }
    readonly doNotTrack: string
  }
}

export const bots = [
  'googlebot',
  'apis-google',
  'mediapartners-google',
  'feedfetcher-google',
  'adsbot',
  'bingbot',
  'slurp',
  'duckduckbot',
  'baiduspider',
  'yandexbot',
  'facebot',
  'ia_archiver',
  'chrome-lighthouse',
  'speed-insights',
  'speed insights',
  'ahrefsbot',
  'ahrefssiteaudit',
  'gtmetrix',
  'bingbot',
  'googleweblight',
  'storebot-google',
  'google.com/bot',
]

export const isBot = (() => {
  const ua = navigator.userAgent.toLowerCase()
  for (let i = 0; i < bots.length; i++)
    if (ua.indexOf(bots[i]) !== -1)
      return true
  return false
})()

export const doNotTrack = (): boolean => {
  try {
    if (process.env.VUE_APP_TRACKING_ENABLED !== 'true')
      return true
    if (isBot)
      return true
    const dntp = getURLParameter('dnt')
    if (dntp && (dntp === '1' || dntp === 'true'))
      return true
    const dnt: boolean | string | null | undefined =
      window.doNotTrack ||
      navigator.doNotTrack ||
      (navigator as NavigatorDoNotTrack).msDoNotTrack
    if (dnt && typeof dnt === 'string' && (dnt === '1' || dnt === 'yes'))
      return true
    if (dnt && typeof dnt === 'number' && dnt === 1)
      return true
    if (window.external && 'msTrackingProtectionEnabled' in window.external)
      return !!(window.external as MyExternal)?.msTrackingProtectionEnabled()
  } catch (error) {
    trackError(error, 'dnt_fail')
    return true
  }
  return false
}

export const trackingEnabled = !doNotTrack()

let analytics: Analytics | null = null
let appMeta: TrackingValue = {}
let lastUser: string | null = null
let logRocket: typeof LogRocket | null = null
let loaded = false
let loadedRocket = false

async function loadTracking(): Promise<void> {
  if (loaded) return
  loaded = true

  if (dripID) {
    // Load Drip
    const s = document.createElement('script')
    s.setAttribute('defer', 'true')
    s.setAttribute('type', 'text/javascript')
    s.setAttribute('src', `https://tag.getdrip.com/${dripID}.js`)
    document.body.appendChild(s)
  }

  // Load Plausible
  const p = document.createElement('script')
  p.setAttribute('defer', 'true')
  p.setAttribute('type', 'text/javascript')
  p.setAttribute('data-domain', 'dynamitejobs.com')
  p.setAttribute('src', 'https://plausible.io/js/plausible.js')
  document.head.appendChild(p)

  // Load Google Analytics
  analytics = await getAnalytics()

  // Identify user
  if (user.value)
    identifyUser(user.value)
}

export const useTracking = (): void => {
  appMeta = getAppMeta()
  if (!trackingEnabled) return

  window._dcq = window._dcq || []
  window._dcs = window._dcs || {}
  window._dcs.account = `${dripID}`

  watch(title, newTitle => {
    if (analytics)
      analytics.setCurrentScreen(newTitle)
    trackEvent('screen_view', { screen_name: newTitle })
  })

  loadLater(loadTracking)
}

async function loadLogrocket(): Promise<void> {
  if (loadedRocket) return
  loadedRocket = true

  // Load LogRocket
  logRocket = await import(/* webpackPreload: true, webpackChunkName: 'preload-extra-analytics' */ 'logrocket').then(module => module.default)
  if (logRocket)
    logRocket.init('mos8qg/dynamite-jobs', {
      release: String(appMeta.version) || undefined,
      console: {
        shouldAggregateConsoleErrors: true,
      },
    })

  // Identify user
  if (user.value)
    identifyUser(user.value)
}

export const useLogrocket = (): void => {
  if (!trackingEnabled) return

  loadLater(loadLogrocket)
}

function dripTrack(method: string, ...data: TrackingPacket[]): void {
  if (!trackingEnabled) return

  if (!window._dcq)
    window._dcq = []

  const packet: TrackingPacket[] = ([method] as TrackingPacket[]).concat(data)
  window._dcq.push(packet)
}

function plausibleEvent(eventName: string, data: TrackingValue): void {
  // If not loaded make temporary function
  window.plausible = window.plausible || function (...rest: PlausibleParam) {
    (window.plausible.q = window.plausible.q || []).push(rest)
  }

  window.plausible(eventName, {
    props: data,
    // callback: () => console.log('event success', eventName),
  })
}

function getDefaultParams(user: User | null | undefined, originalParams?: TrackingValue | null): TrackingValue {
  const params: TrackingValue = { ...appMeta }

  if (user)
    params.user_id = user.uid

  if (logRocket?.sessionURL)
    params.logrocket_session = logRocket.sessionURL

  if (!originalParams)
    originalParams = {}

  return Object.assign(originalParams, params)
}

export const identifyUser = (thisUser: User): void => {
  if (!trackingEnabled) return
  if (!thisUser) return

  const params: TrackingValue = getDefaultParams(thisUser)

  // Analytics Identify
  if (analytics) {
    // Google does not like having the email there
    analytics.setUserId(thisUser.uid)
    analytics.setUserProperties(params)
  }

  // Drip Identify, https://developer.drip.com/?javascript#identifying-visitors
  if (thisUser.email)
    params.email = thisUser.email
  dripTrack('identify', params)

  // LogRocket Identify, // https://docs.logrocket.com/reference#identify
  if (logRocket) {
    if (thisUser.displayName)
      params.name = thisUser.displayName
    logRocket.identify(thisUser.uid, params)
  }

  if (window.Beacon) {
    params.user_profile = `https://dynamitejobs.com/uid/${thisUser.uid}`
    window.Beacon('identify', params)
  }
}

export const trackEvent = (eventName: string, params?: TrackingValue | null, thisUser?: User | null): void => {
  const trackedUser: User | null | undefined = user.value || thisUser

  if (!trackingEnabled) return
  params = getDefaultParams(trackedUser, params)

  // Drip Event, https://developer.drip.com/?javascript#tracking-events
  dripTrack('track', eventName, params)

  // Plausible Event
  plausibleEvent(eventName, params)

  // Google Analytics Event
  if (analytics)
    analytics.logEvent(eventName, params)

  // LogRocket Event
  if (logRocket)
    logRocket.track(eventName)

  if (trackedUser && lastUser !== trackedUser.uid) {
    identifyUser(trackedUser)
    lastUser = trackedUser.uid
  }
}

export const trackError = (error: unknown | null, message: string, params?: TrackingValue): void => {
  if (ON_DEV) // eslint-disable-next-line no-console
    console.error(message, error, user.value, params)

  if (!trackingEnabled) return

  params = getDefaultParams(user.value, params)
  params.error_app_message = message
  if (error && error instanceof Error) {
    params.error_name = error.name || ''
    params.error_msg = error.message || ''
  }

  // Drip Event, https://developer.drip.com/?javascript#tracking-events
  dripTrack('track', 'error', params)

  // Google Analytics Event
  if (analytics)
    analytics.logEvent('issue', params)

  // LogRocket Event
  if (logRocket) {
    if (error && error instanceof Error) {
      error.message += ` - ${params.error_app_message}`
      logRocket.captureException(error, { tags: { error: error.name }, extra: params })
    } else
      logRocket.captureMessage(message, { tags: {}, extra: params })
  }

  if (user.value && lastUser !== user.value.uid) {
    identifyUser(user.value)
    lastUser = user.value.uid
  }

  /* Database Error */
  if (error && String(error).indexOf('An internal error was encountered in the Indexed Database server') !== -1) {
    trackEvent('error_relaoding', params)
    reloadApp()
  }
  /* Async Queue */
  if (error && String(error).indexOf('AsyncQueue') !== -1) {
    trackEvent('error_relaoding', params)
    reloadApp()
  }
  /* Failed To Execute */
  if (error && String(error).indexOf('IDBDatabase') !== -1) {
    trackEvent('error_relaoding', params)
    reloadApp()
  }
  /* Expired User Session */
  if (error && error instanceof Error && error.message.indexOf('credential is no longer valid') !== -1) {
    trackEvent('error_relaoding', params)
    reloadApp()
  }
}

export const trackErrorMessage = (message: string, params?: TrackingValue): void => {
  trackError(null, message, params)
}

export const reloadApp = (): void => {
  if (!saving.value)
    saving.value = true
  window.location.reload()
}