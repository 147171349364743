const projectId = String(process.env.VUE_APP_PROJECT_ID)

export default {
  projectId,
  authDomain:        `${projectId}.firebaseapp.com`,
  storageBucket:     `${projectId}.appspot.com`,
  databaseURL:       `https://${projectId}.firebaseio.com`,
  appId:             process.env.VUE_APP_APP_ID,
  apiKey:            process.env.VUE_APP_WEB_API_KEY,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  measurementId:     process.env.VUE_APP_MEASUREMENT_ID,
}