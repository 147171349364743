import { formatDistanceToNow } from 'date-fns'
import firebase from 'firebase/compat/app'
import {
  ComputedRef,
  Ref,
  computed,
  nextTick,
  ref,
} from 'vue'

import { getCurrentUser } from '@/firebase/auth'
import {
  DocumentData,
  Query,
  bindingFactory,
  db,
  serialize,
} from '@/firebase/db'
import { trackError } from '@/utils/tracking'
import { Message, ProfileIcon } from '@/utils/types'
import { user } from '@/utils/user'

import { goToInbox, sidebar } from './sidebar'

export const MAX_MESSAGE_LEN = 1000

export const CONTACT_LIST = 'ContactList'
export const MESSAGE_LIST = 'MessageList'

export type StepType =
  typeof CONTACT_LIST |
  typeof MESSAGE_LIST

export const dir: Ref<boolean> = ref(true)
export const step: Ref<StepType> = ref(CONTACT_LIST)

export const goTo = (target: StepType, direction = true): void => {
  if (step.value !== target) {
    dir.value = direction
    step.value = target
  }
}

export const goToContacts = (direction = true): void => {
  messagesFromUid.value = null
  goTo(CONTACT_LIST, direction)
}
export const goToMessages = (uid: string, direction = true): void => {
  messagesFromUid.value = uid
  goTo(MESSAGE_LIST, direction)
}

export const messagesFromUid: Ref<string | null> = ref<string | null>(null)
export const messagesFrom: ComputedRef<Message[] | null> = computed(() => {
  if (!messagesFromUid.value) return null
  const msgs = messages.value?.filter(m =>
    m.fromUid === messagesFromUid.value ||
    m.toUid === messagesFromUid.value) || []
  msgs.sort((a, b) =>
    (a.createdAt?.toMillis() || 0) - (b.createdAt?.toMillis() || 0),
  )
  return msgs
})

interface Contact {
  icon: ProfileIcon | null,
  name: string,
  uid: string,
  time: number,
  date: Date | null,
  unread: number,
}

export const sender: ComputedRef<Contact | null> = computed<Contact | null>(
  () => {
    if (!messagesFrom.value?.length) return null
    if (!messagesFromUid.value) return null
    if (!messagesFrom.value) return null
    const msgs = [...messagesFrom.value]
    msgs.reverse()
    const msg = msgs.find(m =>
      m.fromUid === messagesFromUid.value ||
      m.toUid === messagesFromUid.value)
    if (!msg) return null
    return (msg.fromUid === messagesFromUid.value) ? {
      icon:   msg.fromIcon || null,
      name:   msg.fromName,
      uid:    msg.fromUid,
      time:   0,
      date:   null,
      unread: 0,
    } : {
      icon:   msg.toIcon || null,
      name:   msg.toName,
      uid:    msg.toUid,
      time:   0,
      date:   null,
      unread: 0,
    }
  })

interface PeopleDict { [key: string]: Contact }

function addToContacts(people: PeopleDict, msg: Message, direction: 'from' | 'to'): boolean {
  const uid: string = msg[`${direction}Uid`] as string
  if (!uid)
    return false // This is unlikely
  if (!user.value?.uid || uid === user.value.uid) // Let's not add myself to contacts
    return false

  // If already there increase count and add last message date
  if (uid in people) {
    if (msg.createdAt &&
      people[uid].time < msg.createdAt.toMillis()) {
      people[uid].time = msg.createdAt.toMillis()
      if (msg[`${direction}Icon`])
        people[uid].icon = msg[`${direction}Icon`] as ProfileIcon
    }
    people[uid].unread += (msg.unread && direction === 'from') ? 1 : 0
    return true
  }

  // Add to the contact list
  people[uid] = {
    name:   msg[`${direction}Name`] as string,
    uid:    uid,
    icon:   null,
    time:   0,
    date:   null,
    unread: (msg.unread && direction === 'from') ? 1 : 0,
  }
  if (msg[`${direction}Icon`])
    people[uid].icon = msg[`${direction}Icon`] as ProfileIcon
  if (msg.createdAt) {
    people[uid].date = msg.createdAt.toDate()
    people[uid].time = msg.createdAt.toMillis()
  }
  return true
}

export const messages: Ref<Message[] | null> = ref<Message[] | null>(null)
export const contacts: ComputedRef<Contact[]> = computed(() => {
  if (!user.value?.uid) return []
  if (!messages.value) return []
  const people: PeopleDict = {}
  const msgs = messages.value
  msgs.reverse()
  msgs.forEach(msg => {
    const addedFrom =
      addToContacts(people, msg, 'from')
    if (!addedFrom) // If the message was not 'from' try checking 'to'
      addToContacts(people, msg, 'to')
  })
  return Object.values(people).sort((a, b) => a.time - b.time).reverse()
})

export const totalUnreadMessages = computed(() => {
  let total = 0
  contacts.value.map(c => total += c.unread)
  return total
})

function getUnreadsFrom(uid: string): Message[] {
  return messages.value?.filter(m =>
    m.fromUid === uid && m.unread) || []
}

export const getLastUnreadFor = (uid: string): string | null => {
  const unreads: Message[] = getUnreadsFrom(uid)
  if (unreads.length <= 0)
    return null
  const last = unreads.pop()
  return last?.id || null
}

export const makeMessagesRead = async (uid: string): Promise<void> => {
  const unreads: Message[] = getUnreadsFrom(uid)

  await db.runTransaction(async (transaction): Promise<void> => {
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    const promises = []
    for (const m of unreads) {
      if (!m.id) return
      const ref = db.collection('messages').doc(m.id)
      promises.push(transaction.set(ref, { unread: false, updatedAt }, { merge: true }))
    }
    await Promise.all(promises)
  })
}

export const getLastMessageTime = (uid?: string): string => {
  if (!uid) return ''
  const contact = contacts?.value.find(c => c.uid === uid)
  if (contact?.date)
    return formatDistanceToNow(contact.date, { addSuffix: true }) || ''
  return ''
}

// eslint-disable-next-line require-await
export const messagesBindings = bindingFactory<undefined, Message[]>(messages, async () => {
  if (!user.value?.uid) return null
  let queryRef: Query<DocumentData> = db.collection('messages')
  queryRef = queryRef.where('participants', 'array-contains', user.value.uid)
  queryRef = queryRef.orderBy('createdAt', 'asc')
  queryRef = queryRef.limit(5000)

  return queryRef.onSnapshot(
    col => messages.value = (col.docs.map(doc => serialize<Message>(doc)).filter(d => !!d) as Message[]),
    error => trackError(error, 'Failed to bind messages!'))
}, undefined, false, [])

export const didWeChat = async (uid?: string): Promise<boolean> => {
  if (!uid) return false
  await getCurrentUser()
  if (!user.value) return false
  const msgRef = db.collection('messages')
    .where(`participantsMap.${uid}`, '==', true)
    .where(`participantsMap.${user.value.uid}`, '==', true)
    .limit(1)

  const snapshot = await msgRef.get()
  return !snapshot.empty
}

export const openChatWith = async (uid: string): Promise<void> => {
  sidebar.value = true
  await nextTick()
  goToInbox(true)
  goToMessages(uid, true)
}