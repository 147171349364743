import to from 'await-to-js'
import {
  ComputedRef,
  Ref,
  computed,
  ref,
} from 'vue'

import { User, updatePhoto } from '@/firebase/auth'
import { getPubUrl, pathExists, uploadFile } from '@/firebase/storage'

import { trackError, trackEvent } from './tracking'
import { ProfileIcon } from './types'

type FileEvent = Event & {
  dataTransfer?: DataTransfer,
  originalEvent: Event & { dataTransfer?: DataTransfer },
}

export const ACCEPTERD_FORMATS = ['png', 'jpg', 'webp']
export const FILE_LIMIT = 10 // MB
export const SIZE_LIMIT = 256 // px

export const initPhotoUpload = (
  user: Ref<User | null>,
  saveIconData: (iconData: { icon: ProfileIcon }) => Promise<boolean>,
  getDir: () => string | false,
  showError: (error: Error) => false,
  clearErrors?: () => void,
): {
  uploadingPhoto: Ref<boolean>,
  uploadingPercentage: Ref<number>,
  selectPhoto: (event: FileEvent) => void,
  accepted: ComputedRef<string>,
} => {
  const uploadingPhoto = ref<boolean>(false)
  const uploadingPercentage = ref<number>(0)

  function showPhotoError(error: Error): false {
    uploadingPhoto.value = false
    showError(error)
    return false
  }

  function updateProgressValue(number: number): number {
    const result = Math.round(number)
    uploadingPercentage.value = result
    return result
  }

  function showErrorMessage(message: string): false {
    showPhotoError(new Error(message))
    return false
  }

  const accepted = computed(() => {
    const formats = ACCEPTERD_FORMATS.map(f => f.toUpperCase())
    return `${formats.slice(0, formats.length - 1).join(', ')} or ${formats[formats.length - 1]}`
  })

  const selectPhoto = (event: FileEvent) => {
    if (!user.value) return
    if (uploadingPhoto.value) return
    if (clearErrors)
      clearErrors()
    uploadingPhoto.value = true

    // NOTE: dragging
    // https://css-tricks.com/drag-and-drop-file-uploading/
    // https://stackoverflow.com/questions/8006715/drag-drop-files-into-standard-html-file-input

    let files: FileList | null | undefined = event.originalEvent?.dataTransfer?.files
    if (!files) {
      files = event.dataTransfer?.files
    }
    if (!files) {
      files = (event.target as HTMLInputElement)?.files
    }
    if (!files) {
      showErrorMessage('Select an image!')
      return
    }
    if (!files.length) {
      showErrorMessage('No image selected!')
      return
    }
    const file = files[0]
    if (file.name.indexOf('.') === -1) {
      showErrorMessage('Invalid image name!')
      return
    }
    const parts = file.name.split('.')
    const ext = parts[parts.length - 1].toLowerCase()

    if (!ACCEPTERD_FORMATS.includes(ext)) {
      let msg = `Invalid image type: "${ext}"! Accepting only: ${accepted.value}.`
      if (ext === 'heic') {
        msg += ' On iPhone please change your photo settings to "Most Compatible" or convert photo to JPG.'
      }
      showErrorMessage(msg)
      return
    }

    const fileSize = file.size / 1048576 // Size in mb
    if (fileSize > FILE_LIMIT) {
      showErrorMessage(`Image is too big! ${FILE_LIMIT}MB is the limit`)
      return
    }

    const Url = window.URL || window.webkitURL
    const img = new Image()
    const objectUrl = Url.createObjectURL(file)
    const clearImg = () => Url.revokeObjectURL(objectUrl)
    img.onload = async function () {
      if (!user.value) {
        clearImg()
        showErrorMessage('User not logged in!')
        return
      }
      if (img.height < SIZE_LIMIT) {
        clearImg()
        showErrorMessage(`Profile image is too small! The minimum resolution of the photo required is ${SIZE_LIMIT}x${SIZE_LIMIT}.`)
        return
      }
      if (img.width < SIZE_LIMIT) {
        clearImg()
        showErrorMessage(`Profile image is too small! The minimum resolution of the photo required is ${SIZE_LIMIT}x${SIZE_LIMIT}.`)
        return
      }
      clearImg()
      const dir: string | false = getDir()
      if (dir === false) {
        showErrorMessage('Invalid photo directory!')
        return
      }
      const path = `${dir}/icon.${ext}`
      const pathXS = `${dir}/thumbs/icon_64x64.png`
      const pathSM = `${dir}/thumbs/icon_128x128.png`
      const pathMD = `${dir}/thumbs/icon_256x256.png`
      const pathLG = `${dir}/thumbs/icon_512x512.png`
      const pathXSw = `${dir}/thumbs/icon_64x64.webp`
      const pathSMw = `${dir}/thumbs/icon_128x128.webp`
      const pathMDw = `${dir}/thumbs/icon_256x256.webp`
      const pathLGw = `${dir}/thumbs/icon_512x512.webp`

      const checkThumbsReady = () => {
        setTimeout(async () => {
          if (!user.value) {
            showErrorMessage('User is not logged!')
            return
          }
          const exists = await pathExists(path)
          if (exists) {
            // Once the original icon is deleted, all thumbs were generated!
            checkThumbsReady()
            return
          } 

          const [error, icons]: [
            Error | null, string[] | undefined,] = await to(Promise.all([
              getPubUrl(pathXS),
              getPubUrl(pathSM),
              getPubUrl(pathMD),
              getPubUrl(pathLG),
              getPubUrl(pathXSw),
              getPubUrl(pathSMw),
              getPubUrl(pathMDw),
              getPubUrl(pathLGw),
            ]))
          if (error || !icons) {
            if (error) {
              trackError(error, 'Failed to get thumbnails!')
            }
            showErrorMessage('Failed to generate icon thumbnails!')
            return
          }
          const [xs, sm, md, lg, xsw, smw, mdw, lgw] = icons
          const thumbs: { icon: ProfileIcon } = {
            icon: {
              xs,
              sm,
              md,
              lg,
              xsw,
              smw,
              mdw,
              lgw,
              hasIcon: true,
              hasWebp: true,
            },
          }
          try {
            await saveIconData(thumbs)
          } catch (error) {
            throw new Error('Failed to save photo!')
          }
          const [errorP] = await to(updatePhoto(user.value, lg))
          if (errorP) {
            trackError(errorP, 'Failed to save photo!')
          } else {
            trackEvent('updated_photo', {
              photo_url: lg,
            })
          }
          uploadingPhoto.value = false
        }, 200)
      }
      // https://stackoverflow.com/questions/51819349/getting-uncaught-after-catching-error-in-firebase-auth-with-async
      try {
        await uploadFile(file, path, {
          success: checkThumbsReady, 
          error:   error => {
            showPhotoError(error)
            trackError(error, 'Failed upload photo!')
          },
          loading: (number: number) => {
            updateProgressValue(number)
          },
        })
      } catch (err) {
        const error = new Error('An error occured while uploading your image. Please try again or choose another image')
        showPhotoError(error)
      }
    }
    img.src = objectUrl

    event.preventDefault()
    event.stopPropagation()
  }

  return { uploadingPhoto, uploadingPercentage, selectPhoto, accepted }
}