import firebase from 'firebase/compat/app'
import { toRaw } from 'vue'

// Makes a copy which is no a reference and is not reactive, also prevent circular loops
export const clone = <T>(obj: T, un2null = false, hash = new WeakMap()): T => {
  if (hash.has(obj as never)) return hash.get(obj as never) as T
  let raf = toRaw(obj)
  if (un2null && (obj === undefined || raf === undefined))
    return null as never
  if (!obj)
    return raf
  if (typeof raf === 'object') {
    if (
      raf instanceof firebase.firestore.FieldValue ||
      raf instanceof firebase.firestore.Timestamp ||
      raf instanceof firebase.firestore.GeoPoint ||
      raf instanceof firebase.firestore.DocumentReference
    )
      return raf
    if (raf instanceof Array)
      raf = [...toRaw(raf) as never] as never
    else
      raf = Object.assign({}, toRaw(raf)) as T

    for (const k in raf) {
      if (un2null && obj[k] === undefined)
        obj[k] = null as never
      else if (k.toLowerCase().endsWith('ref'))
        raf[k] = toRaw(raf[k])
      else
        raf[k] = clone(raf[k], un2null, hash)
    }
    hash.set(obj as never, raf)
  }
  return raf
}

// // eslint-disable-next-line @typescript-eslint/ban-types
// function hasOwnProperty<X extends {}, Y extends PropertyKey>
//   (obj: X, prop: Y): obj is X & Record<Y, unknown> {
//   // eslint-disable-next-line no-prototype-builtins
//   return obj.hasOwnProperty(prop)
// }

// // eslint-disable-next-line @typescript-eslint/ban-types
// export const getKeyValue = <X extends {}, Y extends PropertyKey>(key: Y) => (obj: X): unknown | undefined =>
//   hasOwnProperty(obj, key) ? obj[key] : undefined